import React from 'react'
import hoistStatics from 'hoist-non-react-statics'

export default function withScrollTop(WrappedComponent) {
  class BaseWithScrollTop extends React.Component {

    componentDidMount(){
      window.scrollTo(0, 0)
    }

    render() {
      return (
        <WrappedComponent {...this.props}/>
      )
    }
  }
  const WithScrollTop = hoistStatics(BaseWithScrollTop, WrappedComponent)


  const name = WrappedComponent.displayName || WrappedComponent.name
  WithScrollTop.displayName = `withScrollTop(${name})`

  return WithScrollTop
}
