import request from 'superagent'
import { createSelector } from 'reselect'
import { rj } from 'redux-rocketjump'
import { takeEveryAndCancel } from 'redux-rocketjump/effects'
import combineRjs from 'redux-rocketjump/plugins/combine'
import rjPosArgs from 'redux-rocketjump/plugins/positionalArgs'
import { authApiCall, withToken } from './auth'
import { API_URL, MASTER_TOKEN } from '../constants'
import _ from 'lodash'

const BOOKE_EVENT = 'BOOKE_EVENT'
const GET_EVENTS = 'GET_EVENTS'
const GET_EVENT_TYPES = 'GET_EVENT_TYPEs'
const GET_COUNTRIES = 'GET_COUNTRIES'
const GET_FILTERED_EVENTS = 'GET_FILTERED_EVENTS'
const GET_EVENTS_GLOBAL = 'GET_EVENTS_GLOBAL'
const GET_EVENTS_GLOBAL_SOUTH = 'GET_EVENTS_GLOBAL_SOUTH'
const GET_EVENTS_REGULATION = 'GET_EVENTS_REGULATION'


export const {
  connect: {
    bookEvent: {
      actions: {
        load: bookEvent,
        unload: unloadBookEvent,
      },
      selectors: {
        isLoading: isBookingEvent,
        getError: getBookingEventError,
        getData: getEventBooked,
      },
    },
    list: {
      actions: {
        load: loadEvents,
      },
      selectors: {
        getData: getEvents,
      }
    },
    eventType: {
      actions: {
        load: loadEventTypes,
      },
      selectors: {
        getData: getEventTypes,
      }
    },
    countries: {
      actions: {
        load: loadCountries,
      },
      selectors: {
        getData: getCountries,
      }
    },
    filteredList: {
      actions: {
        load: loadFilteredEvents,
      },
      selectors: {
        getData: getFilteredEvents,
      }
    },
    eventsGlobal : {
      actions: {
        load: loadEventsGlobal,
      },
      selectors: {
        getData: getEventsGlobal,
      }
    },
    eventsGlobalSouth : {
      actions: {
        load: loadEventsGlobalSouth,
      },
      selectors: {
        getData: getEventsGlobalSouth,
      }
    },
    eventsRegulation : {
      actions: {
        load: loadEventsRegulation,
      },
      selectors: {
        getData: getEventsRegulation,
      }
    }
  },
  saga,
  reducer,
} = combineRjs({

  list: rj({
    type: GET_EVENTS,
    proxySelectors: {
      getData: ({ getData }) => createSelector(getData, events => {
        if (events) {
          return events.filter(event => event.club)
        }
        return null
      })
    },
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/events`))
      .then(({ body }) => body)
  }),

  eventType: rj({
    type: GET_EVENT_TYPES,
    proxySelectors: {
      getData: ({ getData }) => createSelector(getData, labels => {
        if (labels) {
          return _.chain(labels)
            .filter(event => event.toShow)
            .map(event => ({...event, label: event.value}))
            .orderBy(['order'], ['asc'])
            .value()
        }
        return null
      })
    },
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/labels/master?category=eventType`))
      .then(({ body }) => body)
  }),
  countries: rj({
    type: GET_COUNTRIES,
    proxySelectors: {
      getData: ({ getData }) => createSelector(getData, labels => {
        if (labels) {
          return _.chain(labels)
            .orderBy(['value'], ['asc'])
            .value()
        }
        return null
      })
    },
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/labels/master?category=country`))
      .then(({ body }) => body)
  }),

  filteredList: rj({
    type: GET_FILTERED_EVENTS,
    api: t => (filter) => withToken(MASTER_TOKEN, request.get(`${API_URL}/events?afterQueryFilters=club.name.$contains:${filter}`))
      .then(({ body }) => body)
  }),

  eventsGlobal: rj({
    type: GET_EVENTS_GLOBAL,
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/events/globalRanking`))
      .then(({ body }) => body)
  }),

  eventsGlobalSouth: rj({
    type: GET_EVENTS_GLOBAL_SOUTH,
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/events/globalRankingSouth`))
      .then(({ body }) => body)
  }),

  eventsRegulation: rj({
    type: GET_EVENTS_REGULATION,
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/events/regulation`))
      .then(({ body }) => body)
  }),

  bookEvent: rj(rjPosArgs(), {
    type: BOOKE_EVENT,
    take: takeEveryAndCancel,
    api: t => (id, params) =>
      withToken(t, request.post(`${API_URL}/events/${id}/register`))
        .send(params)
        .then(({ body }) => body)
  }),

}, {
  state: 'events',
  callApi: authApiCall,
})
