import React from 'react'
import { connect } from 'react-redux'
import { getAuthUser } from 'eazy-auth'
import { Button } from 'reactstrap'
import memoize from 'memoize-one'
import range from 'lodash/range'
import get from 'lodash/get'
import './GolfClubDetailServices.scss'
import {t} from "i18next";

class GolfClubDetailServices extends React.PureComponent {

  getFilledImages = memoize(images => {
    return range(5).map(i => {
      if (images[i]) {
        return images[i].full
      }
      return '/images/golf-bg.png'
    })
  })

  render(){
    const { club, reserve } = this.props
    const images = this.getFilledImages(club.pictures || [])
    const facilities = get(club, 'facilities')

    return (
      <div className="golfclubdetail-services container-fluid">
        <div className="row">
          <div className="col-md-6 golfclubdetail-services-text-container">
            <h3 className={'font-700'}>{club.name || 'I nostri servizi'}</h3>
            <p className='golfclubdetail-services-text-container-paragraph'>{club.description}</p>
            {/* {!user && <Link to='/login' className="btn book-btn">Prenota i servizi</Link>} */}
          </div>
          <div className="col-md-6 golfclubdetail-services-gallery hidden-sm">
            <div>
              <div className="flex-1 golfclubdetail-services-img" style={{backgroundImage: `url('${images[0]}')`}} />
              <div className="flex-2 golfclubdetail-services-img" style={{backgroundImage: `url('${images[1]}')`}} />
              <div className="flex-1 golfclubdetail-services-img" style={{backgroundImage: `url('${images[2]}')`}} />
              <div className="flex-1 golfclubdetail-services-img" style={{backgroundImage: `url('${images[3]}')`}} />
              {/*<div className="flex-1 golfclubdetail-services-img" style={{backgroundImage: `url('${images[4]}')`}} />*/}
            </div>

            {facilities && facilities.length > 0 && <Button onClick={reserve} className="btn book-btn">{t('book services')}</Button>}
          </div>

        </div>
      </div>
    )
  }
}


export default connect(state => ({
  user: getAuthUser(state),
}))(GolfClubDetailServices)
