import React from 'react'
import { Link } from 'react-router-dom'
// import { Button } from 'reactstrap'
import qs from 'query-string'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { getAuthUser } from 'eazy-auth'
import get from 'lodash/get'
import memoize from 'memoize-one'
import keyBy from 'lodash/keyBy'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
// import ActivityDetailCover from '../../components/ActivityDetailCover'
// import ActivityDetailInfo from '../../components/ActivityDetailInfo'
import ActivityDetailDescription from '../../components/ActivityDetailDescription'
// import ActivityDetailServices from '../../components/ActivityDetailServices'
// import ActivityDetailWebSite from '../../components/ActivityDetailWebSite'
// import ActivityReservationForm from '../../components/ActivityReservationForm'
import ActivityDetailModal from '../../components/ActivityDetailModal'
import ReservedActivityModal from '../../components/ReservedActivityModal'
// import LoginLink from '../../components/LoginLink'
// import GolfMap from '../../components/GolfMap'
import MarkerMap from '../../components/MarkerMap'
import {
  getActivity,
  loadActivity,
  getActivityError,
  isLoadingActivity,
  unloadActivity,
  reserveActivity,
} from '../../state/activities'
import { ACTIVITY_TYPES } from '../../constants'
import withScrollTop from '../../hocs/ScrollTop'
import NotFound from '../NotFound'
import { padTime } from '../../utils'
import './ActivityDetail.scss'

const ActivityDetailPopup = ({ marker }) => (
  <div>
    <h5 className="text-secondary">
    <Link to={`/attivita/${marker.Id}`} className="text-secondary">
      {marker.Partner}
    </Link>
    </h5>
    <p className="text-white">
      {marker.Description}
    </p>
  </div>
)

class ActivityDetail extends React.Component {
  state = {
    reservationSuccess: false,
    showModal: false,
  }

  componentDidMount() {
    const { match } = this.props
    const { params } = match
    const { id } = params
    this.props.loadActivity({ id })
    const query = qs.parse(this.props.history.location.search)
    if(query.reservationModal){
      this.setState({showModal:true})
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.unloadActivity()
      this.props.loadActivity({ id: this.props.match.params.id })
    }
  }

  componentWillUnmount() {
    this.props.unloadActivity()
  }

  toggleShowModal = () => this.setState({showModal: !this.state.showModal})

  closeReservationModal = () => this.setState({
    reservationSuccess: false,
  })

  openReservationModal = () => this.setState({
    reservationSuccess: true,
  })

  getMarkers = memoize(activity => {
    if (activity === null) {
      return []
    }
    const typeById = keyBy(ACTIVITY_TYPES, 'id')
    return [{
      ...activity,
      id: activity.Id,
      icon: typeById[activity.IdMasterCategory].markerIcon,
      position: {
        lat: +activity.Geo.Lat.replace(',', '.'),
        lng: +activity.Geo.Long.replace(',', '.')
      }
    }]
  })

  render(){
    const { activity, loading, reserveActivity, user } = this.props
    const { showModal } = this.state
    const markers = this.getMarkers(activity)

    // BAD ID SHOW NOT FOUND PAGE
    if (!loading && !activity) {
      return <NotFound />
    }

    return <div className='activity-detail'>
      <Menu bgColor={'rgb(1, 22, 50)'} />
      {/* <ActivityDetailCover activity={activity} /> */}
      <div className='p-3 text-center activity-name'>
        {activity && <React.Fragment>
          <h1 className="text-primary" style={{fontWeight: '700'}}>{activity['Partner']}</h1>
          <div className="text-white">
            {get(activity, 'Location.Address')}{' '}
            {get(activity, 'Location.Department')}{' '}{get(activity, 'Location.City')}{' '}
            {get(activity, 'Location.Province')}{' '}
            {get(activity, 'Location.Nation')}
          </div>
        </React.Fragment>}
      </div>
      {/* {activity && <ActivityDetailInfo activity={activity} />} */}
      {activity && <ActivityDetailDescription activity={activity} reserve={this.toggleShowModal}/>}
      <MarkerMap
        defaultZoom={9}
        markers={markers}
        popupComponent={ActivityDetailPopup}
        center={activity ? {
          lat: +activity.Geo.Lat.replace(',', '.'),
          lng: +activity.Geo.Long.replace(',', '.')
        } : undefined}
        options={{
          zoomControlOptions: {
            position: 7,
          },
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }}
      />
      {/* <ActivityDetailServices activity={activity} /> */}
      {/* <ActivityDetailWebSite website={activity.website} /> */}
      {/* {!user && <LoginLink />}
      {user && <div className='mt-3'>
        {activity && <ActivityReservationForm
          categoryId={activity.IdMasterCategory}
          onSubmit={reservation => {
            return reserveActivity({
              ...reservation,
              id: activity.Id,
              bookingTime: reservation.bookingTime
                ? padTime(reservation.bookingTime)
                : null,
              dateTimeEnd: reservation.dateTimeEnd ? reservation.dateTimeEnd : null,
            }).catch(e => {
              throw new SubmissionError({
                _error: get(e, 'response.body.error', 'Errore durante la prenotazione')
              })
            })
          }}
          onSubmitSuccess={this.openReservationModal}
        />}
      </div>} */}
      <ActivityDetailModal
        activity={activity}
        onSubmit={reservation => {
          return reserveActivity({
            ...reservation,
            id: activity.Id,
            bookingTime: reservation.bookingTime
              ? padTime(reservation.bookingTime)
              : null,
            dateTimeEnd: reservation.dateTimeEnd ? reservation.dateTimeEnd : null,
          }).catch(e => {
            throw new SubmissionError({
              _error: get(e, 'response.body.error', 'Errore durante la prenotazione')
            })
          })
        }}
        onSubmitSuccess={this.openReservationModal}
        user={user} isOpen={showModal} toggle={this.toggleShowModal}/>
      <ReservedActivityModal
        isOpen={this.state.reservationSuccess}
        toggle={this.closeReservationModal}
      />
      <Footer />
    </div>
  }
}

export default connect(state => ({
  activity: getActivity(state),
  loading: isLoadingActivity(state),
  error: getActivityError(state),
  user: getAuthUser(state),
}), {
  loadActivity,
  unloadActivity,
  reserveActivity,
})(withScrollTop(ActivityDetail))
