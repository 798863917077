import request from 'superagent'
import { rj } from 'redux-rocketjump'
import { takeEveryAndCancel } from 'redux-rocketjump/effects'
import rjCombine from 'redux-rocketjump/plugins/combine'
import rjPromise from 'redux-rocketjump/plugins/promise'
import { MASTER_TOKEN, API_URL } from '../constants'

const GET_PAYMENT_TOKEN = 'GET_PAYMENT_TOKEN'
const PAY = 'PAY'
const GET_PAYMENT_TOKEN_RENEW = 'GET_PAYMENT_TOKEN_RENEW'
const PAY_RENEW = 'PAY_RENEW'

export const {
  connect: {
    token: {
      actions: {
        load: obtainPaymentToken,
        unload: clearPaymentToken,
      },
      selectors: {
        getData: getPaymentData,
        getError: getPaymentTokenError,
        isLoading: isLoadingPaymentToken,
      },
    },
    pay: {
      actions: {
        load: performPayment,
      }
    },
    renewToken: {
      actions: {
        load: obtainPaymentTokenRenew,
        unload: clearPaymentTokenRenew,
      },
      selectors: {
        getData: getPaymentDataRenew,
        getError: getPaymentTokenErrorRenew,
        isLoading: isLoadingPaymentTokenRenew,
      },
    },
    renewPay: {
      actions: {
        load: performPaymentRenew,
      }
    }
  },
  reducer,
  saga,
} = rjCombine({

  // GET TOKEN FOR BRAINTREE PAYMENT
  token: rj(rjPromise, {
    type: GET_PAYMENT_TOKEN,
    api: (userData) => request.post(`${API_URL}/payments/token`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .send(userData)
      .then(({ body }) => body)
  }),

  pay: rj(rjPromise, {
    type: PAY,
    state: false,
    takeEffect: takeEveryAndCancel,
    api: (paymentData) => request.post(`${API_URL}/payments/pay`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .send(paymentData)
      .then(({ body }) => body)
  }),


  // GET TOKEN FOR RENEW PAYMENT
  renewToken: rj(rjPromise, {
    type: GET_PAYMENT_TOKEN_RENEW,
    api: (userData) => request.post(`${API_URL}/payments/tokenToRenew`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .send(userData)
      .then(({ body }) => body)
  }),

  renewPay: rj(rjPromise, {
    type: PAY_RENEW,
    state: false,
    takeEffect: takeEveryAndCancel,
    api: (paymentData) => request.post(`${API_URL}/payments/payToRenew`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .send(paymentData)
      .then(({ body }) => body)
  })

}, {
  state: 'payment',
})
