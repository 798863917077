import React, { PureComponent } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { connect } from 'react-redux'
import { logout } from 'eazy-auth'
import './LogoutModal.scss'
import {t} from "i18next";

class LogoutModal extends PureComponent {

  _onLogout = () => {
    localStorage.removeItem('renewAsked')
    localStorage.removeItem('user')
    this.props.logout()
  }

  render() {
    const { isOpen, toggle } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle} className='logout-modal' backdropClassName='backdrop-logout-modal'>
        <ModalBody>
          <img className='golf-flag' src='/images/golf-flag.png' alt='Golf Flag' />
          <h4>{t('sure you want to logout?')} <br />Privileged The Club ?</h4>
          <button onClick={this._onLogout} className='btn btn-secondary text-white'>{t('confirm')}</button>
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(undefined, {
  logout,
})(LogoutModal)
