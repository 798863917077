import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { required } from '../Form/validation'
import { FieldInput } from '../Form/Fields'
import {t} from "i18next";

const validate = data => {
  if(data.password !== data.passwordConfirm) {
    return {
      passwordConfirm: t('passwords must match')
    }
  }
}

const passMinLength = value =>
  value && value.length < 6 ? t('password must be long') : undefined

const ChangePasswordForm = ({ handleSubmit, valid, submitting, submitSucceeded }) => (
  <form onSubmit={handleSubmit} className='p-3'>
    <Field
      validate={[required, passMinLength]}
      label={t('old password')}
      labelProps={{ className: 'text-primary' }}
      className='text-primary border-primary'
      name='oldPassword'
      type='password'
      component={FieldInput}
    />
    <Field
      validate={[required, passMinLength]}
      label={t('new password')}
      labelProps={{ className: 'text-primary' }}
      className='text-primary border-primary'
      name='password'
      type='password'
      component={FieldInput}
    />
    <Field
      validate={[required, passMinLength]}
      label={t('confirm password')}
      labelProps={{ className: 'text-primary' }}
      className='text-primary border-primary'
      name='passwordConfirm'
      type='password'
      component={FieldInput}
    />
    <br />
    <div className='text-center'>
      <button
        disabled={!valid || submitting}
        className='btn btn-secondary text-white pl-5 pr-5'>{t('save info')}</button>
    </div>
    {submitSucceeded  && <div className='text-secondary d-flex justify-content-center align-items-center mt-5'>
      <i className="fa fa-2x fa-check-circle-o"></i>
      <h5 className='m-0 ml-3'>{t('change password')}</h5>
    </div>}
  </form>
)

export default reduxForm({
  validate,
  form: 'changePassword',
})(ChangePasswordForm)
