import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getActivitiesById,
  getActivitiesLoadingById,
  loadActivityById,
} from '../../state/activities'
import './ActivityPopup.scss'

class ActivityPopup extends PureComponent {
  componentDidMount() {
    const { activitiesById, activitiesLoadingById, marker } = this.props
    marker.ids.forEach(id => {
      if (!activitiesById[id] && !activitiesLoadingById[id]) {
        this.props.loadActivityById(id)
      }
    })
  }

  render() {
    const { activitiesById, marker } = this.props
    const activities = marker.ids.map(id => activitiesById[id])
      .filter(Boolean)
    return (
      <div className='activity-popup'>
        {activities.length === 0 && <div className='text-white'>Loading...</div>}
        {activities.length > 0 && activities.map(activity => <div key={activity.Id}>
          <h5 className="text-secondary">
          <Link to={`/attivita/${activity.Id}`} className="text-secondary">
            {activity.Partner}
          </Link>
          </h5>
          <p className="text-white">
            {activity.Description}
          </p>
        </div>)}
      </div>
    )
  }
}
export default connect(state => ({
  activitiesById: getActivitiesById(state),
  activitiesLoadingById: getActivitiesLoadingById(state),
}), {
  loadActivityById,
})(ActivityPopup)
