import React from 'react'
import './Facilities.scss'
import classNames from 'classnames'
import {retina} from 'leaflet/src/core/Browser';

const Facility = ({ facility, isSelected, onClick }) => {
  const isClose = facility.isOpen === false
  return (
    <div>
      <div
        onClick={e => !isClose && onClick(facility, e)}
        className={classNames('facility text-primary', {
          'facility-selected': isSelected,
        })}> 
        {isSelected && (
          <div className='selected-dot'>
            <div />
          </div>
        )}
        <div className='text-primary text-center mb-2 facility-text'>
          <b>{facility.description}</b>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='full-price'><div className='price'>{facility.regularPrice} €</div></div>
          <div>{facility.discountedPrice} €</div>
        </div>

      </div>
      {isClose && <div className={'not-available'}>
        Non disponibile per il giorno selezionato
      </div>}
    </div>

  )
}

const Facilities = ({ facilities, selected, onClick }) => (
  <div className='row facilities'>
    {facilities.filter(x => x.name).map((facility, i) => (
      <div className='col-md-4 mb-4' key={i}>
        <Facility
          onClick={onClick}
          isSelected={selected.indexOf(facility.name) !== -1}
          facility={facility}
        />
      </div>
    ))}
  </div>
)

Facilities.defaultProps = {
  selected: [],
}

export default Facilities
