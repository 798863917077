import Carousel from 'nuka-carousel'
import React, { useState } from 'react'
import { getAlbumList } from '../../api'
import useWindowDimensions from '../../hocs/WindowDimensions'
import './WallOfFame.scss'
import _ from 'lodash';
import {DateTime} from 'luxon';
import {Link} from 'react-router-dom';

const WallOfFame = () => {
    //States
    const [albums,setAlbums] = useState([])
    const {width} = useWindowDimensions()


    React.useEffect(() => {
        const didMount = async () => {
          let albums = await getAlbumList()
          console.log({albums})
          const selectedAlbums = albums?.filter(album => album?.wallOfFame)
          setAlbums(selectedAlbums)
        }
        didMount()
    }, [])

    const colNumber = () => {
        if(width > 1400){
            return 4
        }
        if(width <= 768){
            return 1
        }
        if(width <= 1200){
            return 2
        }
        if(width <= 1400){
            return 3
        }
    }

    return (
        <>
            <Carousel
                style={{background:'white',cursor:'default'}}
                // autoplay={true}
                autoplay={false}
                className='wof'
                autoplayInterval={2000}
                transitionMode={"scroll"}
                easing={'easeSinOut'}
                renderCenterLeftControls={null}
                renderCenterRightControls={null}
                heightMode='max'
                slidesToShow={colNumber()}
            >
                {albums?.map((album, i) => (
                    <div className="wof-card mb-5" style={{marginLeft:'2.5%',marginRight:'2.5%'}}>
                        <div className='homepromos-image'>
                            <img src={album.pictures && album.pictures[0]?.thumbnail?.medium} className='homepromos-mainimg'/>
                        </div>
                        <div className='homepromos-content pr-3 pl-3 pt-3 pb-3'>
                            <img src={'/images/slider-preview-icon.png'} className='homepromos-icon'/>
                            <div className='pr-0 pl-4'>
                                <p className='h5 text-secondary font-weight-bold'>{album.title}</p>
                                <p className='h5 text-secondary font-weight-normal'>{DateTime.fromISO(album.date).toFormat('DDD')}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </>
    )
}

export default WallOfFame
