// import Carousel from 'nuka-carousel'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './HomePromosSlider.scss'
import useWindowDimensions from '../../hocs/WindowDimensions'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {Button} from 'reactstrap';
import {t} from 'i18next';
import _ from 'lodash'

const HomePromosSlider = ({ user, conventions: allConventions }) => {
  const conventions = (allConventions || []).filter(conv => conv.isHomeVisible)
  console.log({conventions})
  // const conventions = allConventions
    // const [trig, setTrig] = useState(0)
    // useEffect(() => {
    //   setInterval(() => {
    //     setTrig(Math.random())
    //   }, 100)
    // }, [])
    //States
    const { width } = useWindowDimensions()

    const colNumber = () => {
        if(width > 1400){
            return 4
        }
        if(width <= 768){
            return 1
        }
        if(width <= 1200){
            return 2
        }
        if(width <= 1400){
            return 3
        }
    }

    const Card = ({ id, name, logo, logoClub, description, externalUrl}) => {
        const [expandedCardId, setExpandedCardId] = useState(null)
        const logoClubF = _.get(logoClub, 'thumbnail.medium', '') ? _.get(logoClub, 'thumbnail.medium', '') : '/images/slider-preview-icon.png'
        return (
            <div style={{ width:'90%', marginLeft:'5%' }}>
              <div className="homepromos-slider">
                <div className='homepromos-image'>
                    <img src={_.get(logo, 'thumbnail.medium', '')} className='homepromos-mainimg'/>
                </div>
                <div className='homepromos-content py-4 px-3'>
                    <img src={logoClubF} className='homepromos-icon'/>
                    <p className={`${user ? 'text-secondary' : 'text-nlogged'} content-text font-weight-bold pr-3`}>{name}</p>
                    {/*{user && <img src={`/images/${expandedCardId === id ? 'top' : 'down'}-arrow.png`} style={{cursor:'pointer'}} />}*/}
                  {user && (
                    <img
                      src={`/images/${expandedCardId === id ? 'top' : 'down'}-arrow.png`}
                      onClick={() => setExpandedCardId(expandedCardId ? null : id)}
                      style={{ alignSelf: 'flex-start', cursor:'pointer' }}
                    />
                  )}
                </div>
                {expandedCardId === id && (
                    <div className="description px-3">
                      <p style={{fontSize:13}}>{description}</p>
                      {!!externalUrl && <button className='btn text-white color-orange font-weight-bold' style={{width: 120}} onClick={() => window.open(externalUrl, '_blanc') }>{t('discover')}</button>}
                    </div>
                )}
              </div>
            </div>
        )
    }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    appendDots: dots => <ul>{dots}</ul>,
    // customPaging: i => (
    //   <div
    //     style={{
    //       width: "30px",
    //       borderRadius: '50%',
    //       color: "blue",
    //       border: "1px blue solid"
    //     }}
    //   >
    //   </div>
    // ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

    return (
        <div style={{ margin: '50px 0' }}>
          <Slider {...settings}>
            {conventions?.map((p, i) => (
                <Card key={p._id} {...p}/>
            ))}
          </Slider>
            {/*<Carousel*/}
            {/*    style={{*/}
            {/*        background:'white'*/}
            {/*    }}*/}
            {/*    // autoplay={true}*/}
            {/*    autoplay={false}*/}
            {/*    transitionMode={"scroll"}*/}
            {/*    easing={'easeSinOut'}*/}
            {/*    renderCenterLeftControls={null}*/}
            {/*    renderCenterRightControls={null}*/}
            {/*    heightMode='max'*/}
            {/*    slidesToShow={colNumber()}*/}
            {/*>*/}
            {/*    {conventions?.map((p, i) => (*/}
            {/*        <Card key={i} {...p}/>*/}
            {/*    ))}*/}
            {/*</Carousel>*/}
        </div>
    )
}

export default HomePromosSlider
