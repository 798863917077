import request from 'superagent'
import { createSelector } from 'reselect'
import { rj } from 'redux-rocketjump'
import rjPromise from 'redux-rocketjump/plugins/promise'
import rjMap from 'redux-rocketjump/plugins/map'
import rjList from 'redux-rocketjump/plugins/list'
import combineRjs from 'redux-rocketjump/plugins/combine'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import { authApiCall } from './auth'
import { ACTIVITY_TYPES, MASTER_TOKEN, API_URL } from '../constants'

const GET_LIST_ACTIVITIES = 'GET_LIST_ACTIVITIES'
const GET_ACTIVITIES = 'GET_ACTIVITIES'
const GET_ACTIVITY = 'GET_ACTIVITY'
const GET_ACTIVITY_DETAIL = 'GET_ACTIVITY_DETAIL'
const RESERVE_ACTIVTY = 'RESERVE_ACTIVTY'

const allRj = rj({
  type: GET_ACTIVITIES,
  api: params => Promise.all(Object.keys(ACTIVITY_TYPES)
      .map(i => request.get(`${API_URL}/activities`)
        .query({
          types: ACTIVITY_TYPES[i].id,
          ...params
        })
        .set('Authorization', `Bearer ${MASTER_TOKEN}`)
        .then(({ body }) => body)
    ))
    .then(typeActivities => ACTIVITY_TYPES.reduce((all, type, i) => ({
      [type.id]: typeActivities[i],
      ...all
    }), {})),
  proxySelectors: {
    getCounts: ({ getData }) => createSelector(
      getData,
      activities => {
        if (activities === null) {
          return ACTIVITY_TYPES.map(activity => ({ ...activity, count: null }))
        }
        const activitiesCountByPos = mapValues(activities,
          list => Object.keys(groupBy(list, a => `${a.Geo.Lat}|${a.Geo.Long}`)).length)
        return ACTIVITY_TYPES
          .map(activity => ({
            ...activity,
            count: typeof activitiesCountByPos[activity.id] === 'undefined'
              ? 0
              : activitiesCountByPos[activity.id]
          }))
      },
    ),
    allWithTypes: ({ getData }) => createSelector(
      getData,
      activities => {
        if (activities === null) {
          return null
        }
        const typesById = keyBy(ACTIVITY_TYPES, 'id')
        return Object.keys(activities).reduce((all, typeId) => (
          all.concat(activities[typeId].map(activity => ({
            ...activity,
            type: typesById[typeId],
          })))
        ), [])
      }
    )
  },
})

const byIdRj = rj(rjMap(), {
  type: GET_ACTIVITY,
  api: ({ id }) => request.get(`${API_URL}/activities/${id}`)
    .set('Authorization', `Bearer ${MASTER_TOKEN}`)
    .then(({ body }) => body)
})

const detailRj = rj({
  type: GET_ACTIVITY_DETAIL,
  api: ({ id }) => request.get(`${API_URL}/activities/${id}`)
    .set('Authorization', `Bearer ${MASTER_TOKEN}`)
    .then(({ body }) => body)
})

const reserveRj = rj(rjPromise, {
  type: RESERVE_ACTIVTY,
  state: false,
  callApi: authApiCall,
  api: t => ({ id, ...reservation }) => request.post(`${API_URL}/activities/${id}/reservations`)
    .set('Authorization', `Bearer ${t}`)
    .send(reservation)
    .then(({ body }) => body)
})

const PAGE_SIZE = 12
const paginationAdapter = {
  list: 'Activities',
  count: 'Count',
  current: ({ pageOffset }) => ({ pageOffset: pageOffset || 1 }),
  next: ({ params, Count }) => {
    if (PAGE_SIZE * (+params.pageOffset) > Count) {
      return null
    }
    return {
      ...params,
      pageOffset: (+params.pageOffset) + 1
    }
  },
  previous: () => null,
}

const listRj = rj(rjList({
  pagination: paginationAdapter,
  pageSize: PAGE_SIZE,
}), {
  type: GET_LIST_ACTIVITIES,
  proxyActions: {
    loadMore: ({ load }) => (params = {}, meta = {}) =>
      load(params, { ...meta, append: true }),
  },
  api: (params = {}) => request.get(`${API_URL}/activities/location`)
    .set('Authorization', `Bearer ${MASTER_TOKEN}`)
    .query({
      ...params,
      ...params.pageOffset ? {
        pageSize: PAGE_SIZE,
      } : {
        pageSize:500,
        pageOffset:1
      }
      
    })
    .then(({ body }) => ({
      ...body,
      params
    }))
})

export const {
  connect: {
    all: {
      actions: {
        load: loadActivites,
        unload: unloadActivities,
      },
      selectors: {
        getData: getActivities,
        getCounts: getActivityTypesCounts,
        allWithTypes: getAllActivitiesWithType,
      },
    },
    list: {
      actions: {
        load: loadListActivities,
        loadMore: loadMoreListActivities,
        unload: unloadListActivities,
      },
      selectors: {
        isLoading: isLoadingListActivities,
        getList: getListActivities,
        getCount: getListActivitiesCount,
        getNext: getNextParamasListActivitiesCount,
      },
    },
    byId: {
      actions: {
        loadKey: loadActivityById,
        unload: clearAllActivitiesById,
      },
      selectors: {
        getMapData: getActivitiesById,
        getMapLoadings: getActivitiesLoadingById,
      }
    },
    detail: {
      actions: {
        load: loadActivity,
        unload: unloadActivity,
      },
      selectors: {
        getData: getActivity,
        isLoading: isLoadingActivity,
        getError: getActivityError,
      }
    },
    reserve: {
      actions: {
        load: reserveActivity,
      }
    }
  },
  saga,
  reducer,
} = combineRjs({
  all: allRj,
  list: listRj,
  byId: byIdRj,
  detail: detailRj,
  reserve: reserveRj,
}, {
  state: 'activities',
})
