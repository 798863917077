import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { MAPS_API_KEY, DEFAULT_MAP_CENTER, MAP_STYLE }  from '../../constants'
import './MarkerMap.scss'
import closeIcon from './close.svg'
import {marker} from "leaflet/dist/leaflet-src.esm";


class CustomInfoWindow extends React.Component {

  onDomReady() {
    const a = this.getContent()
    try {
      const b = a.parentNode.parentNode.parentNode
      b.firstChild.lastChild.classList.add('info-window-content')
      b.firstChild.childNodes[b.firstChild.childNodes.length - 2].classList.add('info-window-content-arrows')
      const img = b.querySelector("button.gm-ui-hover-effect > img")
      img.src = closeIcon
    } catch (err) {
      console.error("Can't set color to gmaps infowindow...", err)
    }
  }

  render(){
    const { ...passProps } = this.props
    return <InfoWindow onDomReady={this.onDomReady} {...passProps}/>
  }
}


const MapComponent = withScriptjs(withGoogleMap(class extends React.Component {

  state = {
    markerOpen : false
  }
  openMarker = markerOpen => e => this.setState({markerOpen})
  closeMarker = e => this.setState({markerOpen: null})

  componentDidUpdate(prevProps) {
    if (this.props.markers !== prevProps.markers) {
      this.setState({ markerOpen: null })
    }
  }

  render(){
    const { markers, popupComponent, options, ...passProps } = this.props
    const { markerOpen } = this.state
    return (
      <GoogleMap
        defaultZoom={7}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          styles: MAP_STYLE,
          ...options,
        }}
        defaultCenter={DEFAULT_MAP_CENTER}
        {...passProps}
      >
        {markers && markers.length > 0 && <React.Fragment>
          {markers.map(marker => (
            <Marker
              icon={marker.icon}
              key={marker.id} position={marker.position} onClick={this.openMarker(marker.id)}>
              { markerOpen === marker.id && <CustomInfoWindow onCloseClick={this.closeMarker} defaultPosition={marker.position}>
                {React.createElement(popupComponent, {
                  marker,
                })}
              </CustomInfoWindow>}
            </Marker>)
          )}
        </React.Fragment>}

      </GoogleMap>
    )
  }
}))

class MarkerMap extends React.PureComponent {

  render(){
    const { markers, style, className, ...passProps } = this.props
    return (
      <div className={`HomeMap bg-dark ${className}`} style={style}>
        <MapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&language=IT&region=IT&key=${MAPS_API_KEY}`}
          loadingElement={<div className="h-100" />}
          containerElement={<div className="h-100" />}
          mapElement={<div className="h-100" />}
          markers={markers}
          {...passProps}
        />
      </div>
    )
  }
}

MarkerMap.defaultProps = {
  className: '',
}

export default MarkerMap
