import React from 'react'
import { withAuthLogin } from 'eazy-auth'
import { Link } from 'react-router-dom'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import './Login.scss'
import withScrollTop from '../../hocs/ScrollTop'
import {openTermsCondition} from "../../utils";
import {t} from "i18next";

class LoginCheckBox extends React.PureComponent {
  state ={
    checked: false,
  }
  handleCheck = () => {
    this.setState({checked:!this.state.checked})
  }
  render() {
    const { checked } = this.state
    return <div className="login-checkbox" onClick={this.handleCheck}>
      {checked && <i className="fa fa-check text-secondary" />}
    </div>

  }
}


const Login = ({
  handleSubmit,
  credentials: { username, password },
  error,
  loading
}) => (
  <div className="login bg-primary text-white d-flex flex-column">
    <div className="h-100vh">
      <Menu bgColor={'rgb(1, 22, 50)'} />
      <div className="login-form-container">
        <img src="/images/privileged-logo-gold.png" alt="privileged club logo" />
        <h2>{t('log in to your acocunt')}</h2>
        <form onSubmit={handleSubmit}>
          <div className='login-form-group'>
            <input
              {...username}
              type='email'
              className="form-control"
              placeholder="Email"
            />
          </div>
          <div className='login-form-group'>
            <input
              {...password}
              type='password'
              className="form-control"
              placeholder="Password"
            />
          </div>
          <div className="login-checkbox-container">
            <LoginCheckBox />
            <span>{t('memorize')} </span>
          </div>
          <button disabled={loading} type='submit' className='btn btn-block'>
            {t('log in')}
          </button>
          {error && <div className='alert alert-danger mt-2'>
            {t('incorrect credentials')}
          </div>}
        </form>
        <div className="mt-3 d-flex flex-column">
          <p className="text-white link-style cursor-pointer" onClick={openTermsCondition}>
            <small>{t('terms')}</small>
          </p>
          <Link className="text-white" to="/recupera-password">
            <small>{t('forgot password')}</small>
          </Link>
          <small className='register my-1'>{t('if not yet member')}
            {' '}
            <a href="https://shop.privilegedgolf.com/" target="_blank" className='text-secondary' to='/diventa-socio'>{t('click here')}</a></small>
          <small className='register'>{t('card or signing up')}
            {' '}
            <Link className='text-secondary' to='/registra-tessera'>{t('click here')}</Link></small>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)

export default withAuthLogin({
  credentials: ['username', 'password'],
})(withScrollTop(Login))
