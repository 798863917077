import React from 'react'
import './HomeAssociators.scss'
import {Link} from 'react-router-dom';
import {t} from "i18next";
import qs from 'query-string';

const HomeAssociators = ({user, history, location}) => {
    //States

    const redirect = (path) => {
        history.push(path)
    }

    const goToRedirect = (category) => {
        const searchParams = qs.parse(location.search)
        if (searchParams.showServices === 'true') {
            redirect(`/?category=${category}&showServices`)
        } else {
            redirect(`/?category=${category}&showServices=true`)
        }
    }

    return (
        <>
            <div className='homeassociators-container pb-5'>
                {!user && (
                    <>
                        <div className='homeassociators-inner'>
                            <div className="homeassociator-top">
                                <img src={'/images/associated-1.png'}/>
                                <p className='h5'>{t('everytime you play')}</p>
                            </div>
                            <button className='btn text-white color-orange font-weight-bold' onClick={() => goToRedirect('0')}>{t('discover more')}</button>
                        </div>
                        <div className='homeassociators-inner'>
                            <div className="homeassociator-top">
                                <img src={'/images/associated-2.png'}/>
                                <p className='h5'>{t('participate tournament')}</p>
                            </div>
                            <button className='btn text-white color-orange font-weight-bold' onClick={() => redirect('/the-royal-diamond') }>{t('discover more')}</button>
                        </div>
                    </>
                )}
                <div className={`homeassociators-inner ${user && 'homeassociator-wider'}`}>
                    <div className="homeassociator-top">
                        <img src={'/images/associated-3.png'}/>
                        <p className='h5'>{!user ?
                            t('you can train with')
                            :
                            t('train with')
                        }</p>
                    </div>
                    <button className='btn text-white color-orange font-weight-bold' onClick={() => redirect('/the-royal-diamond') }>{t('discover more')}</button>
                </div>
                {!user && (
                    <div className='homeassociators-inner'>
                        <div className="homeassociator-top">
                            <img src={'/images/associated-4.png'}/>
                            <p className='h5'>{t('save 50% on activities')}</p>
                        </div>
                        <button className='btn text-white color-orange font-weight-bold' onClick={() => goToRedirect(47)}>{t('discover more')}</button>
                    </div>
                )}
                <div className={`homeassociators-inner ${user && 'homeassociator-wider'}`}>
                    <div className="homeassociator-top">
                        <img src={'/images/associated-5.png'}/>
                        <p className='h5'>{!user ? t('enter in a world') : t('your conventions')}</p>
                    </div>
                    <button className='btn text-white color-orange font-weight-bold' onClick={() => redirect('/convenzioni')}>{t('discover more')}</button>
                </div>
            </div>
            {!user && (
                <div className='pt-5 pb-5 d-flex flex-column align-items-center' style={{backgroundColor:'white'}}>
                    <a href="https://shop.privilegedgolf.com/" target="_blank" className='btn text-white color-orange font-weight-bold pl-5 pr-5' style={{minWidth:250}}>{t('enter in the club')}</a>
                    <p className='mt-4 mb-0 pb-0'>{t('no card?')}</p>
                    <Link to='/registra-tessera' className='text-secondary pt-0 mt-0 font-weight-bold' style={{textDecoration:'underline'}}>{t('click here')}</Link>
                </div>
            )}
        </>
    )
}

export default HomeAssociators
