import React from 'react'
import './EventReservationSuccess.scss'
import {t} from "i18next";

export default function EventReservationSuccess() {
  return (
    <div className='event-reservation-success'>
      <div className='top-cover'>
        <img
          alt='club logo'
          src={'/images/privileged-logo-gold-big.png'}
        />
        <div className='container'>
          <p>
            {t('your booking was successful')}
          </p>
          <div className='coursive text-white'>{t('good game')}</div>
        </div>
      </div>
    </div>
  )
}
