import request from 'superagent'
import { createSelector } from 'reselect'
import { rj } from 'redux-rocketjump'
import { takeEveryAndCancel } from 'redux-rocketjump/effects'
import combineRjs from 'redux-rocketjump/plugins/combine'
import rjPosArgs from 'redux-rocketjump/plugins/positionalArgs'
import { authApiCall, withToken } from './auth'
import { API_URL, MASTER_TOKEN } from '../constants'

const GET_SPONSORS = 'GET_SPONSORS'
const GET_SPONSORS_HEADER = 'GET_SPONSORS_HEADER'

export const {
  connect: {
    list: {
      actions: {
        load: loadSponsors,
      },
      selectors: {
        getData: getSponsors,
      }
    },
    header: {
      actions: {
        load: loadSponsorsHeader,
      },
      selectors: {
        getData: getSponsorsHeader,
      }
    },
  },
  saga,
  reducer,
} = combineRjs({

  list: rj({
    type: GET_SPONSORS,
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/supporters`))
      .then(({ body }) => body)
  }),

  header: rj({
    type: GET_SPONSORS_HEADER,
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/sponsors/header`))
      .then(({ body }) => body)
  }),

}, {
  state: 'sponsors',
  callApi: authApiCall,
})
