import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import './GolfClubDetailModal.scss'
import GolfClubDetailFormModal from '../GolfClubDetailFormModal'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash';
import moment from 'moment';
import {t} from "i18next";

class GolfClubDetailModal extends React.PureComponent {
  render(){
    const { isOpen, toggle, user, facilities, onSubmit, onSubmitSuccess, history, club, date, onChangeDate } = this.props

    return (
      <Modal isOpen={isOpen} toggle={toggle} className='golfclubdetail-modal' backdropClassName='backdrop-golfclubdetail-modal'>
        <ModalBody>
          {user && <GolfClubDetailFormModal
            club={club}
            facilities={facilities}
            date={date}
            onChangeDate={onChangeDate}
            onSubmit={onSubmit}
            onUndo={toggle}
            onSubmitSuccess={onSubmitSuccess}
          />}
          {!user && <div>
            <h4>{t('do login')}</h4>
            <p>
              {t('to make a reservation you need to login')}
            </p>
            <Link to={{pathname:'/login', state:{referrer:history.location.pathname+'?reservationModal=1'}}}  className="btn btn-secondary text-white">{t('login')}</Link>
          </div>}
        </ModalBody>
      </Modal>
    )
  }
}

export default withRouter(GolfClubDetailModal)

export const normalizeDate = (newDate) => {
  let readableDate = ''
  _.map(newDate, d => {
    if (typeof d === 'string') {
      readableDate = `${readableDate}${d}`
    }
  })
  const formattedDate = moment(readableDate, 'DD/MM/YYYY').toISOString()
  return formattedDate
}
