import React from 'react'
import { connect } from 'react-redux' 
import { getAuthUser } from 'eazy-auth'
import { Button } from 'reactstrap'
import memoize from 'memoize-one'
import range from 'lodash/range'
import get from 'lodash/get'
import './ResortDetailCollage.scss'

class ResortDetailCollage extends React.PureComponent {

  getFilledImages = memoize(images => {
    return range(5).map(i => {
      if (images[i]) {
        return images[i].full
      }
      return '/images/golf-bg.png'
    })
  })

  render(){
    const { resort, reserve } = this.props
    const images = this.getFilledImages(resort.pictures || [])

    return (
      <div className="resortdetail-services container-fluid">
        <div className="row">
          <div className="col-md-6 resortdetail-services-text-container">
            <div className="resort-container-text">
              <h3>{resort.name || 'I nostri servizi'}</h3>
              <p className='resortdetail-services-text-container-paragraph'>{resort.description}</p>
            </div>
          </div>
          <div className="col-md-6 resortdetail-services-gallery hidden-sm">
              <div className="flex-1 resortdetail-services-img" style={{backgroundImage: `url('${images[2]}')`}} />
              <div className="flex-1 resortdetail-services-img" style={{backgroundImage: `url('${images[4]}')`}} />
              {/*<div className="flex-2 resortdetail-services-img" style={{backgroundImage: `url('${images[1]}')`}} />*/}
              <div className="flex-1 resortdetail-services-img" style={{backgroundImage: `url('${images[3]}')`}} />
              <div className="flex-1 resortdetail-services-img" style={{backgroundImage: `url('${images[0]}')`}} />
          </div>
        </div>
      </div>
    )
  }
}


export default connect(state => ({
  user: getAuthUser(state),
}))(ResortDetailCollage)
