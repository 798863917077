import React from 'react'
// import HomeNewsLetter from '../../components/HomeNewsLetter'
import './Home.scss'
import moment from 'moment';
import _ from 'lodash';
import regioni from '../../regioni.json';
import province from '../../province.json';
import {Field, reduxForm} from 'redux-form';
import {FieldDatepicker} from '../../components/Form/Fields';
import find from 'lodash/find';
import '../../components/HomeGolfClubsList/HomeGolfClubsList.scss'
import {t} from "i18next";

class HomeFilters extends React.Component {
  state = {
    search: '',
    regione: null,
    provincia:null,
    date: null,
    test: 'aaa'
  }


  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      regione:nextProps.filters.regione,
      search:nextProps.filters.search,
      provincia:nextProps.filters.provincia,
      date:nextProps.filters.date,
    })
  }

  componentDidUpdate(){}

  componentWillUnmount() {
  }


  handleChangeRegione = e => {
    const idRegione = e.target.value
    let regione = null
    if (idRegione) {
      regione = find(regioni, { id: idRegione })
    } else {
      regione = null
    }

    this.setState({
      regione,
      provincia: null,
    }, this.loadActivites)
  }

  handleFilters = () => {
    console.log('SEARCHING::', this.state)
    this.props.setStateHome({
      regione: this.state.regione,
      provincia: this.state.provincia,
      search:this.state.search,
      date:this.state.date
    })
  }

  handleChangeProvincia = e => {
    const idProvincia = e.target.value
    let provincia = null
    if (idProvincia) {
      provincia = find(province, { id: `${idProvincia}` })
    }

    this.setState({
      provincia,
    }, this.loadActivites)
  }

  handleSearchChange = e => {
    this.setState({
      search: e.target.value,
    }, this.debouncedLoadActivities)
  }

   onChangeDate = (newDate) => {
    console.log({newDate})
    this.setState({date:'sss'})
    let readableDate = ''
    _.map(newDate, d => {
      if (typeof d === 'string') {
        readableDate = `${readableDate}${d}`
      }
    })
    console.log({readableDate})
    const formattedDate = moment(readableDate, 'DD/MM/YYYY').toISOString()
    localStorage.setItem('selectedDate', formattedDate)
    console.log({formattedDate})
    this.setState({date:formattedDate})
  }

  render() {
    const { search, regione, provincia } = this.state
    console.log({STATE: this.state})

    return (
      <div className='filters-container'>

        <div className='filter-container' id="resp-calendar" style={{position:'relative', top:8}}>
          <Field
            emptyClassName='text-placeholder'
            labelProps={{
              className: 'golfclubdetail-form-label',
            }}
            row
            fullWidth
            name='dateTimeStart'
            placeholder={t('calendar')}
            component={FieldDatepicker}
            onChange={(newDate) => this.onChangeDate(newDate)}
            // validate={[required, mustBookingDate]}
          />

        </div>

        <div className='filter-container'>
          <input
            value={search}
            onChange={this.handleSearchChange}
            type='text'
            className='form-control form-border-bold'
            placeholder={this.props.type != 0 ? 'Cerca Attività' : "Golf"}
          />
        </div>

        <div className='filter-container'>
          <select className='form-control form-border-bold'
                  value={regione ? regione.id : ''}
                  onChange={this.handleChangeRegione}>
            <option value=''>{t('region')}</option>
            {regioni.map(regione => (
              <option key={regione.id} value={regione.id}>{regione.nome}</option>
            ))}
          </select>
        </div>

        <div className='filter-container'>
          <select className='form-control form-border-bold '
                  value={provincia ? provincia.id : ''}
                  onChange={this.handleChangeProvincia}>
            <option value=''>{t('province')}</option>
            {regione && (
              province.filter(provincia => provincia.id_regione === regione.id).map(provincia => (
                <option key={provincia.id} value={provincia.id}>{provincia.nome}</option>
              ))
            )}
          </select>
        </div>
        <div className='filter-container filter-search'>
          <button className="btn text-white color-orange flex-md-row filter-serch-btn" style={{width:'100%'}} onClick={() => this.handleFilters()}>
            {t('search')}
          </button>
        </div>
        <div className='home-switchview'>

            <div onClick={() => this.props.setView('map')} className={`home-switch ${this.props.view === 'map' && 'home-activeswitch'}`}>
              <p style={{padding:0,margin:0}}>{t('map')}</p>
            </div>
            <div onClick={() => this.props.setView('list')} className={`home-switch ${this.props.view === 'list' && 'home-activeswitch'}`}>
              <p style={{padding:0,margin:0}}>{t('list')}</p>
            </div>
          </div>


      </div>
    )

  }
}


export default reduxForm({
  form: 'HomeGolfClubsListFilter',
})(HomeFilters)
