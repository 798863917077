import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'
import UserAvatar from './UserAvatar'
import { updateAvatar, isUpdatingAvatar } from '../../state/avatar'
import './UserAccountMenu.scss'

class UserAccountMenu extends React.PureComponent {
  render() {
    const {
      user,
      updateAvatar,
      updatingAvatar,
      onSettingsClick,
      onCardClick,
      onLogoutClick,
    } = this.props
    // TODO: Better default image
    const image = get(user, 'profilePicture.thumbnail.medium', '/images/user.png')

    return(
      <div className="useraccount-menu d-flex flex-row align-items-center mr-5">
        <UserAvatar
          uploading={updatingAvatar}
          onUpload={updateAvatar}
          image={image}
        />
        <div className='ml-5 useraccount-infobox'>
          <h1  style={{textTransform:'capitalize'}} className="text-white mt-0 profile_username">{user.name}</h1>
          {user.birthDate && <h3 className='mt-3 profile_month' style={{fontWeight:'normal'}}>{moment(user.birthDate).format('DD.MM.YYYY')}</h3>}
          {user.address && <h3 className='mt-1 profile_info' style={{fontWeight:'normal'}}>
              {user.address.city}
              {user.address.city && user.address.municipality && ' '}
              ({user.address.municipality})
            </h3>}
        </div>

      </div>
    )
  }
}

export default connect(state => ({
  updatingAvatar: isUpdatingAvatar(state),
}), {
  updateAvatar,
})(UserAccountMenu)
