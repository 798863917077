import React from 'react'
import moment from 'moment'
import get from 'lodash/get'
import { reduxForm, Field } from 'redux-form'
import { FieldGridInput, FieldInput, FieldDatepicker } from '../Form/Fields'
import { required, mustBookingDate, mustHour, mustFutureBookingHour } from '../Form/validation'
import {t} from "i18next";

// 51 -> Gusto, 56 -> Wellness
const requiredForCateogry = (value, _, props) => {
  if (props.categoryId === 51 || props.categoryId === 56) {
    return (value ? undefined : t('required field'))
  }
  return undefined
}

const mustAfterStart = (value, allValues) => {
  if (!value) {
    return undefined
  }
  const start = moment(allValues.dateTimeStart, 'DD/MM/YYYY', true)
  const end = moment(value, 'DD/MM/YYYY', true)
  if (!start.isValid() || !end.isValid()) {
    return undefined
  }
  if (!end.isSameOrAfter(start, 'date')) {
    return t('start date before end date')
  }
}

const ActivityReservationFormModal = ({ handleSubmit, invalid, submitting, categoryId, error, onUndo, activity }) => (
  <div className='bg-primary'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-12 p-md-4'>
          <div className='text-center'>
            <h3>{t('request reservation')}</h3>
          </div>
          <form onSubmit={handleSubmit} className='mt-5 p-2 p-md-0'>
            {/* 47 Fughe */}
            <Field
              labelProps={{
                className: 'text-white',
              }}
              className='bg-primary border-white text-white placeholder-white'
              emptyClassName='text-placeholder'
              row
              fullWidth
              colLabel={3}
              colInput={7}
              name='dateTimeStart'
              placeholder="gg/mm/aaaa"
              label={categoryId === 47 ? t('start date') : t('date')}
              component={FieldDatepicker}
              validate={[required, mustBookingDate]}
            />
            {categoryId === 47 && <Field
              labelProps={{
                className: 'text-white',
              }}
              className='bg-primary border-white text-white placeholder-white'
              row
              fullWidth
              colLabel={3}
              colInput={7}
              name='dateTimeEnd'
              placeholder="gg/mm/aaaa"
              label={t('end date')}
              component={FieldDatepicker}
              validate={[required, mustBookingDate, mustAfterStart]}
            />}
            <Field
              labelProps={{
                className: 'text-white',
              }}
              className='bg-primary border-white text-white placeholder-white'
              colLabel={3}
              colInput={7}
              name='bookingTime'
              placeholder="hh:mm"
              type='time'
              label={t('hour')}
              component={FieldGridInput}
              validate={[requiredForCateogry, mustHour, mustFutureBookingHour]}
            />
            {/* <Field
              labelProps={{
                className: 'text-md-right text-white',
              }}
              className='bg-secondary border-white text-white placeholder-white'Richiedi prenotazione
              colLabel={4}
              colInput={8}
              name='numParticipants'
              label='N° Partecipanti'
              component={FieldGridInput}
              validate={[required]}
            /> */}
            <div className='row'>
              <div className='col-sm-7 offset-sm-3'>
                <Field
                  className='bg-primary border-white text-white placeholder-white'
                  placeholder={t('message')}
                  name='bookingNote'
                  type='textarea'
                  rows={3}
                  component={FieldInput}
                />
              </div>
            </div>
            {get(activity, 'NumPart') && <div className="px-5">
              {t('if num max')} <b>{activity.NumPart}</b> {t('please write in the field')}  <i>{t('message')}</i>.
            </div>}
            <div className='row mt-4'>
              <div className='col-sm-6 offset-sm-3'>
                <button
                  disabled={invalid || submitting}
                  className='btn btn-block btn-secondary text-white'>{t('book')}</button>
                <button
                  onClick={onUndo}
                  type='button'
                  className='btn btn-block btn-secondary text-white'>{t('cancel')}</button>
                {error && <div className='alert alert-danger mt-2'>
                  {error}
                </div>}
              </div>
            </div>
            <p className='text-white mt-3'>
              {t('during the process')}
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
)

export default reduxForm({
  form: 'activityReservation',
})(ActivityReservationFormModal)
