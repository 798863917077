import React, {useEffect, useState} from 'react'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import './Albums.scss'
import withScrollTop from '../../hocs/ScrollTop'
import { DateTime } from 'luxon'
import {connect} from "react-redux";
import {
  getConventions,
  loadConventions,
  getConventionsHeader,
  loadConventionsHeader
} from "../../state/conventions";
import { getAuthUser } from 'eazy-auth'
import {getAlbumList} from '../../api';
import {Card} from "reactstrap";
import AlbumFilters from "./FiltersAlbum";
import {Link} from "react-router-dom";
import {t} from "i18next";
const Albums = ({...props}) => {

  const [albums, setAlbums] = useState([])
  const [date, setDate] = useState('')
  const [search, setSearch] = useState('')


  useEffect(() => {
    refreshList()
  }, [refreshList])

  const refreshList = async () => {
    const albumList = await getAlbumList()
    setAlbums(albumList)
  }
  console.log({props: props})
  const {conventions, conventionsHeader} = props;
  console.log({conventions})
  console.log({conventionsHeader})
  console.log({date})

  const filteredAlbums = albums.filter(a => {
    return (a.title.toLowerCase().includes(search.toLowerCase()) || a.description.toLowerCase().includes(search.toLowerCase())) &&
    (!date || DateTime.fromISO(a.date).toFormat('dd/MM/yyyy') === DateTime.fromISO(date).plus({hours: 6}).toFormat('dd/MM/yyyy'))
    }
  )


  return <div className="albums">
      <Menu bgColor={'rgb(1, 22, 50)'}/>
      <div className="sponsor-header">
        <img src={'images/galleryHeader.jpeg'} className={'header-image'}/>
      </div>

      <div className="container-cards">
          <div class="row">
            <div className="col">
              <h1 className="title" style={{textTransform:'uppercase'}}>{t('gallery')}</h1>
            </div>
          </div>
          <div class="row">
            <div className="col">
              <AlbumFilters  search={search} setSearch={setSearch} date={date} setDate={setDate}/>
            </div>
          </div>
          <div class="row" >
            {filteredAlbums.filter(album => !album.wallOfFame).map(album => {
              return (
                <div className="col-xs-12 col-sm-12 col-md-4 col-xl-3 col">
                  <Link key={album._id} className="album-card" to={"/albums/"+album._id}>
                    <Card className="col-xs-12 album-card-container">
                      <img className="images" src={album?.pictures[0]?.full} alt="errore caricamento immagine"/>
                      <div className="title-card">{album.title}</div>
                      <div className="date-card">{DateTime.fromISO(album.date).toFormat('DDD')}</div>
                      <div className="description-card">{album.description}</div>
                    </Card>
                  </Link>
                </div>
              )
            })}
          </div>
      </div>

      <Footer/>
    </div>
  }


export default connect(state => ({
  conventions: getConventions(state),
  conventionsHeader: getConventionsHeader(state),
  user: getAuthUser(state)
}), {
  loadConventions,
  loadConventionsHeader,
})(withScrollTop(Albums))

