import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { MAPS_API_KEY, DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM, MAP_STYLE }  from '../../constants'
import './GolfMap.scss'
import closeIcon from './close.svg'


class CustomInfoWindow extends React.Component {

  onDomReady() {
    const a = this.getContent()
    try {
      const b = a.parentNode.parentNode.parentNode
      b.firstChild.lastChild.classList.add('info-window-content')
      b.firstChild.childNodes[b.firstChild.childNodes.length - 2].classList.add('info-window-content-arrows')
      const img = b.querySelector("button.gm-ui-hover-effect > img")
      img.src = closeIcon
    } catch (err) {
      console.error("Can't set color to gmaps infowindow...", err)
    }
  }

  render(){
    const { ...passProps } = this.props
    return <InfoWindow onDomReady={this.onDomReady} {...passProps}/>
  }
}


const MapComponent = withScriptjs(withGoogleMap(class extends React.Component {

  state = {
    markerOpen : false
  }
  openMarker = markerOpen => e => this.setState({markerOpen})
  closeMarker = e => this.setState({markerOpen: null})

  render(){
    const { markers, center, options } = this.props
    const { markerOpen } = this.state
    return (
      <GoogleMap
        defaultZoom={DEFAULT_MAP_ZOOM}
        options={options}
        defaultCenter={center}

      >
        {markers && markers.length > 0 && <React.Fragment>
          {markers.map(marker => (
            <Marker
              icon={marker.icon}
              key={marker.id} position={marker.position} onClick={this.openMarker(marker.id)}>
              { markerOpen === marker.id && <CustomInfoWindow onCloseClick={this.closeMarker} defaultPosition={marker.position}>
                <div>
                  <h5 className="text-secondary">{marker.title}</h5>
                  <p className="text-white">
                    {marker.description}
                  </p>
                </div>
              </CustomInfoWindow>}
            </Marker>)
          )}
        </React.Fragment>}

      </GoogleMap>
    )
  }
}))


class GolfMap extends React.PureComponent {

  render(){
    const { center, markers } = this.props
    return (
      <div className="GolfMap bg-dark">
        <MapComponent
          center={center}
          markers={markers}
          options={{
            streetViewControl: false,
            zoomControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            styles: MAP_STYLE,
            draggable: false,
            scrollwheel: false,
            panControl: false,
          }}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&language=IT&region=IT&key=${MAPS_API_KEY}`}
          loadingElement={<div className="h-100" />}
          containerElement={<div className="h-100" />}
          mapElement={<div className="h-100" />}
        />
      </div>
    )
  }
}

GolfMap.defaultProps = {
  center: DEFAULT_MAP_CENTER,
}

export default GolfMap
