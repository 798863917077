import React from 'react'
import './GolfClubDetailRelatedActivities.scss'

const fakeLogos = ['carlotta', 'bistro', 'carlotta', 'bistro', 'carlotta', 'carlotta', 'bistro', 'carlotta', 'bistro', 'carlotta']

class GolfClubDetailRelatedActivities extends React.PureComponent {
  state = {
    logos: fakeLogos,
    startIndex: 0,
    endIndex: 5,
  }

  goNext = () => {
    this.setState(prevState => ({
      startIndex: prevState.startIndex + 1,
      endIndex: prevState.endIndex + 1,
    }))
  }

  goPrev = () => {
    this.setState(prevState => ({
      startIndex: prevState.startIndex - 1,
      endIndex: prevState.endIndex - 1,
    }))
  }

  render(){
    // const { logos, startIndex, endIndex } = this.state
    return (
      <div>
      <div className="golfclubdetail-related_activities container-fluid">
        <div className="golfclubdetail-related_activities-grass row" />
        {/* <div className="row bg-primary text-white">
          <div className="col-md-1 d-flex justify-content-center align-items-center">
            {logos.length > 5
            ? startIndex !== 0
              ? <i className="fa fa-chevron-left" onClick={startIndex !== 0 ? this.goPrev : null} />
              : <i className="fa fa-chevron-left text-muted" />
            : null}
          </div>
          <div className="col-md-10 golfclubdetail-related_activities-logos-container">
            {logos.slice(startIndex, endIndex).map((l, i) => (
              <div key={i} className="flex-1 text-center">
                <img src={`/images/logo-${l}.png`} alt={`${l} logo`} />
              </div>
            ))}
          </div>
          <div className="col-md-1 d-flex justify-content-center align-items-center">
            {logos.length > 5
            ? endIndex <= logos.length - 1
              ? <i className="fa fa-chevron-right" onClick={endIndex <= logos.length - 1 ? this.goNext : null} />
              : <i className="fa fa-chevron-right text-muted" />
            : null}
          </div>
        </div> */}
      </div>
      <div style={{ height: 100 }} className='bg-primary w-100' />
      </div>
    )
  }
}

export default GolfClubDetailRelatedActivities
