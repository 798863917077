import React, { Fragment } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { getAuthUser } from 'eazy-auth'
import qs from 'query-string'
import memoize from 'memoize-one'
import { t } from '../../i18n/index'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import { getClubs, loadClubs } from '../../state/clubs'
import {
  loadActivites,
  unloadActivities,
  getActivityTypesCounts,
  getActivities,
  getListActivities,
  loadListActivities
} from '../../state/activities'
import {
  getPlansCard,
  loadPlans,
} from '../../state/plans'
import {
  getSponsors,
  loadSponsors,
} from '../../state/sponsors'
import {
  getResorts,
  loadResorts,
} from '../../state/resorts'
import {
  loadIsta,
  getInsta,
} from '../../state/ig'
import Menu from '../../components/Menu'
import withScrollTop from '../../hocs/ScrollTop'
import Footer from '../../components/Footer'
import HomeCover from '../../components/HomeCover'
import HomeSummary from '../../components/HomeSummary'
import ActivityTypes from '../../components/ActivityTypes'
import MarkerMap, { TypedPopup } from '../../components/MarkerMap'
import HomeBooking from '../../components/HomeBooking'
import HomeBecomeMember from '../../components/HomeBecomeMember'
import HomeInstagram from '../../components/HomeInstagram'
// import HomeNewsLetter from '../../components/HomeNewsLetter'
import HomeGolfClubLogos from '../../components/HomeGolfClubLogos'
import HomeGolfSponsorLogos from '../../components/HomeGolfSponsorLogos'
import HomeActivitiesList from '../../components/HomeActivitiesList'
import HomeGolfClubsList from '../../components/HomeGolfClubsList'
import { DEFAULT_MAP_CENTER, DEFAULT_DISTANCE } from '../../constants'
import golfClubPinIcon from '../../components/MarkerMap/golf-club-pin.png'
import royalPinIcon from '../../components/MarkerMap/pin-the-royal-diamond.png'
import resortPinIcon from '../../components/MarkerMap/pin-resort.png'

import { groupByLatLng } from '../../utils'
import './Home.scss'
import moment from "moment";
import _, { filter } from "lodash";
import {Link} from "react-router-dom";
import regioni from '../../regioni.json';
import province from '../../province.json';
import {Field} from 'redux-form';
import {FieldDatepicker} from '../../components/Form/Fields';
import HomeFilters from './Filters';
import HomePromosSlider from '../../components/HomePromosSlider'
import HomeAssociators from '../../components/HomeAssociators/HomeAssociators'
import WallOfFame from '../../components/WallOfFame'
import { getConventions, getConventionsHeader, loadConventions, loadConventionsHeader } from '../../state/conventions'
import {PrivilegedGolfTourImage} from '../../components/PrivilegedGolfTourImage/PrivilegedGolfTourImage';

const ACTIVITY_ORDER = {
  Golf: 0,
  'Privileged Golf Tour': 1,
  'Privileged Resort': 2,
  Hotel: 3,
  Ristoranti: 4,
  Fitness: 5,
  Wellness: 6,
}

class Home extends React.Component {
  state = {
    // map|list
    view: 'map',
    search: '',
    regione: null,
    provincia: null,
    date: '',
    currentCategory: '',
    showServices: ''
  }

  setStateFromFilters = async (passedState, tmpType, useLocalState) => {
    console.log({passedState})
    const storageDate = localStorage.getItem('selectedDate')
    console.log({storageDate})
    this.setState({date: passedState.date || storageDate})

    const newState = useLocalState ? this.state : passedState

    let type = tmpType ? tmpType : +(get(qs.parse(this.props.location.search), 'category', 0) || 0)
    if( type == 0){
      await this.props.loadClubs({date: newState.date || storageDate})
    }else{
      const { provincia, regione, search } = newState
      this.props.loadListActivities({
        type: type,
        region: regione === null ? '' : regione.nome,
        province: provincia === null ? '' : provincia.sigla_automobilistica.toLowerCase(),
        text: search,
      })
    }

    this.setState({...newState, date: newState.date || storageDate})
  }



  componentDidMount() {
    this.props.loadConventions()
    const { user } = this.props;

    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    }
    localStorage.setItem('selectedDate', '')

    const expiryDate = moment(_.get(user, 'loyaltyCard.expiryDate', null))
    const renewAsked = localStorage.getItem('renewAsked');
    // alert(expiryDate.diff(new Date(), 'days')< 0)
    if (expiryDate.diff(new Date(), 'days') < 0 && renewAsked !== 'true') {
      this.props.history.push({
        pathname: '/rinnova-tessera',
        state: { user }
      })
    }

    this.props.loadActivites({
      ...DEFAULT_MAP_CENTER,
      distance: DEFAULT_DISTANCE,
    })
    this.props.loadClubs({})
    this.props.loadPlans()
    this.props.loadIsta()
    this.props.loadSponsors()
    this.props.loadResorts()
  }

  componentDidUpdate(){
    const { location } = this.props
    const { view } = this.state
    const searchParams = qs.parse(location.search)
    console.log({searchParams})
    const showServices = searchParams.showServices
    const isValidShowServices = (showServices === null || showServices === 'true')
    if(searchParams.category !== undefined && (searchParams.category !== this.state.currentCategory ||
      (showServices !== this.state.showServices && isValidShowServices))){
      console.log('REFRESH')
      console.log({state: this.state})
      console.log({isValidShowServices})

      this.setState({currentCategory: searchParams.category, showServices: showServices}, () => {
        this.setStateFromFilters({}, searchParams.category, true )
        setTimeout(() => {
          if (isValidShowServices) {
            this.services && this.services.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          } else {
            this.mapSection && this.mapSection.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }, 100)
      })
    }

  }

  componentWillUnmount() {
    this.props.unloadActivities()
  }

  setView = view => this.setState({ view })

  handleOnBecomeMemberClick = () => {

  }

  handleOnBookingClick = () => {
    this.mapSection.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }

  toggleSectedType = type => {
    this.props.history.push(`/?category=${type.id}`)
  }

  getMarkers = memoize((clubs, activities, activityTypes, selectedType, resorts) => {
    // Markers of clubs
    if (+selectedType === 0) {
      if (clubs === null) {
        return []
      }
      return clubs.map(club => ({
        ...club,
        icon: golfClubPinIcon,
        type: 'club',
        position: {lat: club.coordinates[1], lng: club.coordinates[0]}
      }))
    }

    // Markers of events (the royal diamond)
    if (+selectedType === 23) {
      if (clubs === null) {
        return []
      }
      return clubs
        .filter(club => get(club, 'events', []).length > 0)
        .map(club => ({
          ...club,
          icon: royalPinIcon,
          type: 'event',
          position: {lat: club.coordinates[1], lng: club.coordinates[0]}
        }))
    }

    // Markers of Resorts
    if (+selectedType === 1000) {
      if (!resorts) {
        return []
      }
      return resorts
        .map(resort => ({
          ...resort,
          icon: resortPinIcon,
          type: 'resort',
          position: {lat: resort.coordinates[1], lng: resort.coordinates[0]}
        }))
    }
    console.log('MapActivies', activities)
    const typeById = keyBy(activityTypes, 'id')
    return (activities === null || selectedType === null)
      ? []
      : groupByLatLng(get(activities, selectedType, []).map(activity => ({
        ...activity,
        id: activity.Id,
        type: 'activity',
        icon: typeById[selectedType].markerIcon,
        position: {
          lat: +activity.Geo.Lat.replace(',', '.'),
          lng: +activity.Geo.Long.replace(',', '.')
        }
      })))
  })


  getMatchingMarkers = memoize((markers, search, idRegione, provincia, selectedType) => {
    if (selectedType !== 0) {
      return markers
    }
    const filtered = markers.filter(marker => {
      console.log({marker, search})
      const matchSearch = search ? _.get(marker, 'name', '').toLowerCase().indexOf(search.toLowerCase()) !== -1 : true

      // Search text not match
      if (!matchSearch) {
        return false
      }

      if (provincia) {
        // Check for provincia
        return marker.address.municipality === provincia
      } else if (idRegione) {
        // Check for regione
        const provinceRegione = province
          .filter(provincia => provincia.id_regione === idRegione)

        return provinceRegione
          .some(provincia => marker?.address?.municipality === provincia?.sigla_automobilistica)
      }

      return true
    })
    return  filtered.map(fil => ({...fil, id: fil._id || fil.Id}))

  })

  getActivityTypesWithGolf = memoize((clubs, activityTypes, resorts) => {
     let allActivityTypes = [{
      id: 0,
      label: 'Golf',
      image: '/images/icona-golf-club-gold.png',
      selectedImg: '/images/icona-golf-club-blue.png',
      count: clubs === null ? null : clubs.length,
    }].concat(activityTypes)
    console.log({clubs})
    let eventsCount = null
    if (clubs !== null) {
      eventsCount = clubs.reduce((count, club) =>
        get(club, 'events', []).length + count, 0)
    }

    allActivityTypes = allActivityTypes.concat({
      id: 23,
      label: 'Privileged Golf Tour',
      image: '/images/icona-diamond.png',
      count: eventsCount + 1
      // markerIcon: pinSpaIcon
      // markerIcon: pinSpaIcon
    })

    allActivityTypes.splice(1, 0, {
      id: 1000,
      label: 'Privileged Resort',
      image: "/images/privileged-resort-icon.png",
      // selectedImg: "/images/icona-golf-club-blue.png",
      count: resorts ? resorts.length : 0
    })
    return allActivityTypes.sort((a, b) => ACTIVITY_ORDER[a.label] - ACTIVITY_ORDER[b.label])

    // console.log('C', clubs, eventsCount)
  })

  getLogos = memoize(clubs => {
    if (clubs === null) {
      return []
    }
    // medium squared
    return clubs
      .filter(club => club.logo)
      // .map(club => club.logo.thumbnail.medium)
  })

  render() {
    const { clubs, activities: AllActivites, activityTypes, user, plans, instagram, location, sponsors, resorts, conventions, filteredActivities } = this.props
    const { view, search, regione, provincia, date } = this.state
    const activities = filteredActivities ? {[+(get(qs.parse(location.search), 'category', 0) || 0)]:filteredActivities?.map(fa => ({
      Id:fa.Id,
      Geo:fa.Geo
  }))} : AllActivites
    const activityTypesWithClubs = this.getActivityTypesWithGolf(clubs, activityTypes, resorts)
    const selectedType = +(get(qs.parse(location.search), 'category', 0) || 0)
    const selected = { [selectedType]: true }
    const plainMarkers = this.getMarkers(clubs, activities, activityTypes, selectedType, resorts)
    const logos = this.getLogos(clubs)
    console.log({activities})
    const markers = this.getMatchingMarkers(
      plainMarkers,
      search,
      regione ? regione.id : null,
      provincia ? provincia.sigla_automobilistica : null,
      selectedType
    )

    console.log({instagram})
    console.log({markers})
    console.log({resorts})
    console.log({sponsors})
    console.log({activityTypesWithClubs})
    console.log({date})

    return <div className='home with-topbar'>
      <Menu bgColor={'rgb(1, 22, 50)'}/>
      <HomeCover
        onBookingClick={this.handleOnBookingClick}
        onBecomeMemberClick={this.handleOnBecomeMemberClick}
        user={user}
      >

      </HomeCover>
      {/*{logos.length > 0 && <HomeGolfClubLogos logos={logos}/>}*/}
      {/*{logos.length > 0 && */}
      {logos && logos.length > 0 && <HomeGolfSponsorLogos sponsors={logos}/> }
      <HomePromosSlider user={user} conventions={conventions}/>
      {!user && <HomeSummary/>}

      <div className='map-top-line d-flex flex-column justify-content-center align-items-center' style={{marginTop:user ? 32 : 0}}>
        <h1 style={{textAlign: 'center'}}>{t('book your service')}</h1>
        <p className='h6 font-weight-light text-align-center' style={{textAlign:'center',color:'#efefef'}}> {t('click on icons')}<br/>{t('and see terms')}</p>
      </div>
      <div id='services' ref={r => this.services = r} style={{paddingBottom: 36}}/>
      <div className='mt-5 mb-2' >
        <ActivityTypes
          className='py-2'
          selected={selected}
          onClick={this.toggleSectedType}
          tall={false}
          activityTypes={activityTypesWithClubs}
          uniformFontSize={true}
          homeActivities={true}
        />

      </div>
      <div id='mapSection' ref={r => this.mapSection = r} style={{paddingBottom:36}}/>
      <div className={`${view}-container mapContainer-home mt-3`} >

        <div className='home-filters-wrapper'>
            <HomeFilters setStateHome={this.setStateFromFilters} view={view} setView={(val) => this.setView(val)} type={selectedType} filters={this.state}/>
        </div>
        {view === 'map' && <MarkerMap
          popupComponent={(marker)  => TypedPopup({...marker, date})}
          markers={markers}
          date={date}
          options={{
            zoomControlOptions: {
              position: 7,
            },
          }}
        />}
        {view === 'list' && <Fragment>
          {(selectedType === 0 || selectedType === 23) &&
            <HomeGolfClubsList markers={markers} date={date}/>
          }
          {(selectedType !== 0 && selectedType !== 23) && (
            <HomeActivitiesList type={selectedType} {...this.state}/>
          )}
        </Fragment>}

      </div>
      <div className='home-whyassociated pt-5 w-100' style={{backgroundColor: '#F6F6F6'}}>
        <h1 className='font-weight-bold' style={{textAlign:'center'}}>{!user ? t('why associate') : t('not finish')}</h1>
        <HomeAssociators user={user} history={this.props.history} location={location}/>
      </div>
      <div className='home-sponsorsbox'>
        <PrivilegedGolfTourImage history={this.props.history}/>
        {/*
          <div className="home-sponsorsbox-image"></div>
          <div className="wrapper-logos">
            <div className='home-new-container'>
              <div className='home-text-container'>
                <h1 style={{fontWeight:'bold'}}>Privileged Golf Tour</h1>
                <h2 className='home-sub-header-text'>Sponsored by</h2>
              </div>
              <div className='home-logos-container'>
                {Array(13).fill(null).map((a,i) => {
                  return <div className='home-sLogo'>
                    <img src={`/images/home-logos/${i+1}.png`} className='home-logo-image'/>
                  </div>
                })}
              </div>
            </div>
          </div>
        */}
      </div>
      <div className='pt-5 pb-5' style={{width:'100%', maxWidth:'100%'}}>
        <h1 className='text-secondary-new font-weight-bold' style={{textAlign:'center'}}>Wall of fame</h1>
        <div className='d-flex flex-row pt-5' style={{width:'100%', maxWidth:'100%'}}>
          <WallOfFame />
        </div>
      </div>
      <div ref={r => this.bookingSection = r}/>
      {/*<HomeBooking/>*/}

      {/*<Link to="/convenzioni">*/}
      {/*  <div className='map-top-line-sponsor'>*/}
      {/*    <p className='convention-title'>LE CONVENZIONI DEDICATE AI SOCI PRIVILEGED</p>*/}
      {/*  </div>*/}
      {/*</Link>*/}

      {/*<div ref={r => this.becomeMemberSection = r}/>*/}
      {/*{!user && <HomeBecomeMember*/}
      {/*  cards={plans}*/}
      {/*/>}*/}
      {/*<HomeInstagram instagram={instagram}/>*/}
      {/* <HomeNewsLetter /> */}
      <Footer/>
    </div>;

  }
}

export default connect(state => ({
  user: getAuthUser(state),
  clubs: getClubs(state),
  activities: getActivities(state),
  filteredActivities: getListActivities(state),
  activityTypes: getActivityTypesCounts(state),
  plans: getPlansCard(state),
  instagram: getInsta(state),
  sponsors: getSponsors(state),
  resorts: getResorts(state),
  conventions: getConventions(state),
  conventionsHeader: getConventionsHeader(state),
}), {
  loadClubs,
  loadIsta,
  loadActivites,
  unloadActivities,
  loadPlans,
  loadListActivities,
  loadSponsors,
  loadResorts,
  loadConventions,
  loadConventionsHeader,
})(withScrollTop(Home))


