import React from 'react'
import Carousel from 'nuka-carousel'
import { isDesktopScreen } from '../../utils'
import './HomeGolfClubLogos.scss'

class HomeGolfClubLogos extends React.PureComponent {

  render(){
    const { logos } = this.props
    let slidesToShow
    if (isDesktopScreen()) {
      slidesToShow = 6
    } else {
      slidesToShow = 2
    }

    return (
      <div className='home-golf-logos'>
        <Carousel
          // autoplay={true}
          autoplay={false}
          autoplayInterval={2000}
          transitionMode={"scroll"}
          easing={'easeSinOut'}
          renderBottomCenterControls={() => null}
          renderCenterLeftControls={({ previousSlide }) =>
            <div className='text-white left-control' onClick={previousSlide}>
              <i className="fa fa-chevron-left" />
            </div>
          }
          renderCenterRightControls={({ nextSlide }) =>
            <div className='text-white right-control' onClick={nextSlide}>
              <i className="fa fa-chevron-right" />
            </div>
          }
          // heightMode='max'
          wrapAround={true}
          slidesToShow={slidesToShow}
        >
          {logos.map((logo, i) => (
            <div className='golf-logo-cont' key={i}>
              <img key={i} src={logo} alt={'Club logo'} />
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export default HomeGolfClubLogos
