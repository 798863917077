import React from 'react'
import findIndex from 'lodash/findIndex'
import memoize from 'memoize-one'
import './GolfClubDetailIconServices.scss'
import _ from 'lodash'
import {Field, reduxForm} from 'redux-form';
import {FieldDatepicker} from '../Form/Fields';
import moment from 'moment';
import {t} from "i18next";
const SERVICES_ICONS = {
  'green-fee-9': {
    icon: '/images/servizi-golf-green-free.png',
    alt: 'Green fee',
    text: ['9',t('holes')]
  },
  'green-fee-9-holiday': {
    icon: '/images/servizi-golf-green-free.png',
    alt: 'Green fee',
    text: ['9',t('holes')]
  },
  'green-fee-18': {
    icon: '/images/servizi-golf-green-free.png',
    alt: 'Green fee',
    text: ['18',t('holes')]
  },
  'green-fee-18-holiday': {
    icon: '/images/servizi-golf-green-free.png',
    alt: 'Green fee',
    text: ['18',t('holes')]
  },
  'green-fee-practice': {
    icon: '/images/servizi-golf-green-free.png',
    alt: 'Green fee',
    text: t('practice field')
  },
  'golf-cart-9': {
    icon: '/images/servizio-club-golf-cart.png',
    alt: 'Golf Cart',
    text: ['9',t('holes')]
  },
  'golf-cart-18': {
    icon: '/images/servizio-club-golf-cart.png',
    alt: 'Golf Cart',
    text: ['18',t('holes')]
  },
  'handheld-golf-cart-manual': {
    icon: '/images/servizi-golf-carrello.png',
    alt: 'Carrello',
    text: t('manual')
  },
  'handheld-golf-cart-electric': {
    icon: '/images/servizi-golf-carrello.png',
    alt: 'Carrello',
    text: t('electric')
  },
  'golf-bag-rental': {
    icon: '/images/servzi-golf-noleggio-sacca.png',
    alt: t('bag rental'),
    text: t('bag rental')
  },
  'practice-field-coin': {
    icon: '/images/servizi-golf-gettone.png',
    alt: t('token'),
    text: t('token')
  },
  'pool-and-solarium': {
    icon: '/images/servizi-golf-piscina.png',
    alt: t('pool'),
    text: t('pool and solarium')
  },
  'spa': {
    icon: '/images/servizi-golf-spa.png',
    alt: 'Spa',
    text: 'Spa'
  },
  'restaurants': {
    icon: '/images/servizi-golf-pranzo.png',
    alt: t('restaurants'),
    text: t('restaurants')
  },
  'guest-quarters': {
    icon: '/images/servizi-golf-lezione.png',
    alt: t('lesson'),
    text: t('lesson')
  },
  // ????
  'service-pack-1': {
    icon: '/images/service_pack.png',
    alt: 'SP',
    text: 'Service pack 1'
  },
  'service-pack-2': {
    icon: '/images/service_pack.png',
    alt: 'SP',
    text: 'Service pack 2'
  },
  'default': {
    icon: '/images/service_pack.png',
    alt: 'Service Pack',
    text: 'Service pack'
  },
}

class GolfClubDetailIconServices extends React.PureComponent {

  getServicesList = memoize(services => {
    return services.reduce((list, service) => {

      const serviceIconConf = SERVICES_ICONS[service.name]
      if (typeof serviceIconConf === 'undefined') {
        return list
      }

      const index = findIndex(list, { icon: serviceIconConf.icon })
      if (index === -1)  {
        return list.concat({
          icon: serviceIconConf.icon,
          alt: serviceIconConf.alt,
          texts: [serviceIconConf.text]
        })
      }

      list[index] = {
        ...list[index],
        texts: list[index].texts.concat(serviceIconConf.text)
      }
      return list
    }, [])
  })

  componentWillReceiveProps(nextProps, nextContext) {
    console.log({nextProps})
    if (nextProps.date) {
      this.props.change('dateTimeStart', moment(nextProps.date).format('DD/MM/YYYY'))
    }
  }

  onChangeDate = (newDate) => {
    let readableDate = ''
    _.map(newDate, d => {
      if (typeof d === 'string') {
        readableDate = `${readableDate}${d}`
      }
    })
    const formattedDate = moment(readableDate, 'DD/MM/YYYY').toISOString()
    this.props.onChangeDate(formattedDate)
  }

  render(){
    const { services, reserve } = this.props
    // const servicesList = this.getServicesList(services)
    return (
      services && services.length > 0 && <div>
        <div className="p-3 text-body text-center" style={{ fontSize: '.9rem' }}>
          {t('click on icons and terms')}
        </div>

        <div className='date-filter' style={{display:'flex',justifyContent:'center',width:'100%',maxWidth:'100%'}}>
          <Field
            emptyClassName='text-placeholder'
            row
            colLabel={3}
            colInput={12}
            name='dateTimeStart'
            placeholder={t('filter by day')}
            component={FieldDatepicker}
            style={{width:'25%', minWidth:250}}
            onChange={this.onChangeDate}
            // validate={[required, mustBookingDate]}
          />

        </div>
        <div className="golfclubdetail-iconservices row no-gutters">
          {services && services.length > 0 && services.map((service, i) => {
            let iconData = SERVICES_ICONS[service.name] ||  SERVICES_ICONS['default']
            const imageData = _.get(service, 'image.thumbnail.squared')
            console.log({service})
            const isOpen = service.isOpen !== false
            return (!!iconData || !!imageData) && (
            <div key={i} className='golfclubdetail-service col-md col-6' onClick={reserve}>
              {imageData ?
                <img
                  src={imageData}
                  className={`${!isOpen && 'service-image-close'}`}
                />
              :
                <img
                  className={`${!isOpen && 'service-image-close'}`}
                  src={iconData.icon}
                  alt={iconData.alt}
                />
              }

              <div className={`text-center pt-3 ${!isOpen && 'service-image-close'}`} style={{color: '#DAA056'}}>
                {service.description}
              </div>
            </div>
          )})}
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'GolfClubDetailIconServices',
})(GolfClubDetailIconServices)
