import React from 'react'
import { Link } from 'react-router-dom'
import './HomeBecomeMember.scss'

const Card = ({ card }) => (
  <div className="col-md mt-5 text-center p-4 homebecomemember-card-container">
    <div className="homebecomemember-card card-flexed">
      <img src={card.selectedIcon} alt='card icon' className="homebecomemember-card-image" />
      <div className="homebecomemember-card-text-wrapper">
        <h4 className="mb-3">{card.name}</h4>
        <p className='card-description'>{card.description}</p>
      </div>
      <div className="flex-1"></div>
      <h4>
        {card.price}{' €'}{!card.extraPrice && '*'}
        {card.extraPrice > 0 && ` + ${card.extraPrice} € cad*`}
      </h4>
      <div className='homebecomemember-card-ship-price'>
        *Escluse spese di spedizione pari a {card.shipmentPrice} €
      </div>
    </div>
  </div>
)

class HomeBecomeMember extends React.PureComponent {
  render(){
    const { cards } = this.props
    return (
      <div className="container-fluid homebecomemember homebecomemember-background">
        <div className="row">
          <div className="col-md-12 text-center my-5 pt-5">
            <h2 className="text-primary">Diventa socio</h2>
            <h5 className="pay-off mb-5">EXECUTIVE MEMBER PRIVILEGED</h5>
            <p className='text-muted mb-5'>
              Diventa un <b>Executive Member Privileged</b>, riceverai subito il Privileged Kit,<br />
              con la tua membership card personalizzata e la bag tag per la tua sacca da golf.<br />
              Potrai beneficiare per 12 mesi di tariffe esclusive presso le migliori strutture Golf & Leisure.<br />
              <br />
              <b>Avrai sempre il 50% di sconto sui prezzi di listino.<br />
                Potrai partecipare di diritto al Privileged Golf Tour</b>
            </p>
          </div>
        </div>
        <div className="homebecomemember-images-row row hidden-md">
          <div className="col-md-4 text-center">
            <img src={'/images/macchinina.png'} alt="golf cart" className="homebecomemember-cart-img" />
          </div>
          <div className="col-md-5" />
          <div className="col-md-3 text-center">
            <img src={'/images/player.png'} alt="golf cart" className="homebecomemember-player-img" />
          </div>
        </div>
        <div className="homebecomemember-separator row" />
        {/*<div className="row bg-primary text-white homebecomemember-cards">*/}
        {/*  {cards.map(card => (*/}
        {/*    <Card*/}
        {/*      key={card.id}*/}
        {/*      card={card}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</div>*/}
        <div className="row bg-primary py-5 text-white">
          <div className="col-md-12 mt-4 text-center">
            <p className="text-white">
              *Anche se non giochi a Golf se ti associ a Privileged potrai beneficiare per un anno dei nostri vantaggi<br/>
              esclusivi presso gli hotel, i ristoranti, le SPA e i centri fitness convenzionati.
            </p>
          <div className="mt-4 mb-2">
            {/*<Link to={`/diventa-socio`} className="btn btn-lg homebecomemember-btn">Richiedi la tessera</Link>*/}
              <a href="https://shop.privilegedgolf.com/" target="_blank" className="btn btn-lg homebecomemember-btn">Richiedi la tessera</a>
          </div>
          <p className="homebecomemember-small">Hai già la tessera Privileged The Club e vuoi procedere con l'iscrizione?</p>
          <Link to={`/registra-tessera`} className="text-secondary"><small><b>Clicca qui</b></small></Link>
        </div>
       </div>
      </div>
    )
  }
}

export default HomeBecomeMember
