import request from 'superagent'
import { createSelector } from 'reselect'
import { rj } from 'redux-rocketjump'
import { takeEveryAndCancel } from 'redux-rocketjump/effects'
import combineRjs from 'redux-rocketjump/plugins/combine'
import rjPosArgs from 'redux-rocketjump/plugins/positionalArgs'
import { authApiCall, withToken } from './auth'
import { API_URL, MASTER_TOKEN } from '../constants'

const GET_CONVENTIONS = 'GET_CONVENTIONS'
const GET_CONVENTIONS_HEADER = 'GET_CONVENTIONS_HEADER'

export const {
  connect: {
    list: {
      actions: {
        load: loadConventions,
      },
      selectors: {
        getData: getConventions,
      }
    },
    header: {
      actions: {
        load: loadConventionsHeader,
      },
      selectors: {
        getData: getConventionsHeader,
      }
    },
  },
  saga,
  reducer,
} = combineRjs({

  list: rj({
    type: GET_CONVENTIONS,
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/affiliations`))
      .then(({ body }) => body)
  }),

  header: rj({
    type: GET_CONVENTIONS_HEADER,
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/affiliations/header`))
      .then(({ body }) => body)
  }),

}, {
  state: 'conventions',
  callApi: authApiCall,
})
