import React from 'react'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import './Sponsor.scss'
import withScrollTop from '../../hocs/ScrollTop'
import _ from 'lodash'
import {connect} from "react-redux";
import {
  getSponsors,
  loadSponsors,
    getSponsorsHeader,
    loadSponsorsHeader
} from "../../state/sponsors";


class Sponsor extends React.Component {

  state = {
    sponsors: []
  }


  componentDidMount() {
    this.props.loadSponsors()
    this.props.loadSponsorsHeader()
  }

  render(){
    const { sponsors, sponsorsHeader } = this.props;
    console.log({sponsors})


    return <div className="sponsor">
      <Menu bgColor={'rgb(1, 22, 50)'}/>
      <div className="sponsor-header">
        <img src={sponsorsHeader ? sponsorsHeader.full : ''} className={'header-image'}/>
      </div>

      <div className="card-wrapper">
        <h1 className="title font-700" style={{fontSize: 60}}>Sponsor Privileged</h1>
        <div className="row row-cols-xl-5">
          {_.map(sponsors, sponsor => {
            return (
              <div className="col-12 col-md-6 col-lg-4 col-xl card-wrapper-sponsor">
                <a href={sponsor.permalink} target="_blank" className="card-link">
                  <div className="card-sponsor">
                    <img src={_.get(sponsor, 'logo.thumbnail.medium', '')} className="sponsor-image"/>
                    <div className="card-text">
                      <div className="text-title">{sponsor.title}</div>
                      <div className="description">{sponsor.description}</div>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  }
}

export default connect(state => ({
  sponsors: getSponsors(state),
  sponsorsHeader: getSponsorsHeader(state),
}), {
  loadSponsors,
  loadSponsorsHeader,
})(withScrollTop(Sponsor))
