import React from 'react'
import Carousel from 'nuka-carousel'
import { isDesktopScreen } from '../../utils'
import './HomeGolfSponsorLogos.scss'
import _ from 'lodash'
import {Link} from "react-router-dom";

class HomeGolfSponsorLogos extends React.PureComponent {

  render(){
    const { sponsors } = this.props
    console.log({PROPS: this.props})
    let slidesToShow
    if (isDesktopScreen()) {
      slidesToShow = 7
    } else {
      slidesToShow = 2
    }

    if (sponsors.length < slidesToShow) {
      slidesToShow = sponsors.length - 1
    }

    return (
      <div className='home-golf-sponsor-logos'>
        {sponsors.length > 0 ?
          <Carousel
            // autoplay={true}
            autoplay={false}
            transitionMode={"scroll"}
            easing={'easeSinOut'}
            style={{height:110, paddingTop: 5, paddingBottom: 5}}
            renderBottomCenterControls={() => null}
            renderCenterLeftControls={({previousSlide}) =>
              <div className='left-control' onClick={previousSlide}>
                <i className="fa fa-chevron-left"/>
              </div>
            }
            renderCenterRightControls={({nextSlide}) =>
              <div className='right-control' onClick={nextSlide}>
                <i className="fa fa-chevron-right"/>
              </div>
            }
            // heightMode='max'
            wrapAround={true}
            slidesToShow={slidesToShow}
          >
            {sponsors.map((club, i) => {
              return (
                <div className='golf-logo-cont' >
                  <Link to={`/club/${club._id}`} key={`row-logo-${i + 1}`}>
                    <img src={_.get(club, 'logo.thumbnail.medium', '')} style={{objectFit:'contain'}}/>
                  </Link>
                </div>
              );
            })}
          </Carousel> :
          <h5>LOGHI SPONSOR</h5>
        }
      </div>

    );
  }
}

export default HomeGolfSponsorLogos
