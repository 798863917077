import React from 'react'
import './HomeBooking.scss'
import diamondLogo from './Royal_Diamond_Logo_gold.png'
import WAGC from './WAGC.jpg'
import amatuerGolfOpen from './AMATEURS.png'
import vector from './vector.png'
import { Link } from 'react-router-dom'

class HomeBooking extends React.PureComponent {
  render(){
    return (
      <div className="homebooking">

        <div className="homebooking-event">
          {/*<div className="logo-container">*/}
            {/*<img className="diamond-logo" src={diamondLogo} alt="royal diamond logo"/>*/}
            {/*<img className="diamond-logo" src={WAGC} alt="royal diamond logo"/>*/}
            {/*<img className="diamond-logo" src={amatuerGolfOpen} alt="royal diamond logo"/>*/}
            {/*<img className="diamond-logo" src={vector} alt="royal diamond logo"/>*/}
          {/*</div>*/}
          <Link to="/the-royal-diamond/" className="no-link-style">
            <h3>Partecipa al Privileged Golf Tour.
              {/*<br/>Il circuito Amateur*/}
              {/*più importante in Italia,<br/>con finale mondiale in Messico*/}
            </h3>
          </Link>

        </div>
      </div>
    )
  }
}

export default HomeBooking
