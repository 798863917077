import ReactDOM from 'react-dom'
import React, {Fragment} from 'react'
import moment from 'moment'
// import paypal from 'paypal-checkout'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector, setSubmitSucceeded, stopSubmit, startSubmit } from 'redux-form'
import { required, mustIntPositive, lessOrEqualThan } from '../Form/validation'
import { FieldInput, FieldCheckbox } from '../Form/Fields'
import UserFormSection from '../CommonForm/UserFormSection'
import AccountFormSection from '../CommonForm/AccountFormSection'
import './RenewsMemberForm.scss'
import {obtainPaymentTokenRenew} from "../../state/payment";
import {openPrivacyPolicy, openTermsCondition} from "../../utils";

// const PAYPAL_ENV = process.env.REACT_APP_PAYPAL_ENV || 'sandbox'
// const PAYPAL_ENV =  process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'
const PAYPAL_ENV = 'production'
// console.log(PAYPAL_ENV)

const validate = data => {
  let errors = {}
  if (data.password !== data.passwordConfirm) {
    errors.passwordConfirm = 'Le password devono coincidere'
  }
  if (data.email !== data.emailConfirm) {
    errors.emailConfirm = 'Le email devono coincidere'
  }
  return errors
}

const lessOrEqualThanThree = lessOrEqualThan(3)

class RenewsMemberForm extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.plan !== prevProps.plan) {
      this.props.change('extraCount', null)
    }
  }

  submitAndPay = (e) => {
    // NOTHING TO DO
    e.preventDefault()
  }

  payment = () => {
    const { userData, extraCount, obtainPaymentTokenRenew, plan, initialValues} = this.props

    return new window.paypal.Promise((resolve, reject) => {
      return obtainPaymentTokenRenew({
        email: initialValues.email,
      })
        .then(r => {
          resolve(r.data.paymentId)
        })
        .catch(err => {

          reject(err)
        })
    })
  }

  authorize = (data) => {
    const {
      performPaymentRenew,
      userData,
      paymentData,
      startSubmit,
      stopSubmit,
      setSubmitSucceeded,
      onSubmitSuccess,
      initialValues
    } = this.props

    const userDataFixed = {
      ...initialValues,
      birthDate: moment(initialValues.birthDate, 'DD/MM/YYYY', true)
        ? moment(initialValues.birthDate, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
        : initialValues.birthDate
    }
    startSubmit('becomeMember')
    return new window.paypal.Promise((resolve, reject) => {
      return performPaymentRenew({
        paymentId: data.paymentID,
        payerId: data.payerID,
        transactionId: paymentData.transactionId,
        userData: userDataFixed,
      })
        .then(result => {
          console.log({result})
          resolve(result)
          stopSubmit('becomeMember')
          setSubmitSucceeded('becomeMember')
          onSubmitSuccess(result)
        })
        .catch(err => {
            console.log({err})
            let errorMessage = get(err, 'response.body.error', 'Impossibile eseguire la registrazione.')
          if (typeof errorMessage !== 'string') {
            errorMessage = 'Impossibile eseguire la registrazione.'
          }
          stopSubmit('becomeMember', {
            _error: errorMessage,
          })
          reject(err)
        })
    })
  }

  render() {
    const {
      plan,
      valid,
      extraCount,
      paymentData,
      loadingPaymentToken,
      submitting,
      submitSucceeded,
      paymentDataError,
      error,
      clubs,
      initialValues
    } = this.props

    let price = null
    if (parseInt(plan.extraPrice) > 0) {
      if (!isNaN(parseInt(extraCount))) {
        price = plan.price + (parseInt(extraCount) * plan.extraPrice) + plan.shipmentPrice
      }
    } else {
      price = plan.price + plan.shipmentPrice
    }

    const PayPalButton = window.paypal.Button.driver('react', { React, ReactDOM })

    return (
      <form className='becomemember-form mt-2' onSubmit={this.submitAndPay}>

        {/* TOP FOR SECTION (THE BLUE ONE)  */}
        <div className='container'>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="becomemember-form-user-section">
                <div className='becomemember-form-user-caption'>
                  <h3 style={{fontSize: 36}}>Rinnova la tessera</h3>
                  <h3>{plan.name}</h3>
                  <p className='mt-5'>{plan.description}</p>
                </div>
                <img
                  src={'/images/golf-flag-right.png'}
                  alt="golf flag"
                  className="becomemember-form-flag-img hidden-md"
                />
                <UserFormSection
                  clubs={clubs}
                  className='mt-5'
                  readOnly={true}
                />

                {plan.extraPrice > 0 && <div className='row mt-3'>
                  <div className='col-md-6 offset-md-3'>
                    <Field
                      name='extraCount'
                      className='border-white bg-primary text-white'
                      placeholder='N° Figli'
                      component={FieldInput}
                      validate={[required, mustIntPositive, lessOrEqualThanThree]}
                    />
                  </div>
                </div>}

              </div>
            </div>
          </div>
        </div>

        {/* ACCOUNT FORM SECTION \w BACKGROUND  */}
        <div className='becomemember-account-container'>
          <div className='container'>
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="becomemember-form-account-section">

                  <div className='becomemember-payment-container'>

                    {price && <div className='text-center mb-2'>
                      <h3 className='text-secondary'>Totale da pagare*</h3>
                      <h1>€ {price}</h1>
                      <small className='text-primary font-weight-bold'>* Nell'importo sono incluse le SPESE di
                        SPEDIZIONE</small>
                    </div>}

                    <div className='mt-2'>
                      <Field
                        label={<span>Dichiaro di aver preso visione del <a className="cursor-pointer" onClick={openTermsCondition}>regolamento generale</a> della piattaforma</span>}
                        name='aceptTheRules'
                        validate={required}
                        component={FieldCheckbox}
                      />
                      <Field
                        label={<span>Dichiaro di aver preso visione dell' <a className="cursor-pointer" onClick={openPrivacyPolicy}>informativa sulla privacy</a></span>}
                        name='aceptPrivacy'
                        validate={required}
                        component={FieldCheckbox}
                      />
                    </div>

                    {submitting && <div className='text-white'>
                      <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                    </div>}

                    {!submitting && !valid && <div className="text-center mt-2">
                      <b style={{opacity: !valid ? undefined : 0}}>Completa i campi richiesti per proseguire con il
                        pagamento</b>&nbsp;
                    </div>}

                    {!submitSucceeded && (
                      <div style={{opacity: !valid ? 0.5 : undefined, pointerEvents: !valid ? 'none' : undefined}}>
                        <br/>
                        <PayPalButton
                          key={valid.toString()}
                          env={PAYPAL_ENV}
                          client={{
                            // sandbox:    'sb-zzppk1897860@personal.example.com',
                            production: 'AZ8_Dtmd3FCGtw-Wf-Z6skIzDZqNUCpMmxH_twOQrjzPIwzEg4ZEYGRTw7ud0Mg0CYVOdunemv-YXIcx'
                          }}
                          payment={this.payment}
                          onAuthorize={this.authorize}
                          commit={true}
                          style={{size: 'large'}}
                        />
                      </div>
                    )}

                    <div className="my-3 text-center">
                      <img className="img-fluid img-credit-cards" src="/images/pay-pal_copy.png" alt="credit cards"/>
                    </div>

                    {paymentDataError && <div className='alert alert-danger mt-4'>
                      <div><b>Errore durante il recupero del metodo di pagamento:</b></div>
                      <p>{get(paymentDataError, 'response.body.error')}</p>
                    </div>}

                    {error && <div className='alert alert-danger mt-4'>
                      <b>Errore in fase di registrazione.</b>
                      <div>{error}</div>
                      <div>Riprova più tardi.</div>
                    </div>}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="becomemember-form-bottom-image-container hidden-md">
            <img src="/images/macchinina.png" alt="golf car" className="img-fluid"/>
          </div>
        </div>

      </form>
    );
  }
}

const selector = formValueSelector('becomeMember')
// const errorSelector = getFormSyncErrors('becomeMember')

export default reduxForm({
  form: 'renewsMember',
  validate,
})(connect(state => ({
  // formErrors: errorSelector(state),
  extraCount: selector(state, 'extraCount'),
  userData: selector(state,
    'email', 'password', 'name', 'surname', 'birthDate', 'address',
    'fiscalCode', 'phoneNumber', 'favoriteClubId',
  ),
}), {
  setSubmitSucceeded,
  stopSubmit,
  startSubmit,
})(RenewsMemberForm))
