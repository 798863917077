import React from 'react'
import './BecomeMemberCardsList.scss'
import classNames from 'classnames'

const Card = ({ card, membership, onChangeMembership }) => (
  <div className="col-md text-center becomemember-cards-list-card-content mb-4 mb-md-0">
    <div
      onClick={() => onChangeMembership(card.slug)}
      className={classNames('becomemember-cards-list-card pointer', {
      'becomemember-cards-list-card-primary': membership === card.slug
    })}>
      <img
        src={membership === card.slug ? card.icon: card.selectedIcon}
        alt="card icon"
      />
      <div className="becomemember-cards-list-card-head">
        <h3>{card.name}</h3>
      </div>
      <p>{card.description}</p>
      <h3 className='becomemember-card-price'>
        {card.price}{' €'}{!card.extraPrice && '*'}
        {card.extraPrice > 0 && ` + ${card.extraPrice} € cad*`}
      </h3>
      <div className='becomemember-card-ship-price'>
        *Escluse spese di spedizione pari a {card.shipmentPrice} €
      </div>
    </div>
  </div>
)

class BecomeMemberCardsList extends React.PureComponent {
  render() {
    const { membership, cards, onChangeMembership } = this.props
    return (
      <div className="becomemember-cards-list">
        <div className="becomemember-cards-list-bg-primary">
          <div className="container-fluid">
            <div className="row px-5">
              {cards.map(card => (
                <Card
                  onChangeMembership={onChangeMembership}
                  key={card.name}
                  card={card}
                  membership={membership}
                />
              ))}
            </div>
            <div className='text-center mt-5'>
              <p className="text-white">
                *Anche se non giochi a Golf se ti associ a Privileged potrai beneficiare per un anno dei nostri vantaggi<br/>
                esclusivi presso gli hotel, i ristoranti, le SPA e i centri fitness convenzionati.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BecomeMemberCardsList
