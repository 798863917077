import React from 'react'
import './Footer.scss'
import {isDesktopScreen, openCookiePolicy, openPrivacyPolicy, openTermsCondition} from "../../utils";
import {t} from "i18next";

const whiteStyles = {
  backgroundColor: '#fff',
  color: 'rgb(1, 22, 50)',
}

class Footer extends React.Component {
  render(){
    const { white } = this.props
    return (
      <div className="container-fluid footer" style={white ? whiteStyles : null}>
        <div className="row pt-1">
          <div className="col-md-4 pt-5 d-flex flex-column align-items-center footer-firstbox">
            <div>
            <div className="hidden-md text-secondary ml-4 text-left mb-0">
              <p className='mb-0'>CONTACT US</p>
              <p className="footer-claim-secondary mt-0">{t('at your service')}</p>
            </div>
            <div className="">
              <div className="d-flex flex-row ml-4 mt-1">
                <div>
                  <img
                    style={{ width: 40 }}
                    className='mr-2'
                    alt='phone icon' src='/images/contacts-phone.png' />
                </div>
                <div className="d-flex flex-column">
                  <p className="m-0">
                    <a className="text-white no-link-style" href="tel:+39 0171 1712440">+39 0171 1712440</a>
                  </p>
                  <p className="m-0">
                    <a className="text-white no-link-style" href="tel:+39 331 4040113">+39 331 4040113</a>
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row ml-4 mt-0">
                <div className="d-flex flex-column align-items-center align-items-center">
                  <img
                    style={{ width: 40 }}
                    className='mr-2'
                    alt='mail icon' src='/images/contacts-mail.png' />
                </div>
                <div>
                  <p className="m-0">
                    <a className="text-white no-link-style" href="mailto:reservation@privilegedgolf.com">reservation@privilegedgolf.com</a>
                  </p>
                  <p className="m-0">
                    <a className="text-white no-link-style" href="mailto:team@privilegedgolf.com">team@privilegedgolf.com</a>
                  </p>
                </div>

              </div>
              <div className="d-flex flex-row ml-4 mt-0">
                <div className="d-flex flex-column align-items-center align-items-center">
                  <img
                    style={{ width: 40 }}
                    className='mr-2'
                    alt='marker icon' src='/images/contacts-mapmarker.png' />
                </div>
                <p className="m-0">
                  Via Pascal 7, 12100 Cuneo
                </p>

              </div>
            </div>
            </div>
          </div>
          <div className="col-md-4 pt-3 footer-midbox">
            <div className='footer-img-wrapper'>
              <img src="/images/privileged-logo-gold.png" alt="Privileged golf club logo" style={{height:'auto',width:'100%'}} />
            </div>
          </div>
          <div className="col-md-4 pt-5 d-flex flex-column align-items-center footer-lastbox" >
            <div>
            <div className='d-flex flex-row'>
              <div>
                <p className="hidden-md text-secondary">SERVICES</p>
              </div>
              <div className="ml-3">
                <p className="m-0"><a className="no-link-style text-white cursor-pointer" onClick={openTermsCondition}>{t('terms')}</a></p>
                <p className="m-0"><a className="no-link-style text-white cursor-pointer" onClick={openCookiePolicy}>Cookie Policy</a></p>
                <p className="m-0"><a className="no-link-style text-white cursor-pointer" onClick={openPrivacyPolicy}>Privacy Policy</a></p>
              </div>
            </div>
            <div className='d-flex flex-row mt-4'>
              <div>
                <p className="hidden-md text-secondary">{t('follow us')}</p>
              </div>
              <div className="ml-3">
                <a href={'https://www.instagram.com/privileged.golf'} target="_blank" rel="noopener noreferrer">
                  <img src='/images/footer-ig.png' className='footer-img' />
                </a>
                <a href={'https://www.facebook.com/Privileged-The-Club-126956224851612'} target="_blank" rel="noopener noreferrer">
                  <img src='/images/footer-fb.png' className='footer-img' />
                </a>
                <a href={'https://www.youtube.com/channel/UCd3kU1yEGhrbcXkzLTL9t6g'} target="_blank" rel="noopener noreferrer">
                  <img src='/images/footer-yt.png' className='footer-img' />
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="text-center p-1 pt-0 mt-md-1 mt-0">
          <p>
            Privileged The Club Srl - Via Carlo Pascal 7, 12100 Cuneo - P.IVA e C.F.  IT03898810043
          </p>
          <p>
            {t('share capital')}
          </p>
        </div>
      </div>
    )
  }
}

Footer.defaultProps = {
  white: false,
}

export default Footer
