import React from 'react'
import { Link } from 'react-router-dom'
import './RegistrationDone.scss'

const RegistrationDone = ({ message }) => (
  <div className='registration-done'>
    <div className='msg'>{message}</div>
    <div className='coursive'>Benvenuto in</div>
    <img alt='privileged the club logo' src='/images/privileged-logo-gold-big.png' />
    <div>A breve riceverai una mail con il riepilogo di tutti i tuoi dati di registrazione</div>
    <Link
      to='/'
      tag={'button'} className='btn btn-secondary text-white mt-4'>Torna alla Home</Link>
  </div>
)
export default RegistrationDone
