import pinChefIcon from '../components/MarkerMap/pinRistoranti.png'
import pinSpaIcon from '../components/MarkerMap/pinSpa.png'
import pinFitnessIcon from '../components/MarkerMap/pinFitness.png'
import pinFugheIcon from '../components/MarkerMap/pinFughe.png'
import moment from "moment";
import _ from "lodash";
import {t} from '../i18n/index'
export const MAPS_API_KEY = 'AIzaSyC96I2j-YMZhJ2b-f151dY9nIbHGPCCFM0'

export const DEFAULT_MAP_CENTER = { lat: 44.3661389, lng: 10.5258652 }
export const MAIN_MAP_CENTER = { lat: 44.3661389, lng: 7.5236765 }
export const DEFAULT_MAP_ZOOM = 11
export const DEFAULT_DISTANCE = 1000000
export const ACTIVITY_BG_IMAGES = {
  'Gusto': 'gusto.jpg',
  'Fughe': 'fughe.jpg',
  'Fitness': 'fitness.jpg',
  'Wellness': 'wellness.jpg',
  'Default': 'event-bg.png'
}

export const CARDS_ICONS = {
  'ic-one-card': {
    icon: '/images/tessera-bianca.png',
    selectedIcon: '/images/tessera-arancio.png',
  },
  'ic-four-card': {
    icon: '/images/tessera-bianca-4x.png',
    selectedIcon: '/images/tessera-arancio-4x.png'
  }
}

const ISPROD = true


export const API_URL = ISPROD
  ? 'https://privileged-golf.herokuapp.com'
  : 'https://privileged-golf-dev.herokuapp.com'

export const MASTER_TOKEN = ISPROD
  ? 'bb76fe867b95df95ca0269ecfa62151f011b89b0ae344222be3ea0362dfd88b95414c42b6004bdf6f4d73b8d7726cffff9fb1ab1d191e4e509532651326f1813'
  : 'masterkey'

export const ACTIVITY_TYPES = [
  {
    id: 47,
    label: 'Hotel',
    image: '/images/icona-hotel.png',
    markerIcon: pinFugheIcon
  },
  {
    id: 51,
    label: t('restaurants'),
    image: '/images/icona-ristoranti.png',
    markerIcon: pinChefIcon
  },
  {
    id: 55,
    label: 'Fitness',
    image: '/images/icona-fitness.png',
    markerIcon: pinFitnessIcon
  },
  {
    id: 56,
    label: 'Wellness',
    image: '/images/icona-spa.png',
    markerIcon: pinSpaIcon
  },
  // {
  //   id: 23,
  //   label: 'The Royal Diamond',
  //   image: '/images/icona-diamond.png',
  //   markerIcon: pinSpaIcon
  // },
]

// built with: https://mapstyle.withgoogle.com/ (silver style)
export const MAP_STYLE = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
]

export const isUserExpired = user => {
  if (!user) {
    return true
  }
  const expiryDate = moment(_.get(user, 'loyaltyCard.expiryDate', null))
  return expiryDate.diff(new Date(), 'days') < 0
}

export const MONTHS = [
  t('january'),
  t('february'),
  t('march'),
  t('april'),
  t('may'),
  t('june'),
  t('july'),
  t('august'),
  t('september'),
  t('october'),
  t('november'),
  t('december'),
]

export const ACTIVITY_IMAGES = {
  Golf: '/images/icona-golf-ball.png',
  'Privileged Golf Tour': '/images/activities/icona-diamond.png',
  'Privileged Resort': '/images/activities/icona-resort.png',
  Hotel: '/images/activities/icona-hotel.png',
  Ristoranti: '/images/activities/icona-ristoranti.png',
  Restaurants:'/images/activities/icona-ristoranti.png',
  Fitness: '/images/activities/icona-fitness.png',
  Wellness: '/images/activities/icona-spa.png',
}
