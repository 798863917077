import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './en.json';
import translationIt from './it.json';
import i18next from 'i18next';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n.use(LanguageDetector).init({
    fallbackLng: 'en',
    debug: false, transSupportBasicHtmlNodes: true,
        detection:options,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEn,
      },
        it:{
          translation: translationIt,
        }
    },
    // backend: {
    //   loadPath: "/public/locales/{{lng}}/{{ns}}.json"
    // }
  });

export default i18n;

const currentLanguage = localStorage.getItem('language')
i18next.changeLanguage(currentLanguage || 'it')

export const t = (key, params) => i18n.t(key, params);
