import React from 'react'
import { Field } from 'redux-form'
import { required } from '../Form/validation'
import { FieldInput } from '../Form/Fields'
import {t} from "i18next";

const passMinLength = value =>
  value && value.length < 6 ? t('password must be long') : undefined

const AccountFormSection = () => (
  <div>
    {/* <Field
      labelProps={{ className: 'text-primary' }}
      className='border-primary'
      validate={required}
      label='USERNAME'
      component={FieldInput}
      name='username'
    /> */}
    <Field
      labelProps={{ className: 'text-primary' }}
      className='border-primary'
      validate={[required, passMinLength]}
      label='PASSWORD'
      component={FieldInput}
      name='password'
      type='password'
    />
    <Field
      labelProps={{ className: 'text-primary' }}
      className='border-primary'
      validate={[required, passMinLength]}
      label={t('confirm password')}
      component={FieldInput}
      name='passwordConfirm'
      type='password'
    />
  </div>
)

export default AccountFormSection
