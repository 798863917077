import React from 'react'
import './HomeSummary.scss'
import {t} from '../../i18n/index'

export default class HomeSummary extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid homesummary mt-5">
          <div className="row">
            <div className="col-md-12 text-center mt-5 d-flex flex-row justify-content-center">
              <h2 className="text-primary text-capitalize font-weight-bold h1">{t('its')}{" "}</h2>
              <h2 className='text-lowercase font-weight-bold h1'>&ensp;{t('easy')}</h2>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-1" />
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-4 text-center">
                  <div className="homesummary-golfball-container">
                    <img src={'/images/pallina-home-1.png'} alt="pallina golf" />
                  </div>
                  <div className="mt-3">
                    <h5 className="text-primary">{t('associate')}</h5>
                    <p className="mt-3 text-primary" style={{fontSize:13}}>{t('a privileged')}<br/>{t('start to 70')}</p>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="homesummary-golfball-container">
                    <img src={'/images/pallina-home-2.png'} alt="pallina golf" />
                  </div>
                  <div className="mt-3">
                    <h5 className="text-primary">{t('book')}</h5>
                    <p className="mt-3 text-primary" style={{fontSize:13}}> {t('golf field hotel')} <br/> {t('restaurants and fitness')} <br/> {t('from platform')}</p>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="homesummary-golfball-container">
                    <img src={'/images/pallina-home-3.png'} alt="pallina golf" />
                  </div>
                  <div className="mt-3">
                    <h5 className="text-primary">{t('save')}</h5>
                    <p className="mt-3 text-primary" style={{fontSize:13}}>{t('sales')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1" />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
