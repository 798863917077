import React from 'react'
import Menu from '../../components/Menu'
import { Link } from 'react-router-dom'
import RecoverForm from '../../components/RecoverForm'
import {recover} from '../../state/auth'
import Footer from '../../components/Footer'
import withScrollTop from '../../hocs/ScrollTop'


class Recover  extends React.PureComponent {

  state = {
    sent: false,

  }
  render(){
    return (
      <div className='h-100vh bg-primary text-white d-flex flex-column'>
        <Menu/>
        <div className="container py-5">
          <div className="text-center">
            <h3 className="text-white">
              Recupero password
            </h3>
            {!this.state.sent && <div>
              <div className="row">
                  <div className="col-md-4 offset-md-4">
                    <p className="text-secondary">
                      <small>
                        Per recuperare la password inserire l'indirizzo email usato per la registrazione.
                        Verrà inviata una mail di conferma con le istruzioni per il reset della password.
                      </small>
                    </p>
                  </div>
              </div>

              <RecoverForm
                onSubmit={payload => recover(payload)}
                onSubmitSuccess={data => {
                  this.setState({sent: true})
                }}
              />

            </div>}
            {this.state.sent && <div>
              <div className="row">
                  <div className="col-md-4 offset-md-4">
                    <p className="text-secondary">
                      <small>
                        Controlla la tua casella email per concludere la procedura di recupero password.
                      </small>
                    </p>
                  </div>
              </div>
              <div className="text-center p-3">
                <Link className="btn btn-secondary" to="/login">Torna al login</Link>
              </div>
            </div>}


          </div>


        </div>
        <Footer/>

      </div>
    )
  }
}

export default withScrollTop(Recover)
