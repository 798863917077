import React from 'react'
import { Link } from 'react-router-dom'
import './HomeCover.scss'
import { t } from '../../i18n/index'

class HomeCover extends React.PureComponent {
  render(){
    const { message, user, onBookingClick, onBecomeMemberClick, children } = this.props
    return (
      <div className={`bg-dark position-relative homecover ${user ? 'homelogged-bg' : 'homenlogged-bg'}`}>

        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="text-center px-3">
            <img src="/images/privileged-logo-gold-big.png" alt="privileged club logo" className="homecover-logo" />

            <h2 className="text-white mb-4 coursive">{t('the best')}</h2>
            <h5 className="text-white text-uppercase subtitle">{message}</h5>
            {!user &&
              <React.Fragment>
                <div className="homecover-btn-container pt-3">
                  <a href="https://shop.privilegedgolf.com/" target="_blank" className="btn homecover-btn homecover-btn-left">
                    {t('join')}
                  </a>
                  <Link className="btn homecover-btn booking" to="/login">{t('log in')}</Link>
                  {/*<button onClick={onBookingClick} className="btn homecover-btn booking">*/}
                  {/*  Accedi*/}
                  {/*</button>*/}
                </div>
              <p className="text-white mt-3 mb-0">{t('card or signing up')}</p>
              <Link to={`/registra-tessera`} className="text-secondary"><b>{t('click here')}</b></Link>
              </React.Fragment>}
              {user &&
                <React.Fragment>
                  <div className="homecover-btn-container pt-3">
                  <button onClick={onBookingClick} className="btn homecover-btn homecover-btn-left">
                    {t('book')}
                  </button>
                </div>
                </React.Fragment>}
          </div>
          {children}
        </div>
      </div>
    )
  }
}

HomeCover.defaultProps = {
  message: <span> {t('play')}<br/>{t('and live')}</span>,
  user: false,
}

export default HomeCover
