import React from 'react'
import CookieBanner from 'react-cookie-banner'
import './Cookies.scss'
import {t} from "i18next";

const styles = {

}
const MESSAGE = t('this site use cookie')

class Cookies extends React.Component {
  render(){

    return (
      <CookieBanner
        message={MESSAGE}
        link={<a href='/pdf/cookie_policy.pdf' target="_blank">{t('more info')}</a>}
        buttonMessage='Accetta'
        dismissOnScroll={false}
        dismissOnClick={true}
        styles={styles}
        // onAccept={() => setState({ accepted: true })}
      />


    )
  }
}


export default Cookies
