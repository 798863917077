import React from 'react'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import GolfMap from '../../components/GolfMap'
import './Contacts.scss'
import withScrollTop from '../../hocs/ScrollTop'
import { API_URL, MAIN_MAP_CENTER, MASTER_TOKEN } from '../../constants'
import golfClubPinIcon from '../../components/MarkerMap/golf-club-pin.png'
import axios from 'axios'
import {t} from "i18next";

const MARKERS = [
  {
    id: 23,
    description: 'Via Roccavione n.11, 12100 Cuneo',
    icon: golfClubPinIcon,
    position: MAIN_MAP_CENTER,
  }
]

class Contacts extends React.Component {

  state = {
    name:'',
    email:'',
    phone:'',
    message:'',
    messageSent:false
  }

  handleSubmit = (e) => {
    this.setState({messageSent:false})
    e.preventDefault()
    axios.post(`${API_URL}/contacts`, {
      name:this.state.name,
      email:this.state.email,
      phone:this.state.phone,
      message:this.state.message
    }, {
      headers: {
        'Authorization': `bearer ${MASTER_TOKEN}`
      }
    }).then(() => {
      this.setState({
        name:'',
        email:'',
        phone:'',
        message:''
      })
      this.setState({messageSent:true})
    }).catch(() => {
      this.setState({messageSent:false})
    })
  }

  render(){
    return <><div className="contacts">
      <Menu bgColor={'rgb(1, 22, 50)'}/>

      <div className='contact_container h-100'>
        <div className='ml-5 pl-4'>
          <h1 className='text-black mb-5 font-weight-bold font-700' style={{fontSize: 60}}>{t('contacts')}</h1>
        </div>
        <div className='d-flex flex-row justify-content-space-between pt-0 pb-4 pl-5 pr-5 w-100' style={{flexWrap:'wrap'}}>
          <div className='contact-form ml-0 mr-5'>
              <form autoComplete='false' className='d-flex flex-column align-items-center' onSubmit={this.handleSubmit}>
                <div className="form-group contact-input mt-3">
                  <input value={this.state.name} onChange={(val) => this.setState({name:val.target.value})} type="text"
                         className="form-control border-black contact-form-input" id="exampleInputEmail1" placeholder={t('name and surname')} required/>
                </div>
                <div className="form-group contact-input mt-3">
                  <input value={this.state.email} onChange={(val) => this.setState({email:val.target.value})} type="email"
                         className="form-control border-black contact-form-input" id="exampleInputEmail1" placeholder={t('mail')} required/>
                </div>
                <div className="form-group contact-input mt-3">
                  <input value={this.state.phone} onChange={(val) => this.setState({phone:val.target.value})} type="text" className="form-control border-black contact-form-input" id="exampleInputEmail1" placeholder={t('phone')} />
                </div>
                <div className="form-group contact-input mt-3">
                  <input value={this.state.message} onChange={(val) => this.setState({message:val.target.value})} type="text" className="form-control border-black contact-form-input" id="exampleInputEmail1" placeholder={t('message')} required/>
                </div>
                {this.state.messageSent && (
                    <p style={{color:'green',textAlign:'center'}}>La richiesta di contatto è stata inviata con successo!</p>
                  )}
                <div style={{width:'30%',minWidth:150,justifySelf:'center'}}>

                  <button type='submit' className='btn color-orange text-white font-weight-bold w-100'>{t('text us')}</button>
                </div>
              </form>
          </div>
          <div className="bg-info contacts-map-container">
            <GolfMap
              center={MAIN_MAP_CENTER}
              markers={MARKERS}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>

    </>
  }
}

export default withScrollTop(Contacts)
