import React, { PureComponent, Fragment } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'
import { labelFromEventType } from '../../utils'
import './HomeItemCard.scss'

export default class HomeItemCard extends PureComponent {
  render() {
    const { item, type, date } = this.props

    const image = type === 'activity'
      ? get(item, 'CoverImage')
      : get(item, 'logo.thumbnail.medium')

    const title = type === 'activity'
      ? get(item, 'Partner')
      : get(item, 'name')

    const address = type === 'activity'
      ? (
        <Fragment>
          {get(item, 'Location.Address')}{' '}<br />
          {get(item, 'Location.Department')}{' '}
          {get(item, 'Location.City')}{' '}
          {get(item, 'Location.Province')}
        </Fragment>
      ) : (
        <Fragment>
          {get(item, 'address.address')}{' '}<br />
          {get(item, 'address.zipCode')}{' '}
          {get(item, 'address.city')}{' '}
          {get(item, 'address.municipality')}
        </Fragment>
      )

    let to = type === 'activity'
      ? `/attivita/${item.Id}`
      : `/club/${item._id}`

    if (date) {
      to = to + '?date=' + moment(date).format('DD-MM-YYYY')
    }

    return (
      <div className={classNames('col-lg-2 col-md-3 col-sm-4 col-6 home-item-card', {
        'home-item-card-event': type === 'event',
      })}>
        <Link to={to} className='no-link-style'>
          <div className='home-item-card-inner'>
            {image && <img
              alt='logo'
              src={image}
            />}
            <div className='text-center mb-2'>
              <div className="title-card" style={{color:'#021632'}}>
                {title}
              </div>
            </div>
            <div className='address-card' style={{color:'#021632'}}>
              {address}
            </div>
            {type === 'event' && (
              <div className='mt-3'>
                {item.events.map(event => (
                  <div key={event._id} className='text-secondary event-in-card text-center'>
                    {`${moment(event.date).format('DD/MM/YYYY')} ${labelFromEventType(event.type)}`}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Link>
      </div>
    )
  }
}
