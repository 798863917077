import React from 'react'
import moment from 'moment'
import './UserAccountCard.scss'
import _ from 'lodash'
import {t} from "i18next";

class UserAccountCard extends React.PureComponent {
  render() {
    const { card, membership } = this.props
    console.log(card)
    return (
      <div className='user-card'>

        <div className="d-flex flex-row align-items-center">
          <div className="c">
            <div className='user-card-circle'>
              {(_.get(card, 'membershipPlan.selectedIcon', '') || _.get(membership, 'icon.full', '')) && <div className='user-card-circle-content'>
                <img
                  src={_.get(membership, 'icon.full', '') || _.get(card, 'membershipPlan.selectedIcon', '')}
                  className='user-card-image'
                  alt='card icon'
                />
                <div className='user-card-title'>{_.get(membership, 'shortDescription', '') || card.membershipPlan.name}</div>
              </div>}
            </div>
          </div>
          <div className="ml-5 usercard-infosbox" >
            <h2 className='text-secondary usercard-heading'>{t('your card')}</h2>
            <h4 className="text-white font-weight-normal mt-3 usercard-code" usercard-code>{card.code}</h4>
            <div className='d-flex align-items-center flex-row'>
              <h4 className='text-white font-weight-normal text-capitalize usercard-sub'>{t('valid until')}</h4>
              <h4 className='ml-2 usercard-sub'>{moment(card.concreteExpiryDate).format('DD.MM.YYYY')}</h4>
            </div>
          </div>

          {/*<div className="col-md-4 d-flex align-items-center justify-content-center">
            <div className='user-card-valid-until'>
              <h4>validità fino al</h4>
              <div className='user-card-valid-date'>
                {card.expiryDate && moment(card.expiryDate).format('DD.MM.YYYY')}
              </div>
            </div>

    </div>*/}
        </div>






      </div>
    )
  }
}

export default UserAccountCard
