import React, { PureComponent } from 'react'

export default class UserAvatar extends PureComponent {
  state = { image: null, file: null }

  componentDidUpdate(prevProps) {
    if (this.props.image !== prevProps.image) {
      this.clearImage()
    }
  }

  componentWillUnmount() {
    this.tearDownFileReader()
  }

  loadPreview = file => {
    this.setState({
      file,
    }, () => {
      this.reader = new FileReader()
      this.reader.addEventListener('load', this.onImageLoaded)
      this.reader.readAsDataURL(file)
    })
  }

  onImageLoaded = e => {
    this.setState({ image: e.target.result })
  }

  tearDownFileReader() {
    if (this.reader) {
      this.reader.removeEventListener('load', this.onImageLoaded)
    }
  }

  clearImage = () => {
    this.tearDownFileReader()
    this.setState({ image: null, file: null })
  }

  uploadImage = () => {
    this.props.onUpload(this.state.file)
  }

  render() {
    const { image, uploading } = this.props
    const newImage = this.state.image
    const previewImage = newImage ? newImage : image

    return (
      <div className='useraccount-avatar'>
        <div className="useraccount-menu-img-container">
          <img src={previewImage} alt="user provile avatar" />
          {newImage && !uploading && <div className='avatar-change'>
            <button onClick={this.clearImage}  className='avatar-undo-change'>
              <i className="fa fa-times" />
            </button>
            <button onClick={this.uploadImage} className='avatar-confirm-change'>
              <i className="fa fa-check" />
            </button>
          </div>}
          {uploading && <div className='uploading-avatar'>
            <i className="fa fa-2x fa-circle-o-notch fa-spin"></i>
          </div>}
        </div>
        {!newImage && <label className="useraccount-menu-plus">
          <i className="fa fa-plus" />
          <input
            className='d-none'
            type='file'
            onChange={e => {
              const file = e.target.files[0]
              if (file instanceof File) {
                this.loadPreview(file)
              }
            }}
          />
        </label>}
      </div>
    )
  }
}
