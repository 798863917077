import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'
import { middleware as thunkMiddleware } from 'redux-saga-thunk'
import { makeAppsReducers, makeAppsSaga } from 'redux-rocketjump'
import * as auth from './auth'
import * as clubs from './clubs'
import * as activities from './activities'
import * as avatar from './avatar'
import * as plans from './plans'
import * as payment from './payment'
import * as ig from './ig'
import * as events from './events'
import * as sponsors from './sponsors'
import * as conventions from './conventions'
import * as resorts from './resorts'

const APPS = {
  auth,
  clubs,
  events,
  activities,
  avatar,
  plans,
  payment,
  ig,
  sponsors,
  conventions,
  resorts
}

const rootReducer = combineReducers({
  form: formReducer,
  ...makeAppsReducers(APPS),
})

const mainSaga = makeAppsSaga(APPS)
const preloadedState = {

}

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  preloadedState,
  composeEnhancers(
    applyMiddleware(thunkMiddleware, sagaMiddleware),
  )
)
sagaMiddleware.run(mainSaga)

export default store
