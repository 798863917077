import React from 'react'
import './GolfClubDetailLowerInfo.scss'
import get from 'lodash/get'

class GolfClubDetailLowerInfo extends React.PureComponent {

  render(){
    const { club } = this.props
    const websiteUrl = get(club, 'websiteUrl')
    const address = get(club, 'address', {})
    const closingDayOfWeek = get(club, 'closingDayOfWeek')
    const closingPeriod = get(club, 'closingPeriod')

    return (
      <div className="golfclubdetail-lower-info container-fluid d-flex flex-column align-items-center justify-content-center">
        {websiteUrl && <h4 className="font-600">{websiteUrl}</h4>}
        <div className="text-center">
          <h5 className='golfclub-address'>{address.address} {address.city} ({address.municipality})</h5>
        </div>
        {(!!closingDayOfWeek || !!closingPeriod) && (
          <div className="mt-2 text-center">
            {closingDayOfWeek && <div className="">Giorno di chiusura: {closingDayOfWeek}</div>}
            {closingPeriod && <div className="">Periodo di chiusura: {closingPeriod}</div>}
          </div>
        )}
      </div>
    )
  }
}

export default GolfClubDetailLowerInfo
