import React, { Fragment } from 'react'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.scss'
import { Input, FormGroup, Label, FormFeedback, Col } from 'reactstrap'
import { isDesktopScreen } from '../../utils'

const arrayze = a => Array.isArray(a) ? a : [a]

export const FieldGridInput = ({
  input,
  meta,
  label,
  type,
  labelProps,
  validCheck,
  colLabel,
  colInput,
  ...passProps
}) => {
  let inputElement = <Input
    type={type}
    {...input}
    {...passProps}
    invalid={!!(meta.touched && meta.error)}
  />

  if (validCheck) {
    inputElement = (
      <div className='d-flex'>
        {inputElement}
        <div
          style={{ visibility: meta.error ? 'hidden' : undefined }}
          className='text-secondary px-2 text-center'>
          √
        </div>
      </div>
    )
  }

  return (
    <FormGroup row>
      {label && <Label sm={colLabel} {...labelProps}>{label}</Label>}
      <Col sm={colInput}>
        {inputElement}
        {meta.touched && meta.error && arrayze(meta.error).map((error, i) => (
          <FormFeedback style={{display: 'inline-block'}}key={i}>{error}</FormFeedback>
        ))}
      </Col>
    </FormGroup>
  )
}

FieldGridInput.defaultProps = {
  colLabel: 4,
  colInput: 8,
  type: 'text',
}

export const FieldInput = ({ input, meta, label, type, labelProps, className, emptyClassName, ...passProps }) => (
  <FormGroup>
    {label && <Label {...labelProps}>{label}</Label>}
    <Input type={type} {...input} className={input.value ? className : `${className} ${emptyClassName}`} {...passProps} invalid={!!(meta.touched && meta.error)} />
    {meta.touched && meta.error && arrayze(meta.error).map((error, i) => (
      <FormFeedback key={i}>{error}</FormFeedback>
    ))}
  </FormGroup>
)

FieldInput.defaultProps = {
  type: 'text',
}

export const FieldCheckbox = (props) => (
  <FormGroup check>
    <Label check>
      <Input type="checkbox" checked={props.input.value} {...props.input} valid={false}/>{' '}
      {props.label}
    </Label>
    {props.meta.touched && props.meta.error && arrayze(props.meta.error).map((error, i) => (
      <FormFeedback key={i}>{error}</FormFeedback>
    ))}
  </FormGroup>
)

const FieldDatepickerDesktop = ({
  input: { value, onChange, onBlur, onFocus },
  className,
  label,
  meta,
  row,
  fullWidth = false,
  labelProps,
  colLabel,
  colInput,
  placeholder,
  ...passProps
}) => {
  const selectedDate = value === '' ? null : moment(value, 'DD/MM/YYYY', true).toDate()
  const datepickerElement = (
    <DatePicker
      placeholderText={placeholder}
      dateFormat='dd/MM/yyyy'
      className={classNames('form-control', className, {
        'is-invalid': meta.touched && meta.error
      })}
      selected={selectedDate}
      onChange={d => {
        if (d) {
          onChange(moment(d).format('DD/MM/YYYY'))
        } else {
          onChange(null)
        }
      }}
      onBlur={() => onBlur()}
      onFocus={() => onFocus()}
      {...passProps}
    />
  )

  const errorElement = (
    <Fragment>
      {meta.touched && meta.error && arrayze(meta.error).map((error, i) => (
        <div key={i} className='invalid-feedback d-block'>{error}</div>
      ))}
    </Fragment>
  )

  return (
    <FormGroup className={classNames({ 'datepicker-full-width': fullWidth })} row={row}>
      {label && <Label sm={colLabel} {...labelProps}>{label}</Label>}
      {row && (
        <Col sm={colInput}>
          {datepickerElement}
          {errorElement}
        </Col>
      )}
      {!row && (
        <Fragment>
          {datepickerElement}
          {errorElement}
        </Fragment>
      )}
    </FormGroup>
  )
}

FieldDatepickerDesktop.defaultProps = {
  row: false,
}

const desktop = isDesktopScreen()
export const FieldDatepicker = (props) => {
  if (desktop) {
    return <FieldDatepickerDesktop {...props } />
  } else {
    const { row, fullWidth, input, ...passProps } = props

    const m = moment(input.value, 'DD/MM/YYYY', true)
    const value = m.isValid() ? m.format('YYYY-MM-DD') : ''

    const onChange = e => {
      const m = moment(e.target.value)
      const newValue = m.isValid() ? m.format('DD/MM/YYYY') : ''
      input.onChange(newValue)
    }
    const passInput = {
      onBlur: () => input.onBlur(),
      onFocus: () => input.onFocus(),
      value,
      onChange,
    }

    if (row) {
      return <FieldGridInput
        input={passInput}
        type='date'
        {...passProps}
      />
    } else {
      return <FieldInput
        type='date'
        input={passInput}
        {...passProps}
      />
    }
  }
}
