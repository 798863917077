import React, { Component } from 'react'
import memoize from 'memoize-one'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import flatMap from 'lodash/flatMap'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import { getAuthUser } from 'eazy-auth'
import {labelFromEventType, printTextBreakLine} from '../../utils'
import {
  bookEvent,
  isBookingEvent,
  unloadBookEvent,
  getEventBooked,
} from '../../state/events'
import { reduxForm, Field } from 'redux-form'
import { FieldGridInput, FieldInput, FieldDatepicker } from '../Form/Fields'
import './EventsList.scss'
import _ from 'lodash'
import {t} from "i18next";


class ReservationForm extends React.Component {
  render(){
    const { handleSubmit, invalid, submitting, error, event, club, toggle, user } = this.props
    return (
      <form
        // onSubmit={(e) => {
        //   e.preventDefault()
        //   onBook(event.id)
        // }}
        onSubmit={handleSubmit}
      >
        <div>
          <h5 className='mb-2'>Privileged Golf Tour</h5>
          <h4>{_.get(event, 'virtualType.value', '').split('<br>').map(text => text + ' ')}</h4>
        </div>
        <div className='text-uppercase text-white'>
          <div className="font-weight-bold" style={{color:'#DAA056'}}>{club.name}</div>
        </div>

        <div className="event-data my-3">
          <div className='row m-4'>
            <div className="col-12 d-flex mb-3">
              <div className="mr-3 text-uppercase"><b>{t('date')}</b></div>
              <div className="text-left">{moment(event.date).format('DD MMMM YYYY')}</div>
            </div>
            <div className="col-12 d-flex ">
              <div className="mr-3 text-uppercase"><b>{t('hours')}</b></div>
              <div className="text-left">{event.time}</div>
            </div>
          </div>

          <div className='row m-4'>
            <div className="col-12 d-flex mb-3">
              <div className="mr-3 text-uppercase"><b>Location</b></div>
              <div className="text-left">{get(club, 'address.address')} {get(club, 'address.city')} {get(club, 'address.municipality')}</div>
            </div>
            <div className="col-12 d-flex">
              <div className="mr-3 text-uppercase"><b>Formula</b></div>
              <div className="text-left">{event.formula}</div>
            </div>
          </div>

          <div className='row m-4'>
            <div className="col-12 d-flex mb-3">
              <div className="text-left mr-3 text-uppercase" style={{ flex: '0 0 50px' }}><b>{t('awards')}</b></div>
              <div className="text-left">{event.prize || '-'}</div>
            </div>
            <div className="col-12 d-flex">
              <div className="text-left mr-3 text-uppercase" style={{ flex: '0 0 50px' }}><b>{t('fee')}</b></div>
              <div className="text-left">{printTextBreakLine(event.cost || '-')}</div>
            </div>
          </div>

          <div className='row my-4'>
            {user && <React.Fragment><div className="col-sm-8 offset-sm-2 text-center">
            <b>{t('reporting preferences')}</b>
            <Field
              labelProps={{
                className: 'text-white',
              }}
              type='textarea'
              className='bg-primary border-white text-white placeholder-white'
              name='preferences'
              label={t('reporting preferences description')}
              component={FieldInput}
            />
            </div></React.Fragment>}
          </div>
        </div>

        <div>
          {user && <button
            type='submit'
            disabled={submitting}
            className='btn btn-secondary btn-sm mb-3'>
            {t('confirm registration')}
            {/* <i className="fa fa-circle-o-notch fa-spin ml-2"></i> */}
          </button>}
          {!user && <Link
            to={{pathname:'/login', state: {referrer : {'pathname': window.location.pathname}}}}
            style={{textTransform:"uppercase"}}
            disabled={submitting}
            className='btn btn-secondary btn-sm mb-3'>
            {t('confirm registration')}
            {/* <i className="fa fa-circle-o-notch fa-spin ml-2"></i> */}
          </Link>}
        </div>
        <div>
          {/*<button*/}
          {/*  type='button'*/}
          {/*  onClick={toggle}*/}
          {/*  disabled={submitting}*/}
          {/*  className='btn btn-secondary btn-sm'>*/}
          {/*  ANNULLA*/}
          {/*</button>*/}

        </div>
      </form>
    )

  }

}




const ChallengeReservationForm = reduxForm({
  form: 'challengeReservation',
})(ReservationForm)




let EventModal = ({ event,  loading, toggle, club, onBook, user }) => (
  <Modal
    className='event-modal'
    toggle={toggle}
    isOpen={event !== null}>

    {event && <ModalBody>
      <ChallengeReservationForm
        onSubmit={data => {
          return onBook(event.id, data)
        }}
        onSubmitSuccess={()=> {}}
        user={user}
        club={club} toggle={toggle} event={event} />
    </ModalBody>}
  </Modal>
)

const EventRow = ({ event, user, onClick, showClub }) => (
  <tr className="text-primary">
    <td>{moment(event.date).format('DD/MM/YYYY')}</td>
    <td>{_.get(event, 'virtualType.value', '').split('<br>').map(text => text + ' ')}</td>
    <td>{_.get(event, 'club.address.region', '')}</td>
    <td>{_.get(event, 'club.address.municipality', '')}</td>
    {showClub && (
      event.club
        ? <td>
            <Link to={`/club/${event.club.id}?royalDiamond=1`}>{event.club.name}</Link>
          </td>
        : <td />
    )}
    <td className="text-right d-flex flex-row">
      <button
        disabled={!event.isOpen}
        onClick={e => onClick(event, e)}
        style={{textTransform:"uppercase", minWidth:100}}
        className='btn btn-secondary'>{t('register')}</button>

      <a
        href={event.pdf ? event.pdf.full : '#'}
        target="_blank" rel="noopener noreferrer"
        className={`btn btn-secondary ml-1 ${!event.pdf ? 'disabled' : ''}`}>INFO</a>

    </td>

  </tr>
)

const EventHeadRow = ({ month }) => (
  <tr className='text-uppercase'>
    <td><b>{month}</b></td>
  </tr>
)

class EventsList extends Component {
  state = {
    eventOpen: null,
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.eventBooked &&
      this.props.eventBooked !== prevProps.eventBooked
    ) {
      // Clean up the booked event
      this.props.unloadBookEvent()
      // Close open modal event
      this.setState({
        eventOpen: null,
      })
      // Notify parent
      if (typeof this.props.onEventBooked === 'function') {
        this.props.onEventBooked()
      }
    }
  }

  componentWillUnmount() {
    this.props.unloadBookEvent()
  }

  handleClick = event => this.setState({
    eventOpen: event,
  })

  closeModal = () => this.setState({ eventOpen: null })

  getEventsList = memoize((events, groupByMonth) => {
    if (!groupByMonth) {
      return events
    }
    const eventsByMonthYear = groupBy(events, event => {
      return moment(event.date).format('YYYY-MM')
    })
    const eventsList = flatMap(Object.keys(eventsByMonthYear).sort(), key => {
      const m = moment(`${key}-01`).locale(t('lan'))                              //////////////////////////////////////////////////////////////////////////////////////////7
      const monthYearRow = {
        month: capitalize(m.format('MMMM')),
        _id: key,
        isHeadRow: true,
      }
      return [monthYearRow].concat(sortBy(eventsByMonthYear[key], 'date'))
    })
    return eventsList
  })

  render() {
    const {
      events,
      className,
      club,
      user,
      bookEvent,
      isBookingEvent,
      // Display option
      showClub,
      groupByMonth,
      layout
    } = this.props
    const { eventOpen } = this.state

    const eventsList = this.getEventsList(events, groupByMonth)
    // The club of current opening in modal event
    let eventOpenClub = null
    if (eventOpen) {
      if (eventOpen.club) {
        eventOpenClub = eventOpen.club
      } else if (club) {
        eventOpenClub = club
      }
    }

    return layout === 'grid' ? (
      <div className={`${className} events-list`}>
          {eventsList.map(event => (
            !event.isHeadRow
              ? (
                <div className="event-box">
                  <div className="event-info">
                    <p className="mb-1">{moment(event.date).format('DD/MM/YYYY')}</p>
                    <p className="mb-1">{_.get(event, 'virtualType.value', '').split('<br>').map(text => text + ' ')}</p>
                    <p className="mb-1">{_.get(event, 'club.address.region', '')} {_.get(event, 'club.address.municipality', '')}</p>
                  </div>
                  <div className="event-actions">
                    {showClub && (
                      event.club
                        ? <div>
                          <Link to={`/club/${event.club.id}?royalDiamond=1`}>{event.club.name}</Link>
                        </div>
                        : <div />
                    )}
                    <div className="text-right d-flex flex-column">
                      <button
                        disabled={!event.isOpen}
                        onClick={e => this.handleClick(event, e)}
                        style={{textTransform:"uppercase"}}
                        className='btn btn-secondary mb-2'>{t('register')}</button>
                      <a
                        href={event.pdf ? event.pdf.full : '#'}
                        target="_blank" rel="noopener noreferrer"
                        className={`btn btn-secondary ${!event.pdf ? 'disabled' : ''}`}>INFO</a>

                    </div>
                  </div>
                </div>
              ) : <EventHeadRow
                month={event.month}
                key={event._id}
              />
          ))}

        <EventModal
          loading={isBookingEvent}
          onBook={bookEvent}
          toggle={this.closeModal}
          club={eventOpenClub}
          event={eventOpen}
          user={user}
        />
      </div>
    ) : (
      <div className={`${className} table-responsive events-list`}>
        <table className='table table-borderless'>
          <tbody>
          {eventsList.map(event => (
            !event.isHeadRow
              ? <EventRow
                showClub={showClub}
                event={event}
                onClick={this.handleClick}
                key={event._id}
                user={user}
              />
              : <EventHeadRow
                month={event.month}
                key={event._id}
              />
          ))}
          </tbody>
        </table>

        <EventModal
          loading={isBookingEvent}
          onBook={bookEvent}
          toggle={this.closeModal}
          club={eventOpenClub}
          event={eventOpen}
          user={user}
        />
      </div>
    )
  }
}

export default connect(state => ({
  user: getAuthUser(state),
  eventBooked: getEventBooked(state),
  isBookingEvent: isBookingEvent(state),
}), {
  bookEvent,
  unloadBookEvent,
})(EventsList)
