import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import ChangePasswordForm from './ChangePasswordForm'
import UserInfoForm from './UserInfoForm'
import {
  updatePassword,
  updateUserData,
} from '../../state/auth'
import './UserAccountSettings.scss'
import {getCountries, loadCountries} from "../../state/events";
import _ from 'lodash';
import {t} from "i18next";

class UserAccountSettings extends React.PureComponent {
  state = {
    show: 'info',
    selectedCountry: ''
  }

  componentDidMount() {
    this.props.loadCountries()
    this.setState({selectedCountry: _.get(this.props.user, 'address.country', '') || ''})
  }

  handleChangeCountry = (event) => {
    this.setState({selectedCountry: event.target.value})
  }

  render() {
    const { user, updatePassword, updateUserData, clubs, countries } = this.props
    const { show, selectedCountry } = this.state

    return (
      <div className='mt-5'>
        <div className='text-center text-primary mb-4'>
          <h4 className='h2 font-weight-bold'>{t('my data')}</h4>
        </div>
        <div className="container-fluid useraccount-settings">
          <div className="row">
            <div className="col-md-3 useraccount-settings-panel" />
            <div className={show === 'password' ? 'col-md-4 offset-md-1 px-md-5' : 'col-md-6'}>
              {show === 'password' && <>
              <div className="d-flex flex-column align-items-center">
                  <p
                    onClick={() => this.setState({ show: 'info' })}
                    className="m-0 pointer text-secondary font-weight-bold" style={{textDecoration:'underline'}}>{t('edit data')}</p>
                  <p>{t('click to edit')}</p>
                </div>
                <ChangePasswordForm
                  onSubmit={({ password, oldPassword }) =>
                    updatePassword({ password, oldPassword }).catch(err => {
                      if (err.status === 401) {
                        throw new SubmissionError({
                          oldPassword: t('incorrect old password')
                        })
                      }
                      throw new SubmissionError({ _error: t('cannot change password')})
                    })}
                /> 
              </>}
              {show === 'info' && <>
                <div className="d-flex flex-column align-items-center">
                  <p
                    onClick={() => this.setState({ show: 'password' })}
                    className="m-0 pointer text-secondary font-weight-bold" style={{textDecoration:'underline'}}>{t('edit password')}</p>
                  <p>{t('click to edit')}</p>
                </div>
                <UserInfoForm
                  clubs={clubs}
                  initialValues={{
                    ...user,
                    birthDate: moment(user.birthDate).isValid()
                      ? moment(user.birthDate).format('DD/MM/YYYY')
                      : user.birthDate
                  }}
                  onSubmit={user => updateUserData({
                    ...user,
                    address: {
                      ...user.address,
                      country: selectedCountry
                    },
                    birthDate: moment(user.birthDate, 'DD/MM/YYYY', true)
                      ? moment(user.birthDate, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
                      : user.birthDate
                  })}
                  selectedCountry={selectedCountry}
                  handleChangeCountry={this.handleChangeCountry}
                  countries={countries}
                />
              </>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  countries: getCountries(state),
}), {
  updatePassword,
  updateUserData,
  loadCountries,
})(UserAccountSettings)
