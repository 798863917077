import request from 'superagent'
import { authApiCall, withToken } from './auth'
import { API_URL, MASTER_TOKEN } from '../constants'
import _ from 'lodash'

export const getUploadedFile = async (fileName) => {
  const res = await withToken(MASTER_TOKEN, request.get(`${API_URL}/files?fileName=${fileName}`));
  console.log({res})
  return _.get(res, 'body', null)
}
