import React, { Component } from 'react'
import classNames from 'classnames'
import './ActivityTypesEvents.scss'
import _ from 'lodash'

export default class ActivityTypesEvents extends Component {
  render() {
    const { activityTypes, selected, onClick, tall, className, containerClassName, uniformFontSize, smallerContent, homeActivities, fiveActivities, noHover, eventType } = this.props
      console.log({activityTypes})
    let divClass = 'col-md-3'

    console.log({activityTypes})

    const renderItem = (type, index) => {
      return (
        <div
          onClick={
            typeof onClick === 'function'
              ? e => onClick(type, e)
              : undefined
          }
          key={type.id}
          style={{padding: 0}}
          className={classNames(`text-center`, {
            'pointer': typeof onClick === 'function'
          }, 'container')}
        >
          <div className={noHover ? "type-icon-container-events" : classNames("type-icon-container", {
            'selected': !!selected[type.id],
          })}>
            <img
              className={classNames({
                'invert': !type.selectedImg,
              })}
              src={(selected[type.id] && type.selectedImg) ? type.selectedImg : _.get(type, 'image.full', type.image)}
              alt="service's logo"
            />
          </div>
          <h2 className="text-secondary-new font-600" style={{paddingTop: 10, paddingBottom: 10}} >{type.count}</h2>
          <div className={classNames(
            'type-label',
          )}>
            <h5 className={classNames("text-primary font-600", {"text-activity": !uniformFontSize && type.label.length > 8})}>
              {_.split(type.label, '<br>', 5).map(label => {
                return (<p key={label} style={{marginBottom: 5}}>{label}</p>)
              })}
            </h5>

          </div>
        </div>
      )

    }

    return (
      <div className={`container ${containerClassName}`}>
        <div className={`activity-types-events wrapper`}>
          <div className={`element-container`}>
              {activityTypes.slice(0,6).map((type, i) => renderItem(type, i))}
          </div>
          <div className={`element-container`}>
              {activityTypes.slice(6,13).map((type, i) => renderItem(type, i))}
          </div>
        </div>
      </div>
    )
  }
}

ActivityTypesEvents.defaultProps = {
  className: '',
  containerClassName: '',
  selected: {},
  tall: true,
}

