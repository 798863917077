import React, { useState } from 'react'
import { getAuthUser } from 'eazy-auth'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { isDesktopScreen } from '../../utils'
import './Menu.scss'
import moment from "moment";
import _ from "lodash";
import LogoutModal from "../LogoutModal/LogoutModal";
import { logout } from 'eazy-auth'
import i18n, {t} from '../../i18n'
import i18next, {changeLanguage} from 'i18next';

const SwitchLang = () => {
  const [currentLang, setCurrentLang] = useState(i18n.language)
  const onChangeLang = async () => {
    const toggleLang = i18n.language === 'it' ? 'en' : 'it'
    localStorage.setItem('language', toggleLang)
    // await i18n.changeLanguage(toggleLang)
    setCurrentLang(toggleLang)
    window.location.reload()
  }
  return (
    <img
      src={`/images/${currentLang === 'it' ? 'italy-flag' : 'united-kingdom-flag'}.png`}
      alt="flag"
      style={{ width: 20 }}
      className="cursor-pointer"
      onClick={onChangeLang}
    />
  )
}

class Menu extends React.Component {
  state = {
    open: false,
    showLogoutModal: false
  }

  toggleMenu = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  closeMenu = () => {
    this.setState({open: false})
  }

  toggleShowLogoutModal = () => this.setState(prevState => ({
    showLogoutModal: !prevState.showLogoutModal,
  }))

  changeLanguage = () => {
    const currentLanguage = localStorage.getItem('language')
    console.log({currentLanguage})
    const newLanguage = currentLanguage === 'en' ? 'it' : 'en'
    console.log({newLanguage})
    localStorage.setItem('language', newLanguage)
  }


  render(){
    const { open, showLogoutModal } = this.state
    const { textColor, iconColor, user } = this.props
    const bgColor = '#1B3862'
    const hamburgerStyles = {
      line: {
        fill: 'none',
        stroke: iconColor,
        strokeWidth: 3,
        strokeLinecap:'round',
        strokeOpacity:1,
        strokeDasharray:'none',
      }
    }
    console.log({user})
    const expiryDate = _.get(user, 'loyaltyCard.expiryDate', null)
    const userExpired = expiryDate && moment(expiryDate).diff(new Date(), 'days') < 0
    // const userExpired = false
    console.log({expiryDate})
    console.log({userExpired})


    return <div className="menu" style={{color:textColor, backgroundColor:bgColor}}>
      <svg
        width="22px"
        height="22px"
        className="menu-hamburger"
        onClick={this.toggleMenu}
        style={{backgroundColor:'trasparent'}}
        >
        <line x1="4" y1="2" x2="16" y2="2" style={hamburgerStyles.line} />
        <line x1="4" y1="10" x2="16" y2="10" style={hamburgerStyles.line} />
        <line x1="4" y1="18" x2="12" y2="18" style={hamburgerStyles.line} />
      </svg>

      {(open || isDesktopScreen()) &&
      <div className="menu-open-link" style={{backgroundColor: bgColor}}>
        <div className="logo-container h-100 d-flex align-items-center justify-content-center">
            {!userExpired ?
          <Link className="no-link-style" to='/' style={{color:textColor}}>
            <img src="/images/palla.png" alt="logo golf menu" className="menu-logo"/>
          </Link>
              :
            <img src="/images/palla.png" alt="logo golf menu" className="menu-logo"/>
            }
        </div>

        <div className="links">
          {!userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/' style={{color:textColor}}>Home</Link>}
          {/*{userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/rinnova-tessera' style={{color:textColor}}>Rinnova Tessera</Link>}*/}
          {userExpired && <a href="https://shop.privilegedgolf.com/" target="_blank" className="no-link-style" style={{color:textColor}}>Rinnova Tessera</a>}
          {/*{!user && <Link onClick={this.closeMenu} className="no-link-style" to='/diventa-socio' style={{color:textColor}}>Shop</Link>}*/}
          <a href="https://shop.privilegedgolf.com/" target="_blank" className="no-link-style" style={{color:textColor}}>Shop</a>
          {!userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/?category=0' style={{color:textColor}}>Golf</Link>}
          {!userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/?category=47' style={{color:textColor}}>{t('activities')}</Link>}
          {!userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/the-royal-diamond' style={{color:textColor}}>Privileged Golf Tour</Link>}
          {!userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/resort/5e5a1476c3d76d0016860243' style={{color:textColor}}>Privileged Resort</Link>}
          {!userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/convenzioni' style={{color:textColor}}>{t('conventions')}</Link>}
          {!userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/sponsor' style={{color:textColor}}>Sponsor</Link>}
          {<Link onClick={this.closeMenu} className="no-link-style" to='/contatti' style={{color:textColor}}>{t('contacts')}</Link>}
          {!userExpired && <Link onClick={this.closeMenu} className="no-link-style" to='/albums' style={{color:textColor}}>{t('gallery')}</Link>}
        </div>
      </div>}

      <div className="menu-right" style={{color:iconColor}}>
        {!user && <React.Fragment>
            <Link className="no-link-style mr-3 d-none d-md-inline" to='/login' style={{color:textColor}}>{t('log in')}</Link>
            {/*<Link className="no-link-style mr-3 d-none d-md-inline" to='/diventa-socio' style={{color:textColor}}>Diventa socio</Link>*/}
            <a href="https://shop.privilegedgolf.com/" target="_blank" className="no-link-style mr-3 d-none d-md-inline" style={{color:textColor}}>{t('join the club')}</a>
            <Link style={{color:iconColor}} to={`/login`}><i className="fa fa-user mr-4"></i></Link>
          </React.Fragment>
        }
        {/*{userExpired &&*/}
        {/*  <Link style={{color:iconColor}} className="no-link-style" onClick={logout} to={''}>*/}
        {/*    <span className="menu-user-name mr-4">Log Out</span>*/}
        {/*  </Link>}*/}
        {user &&
          <Link style={{color:iconColor}} className="no-link-style" to={`/user`}>
            <span className="menu-user-name mr-4">{user.name}</span>
            {user.profilePicture
              ? <img src={user.profilePicture.thumbnail.medium} alt="user avatar" className="menu-user-image mr-4" />
              : <i className="fa fa-user mr-4" />}
          </Link>}
          <a href={'https://www.instagram.com/privileged.golf'} target="_blank" rel="noopener noreferrer" className='no-link-style'>
            <i className="fa fa-instagram mr-4 pointer"></i>
          </a>
          <a href={'https://www.facebook.com/Privileged-The-Club-126956224851612'} target="_blank" rel="noopener noreferrer" className='no-link-style'>
            <i className="fa fa-facebook mr-4 pointer"></i>
          </a>
          <a href={'https://www.youtube.com/channel/UCd3kU1yEGhrbcXkzLTL9t6g'} target="_blank" rel="noopener noreferrer" className='no-link-style' >
            <img src='/images/footer-yt.png' style={{width:18,height:18,marginRight:24,marginTop:-4}}/>
          </a>
        <SwitchLang />
      </div>
    </div>
  }
}

Menu.defaultProps = {
  textColor: 'rgb(217, 159, 91)',
  iconColor: 'rgb(217, 159, 91)',
  bgColor: 'trasparent',
}


export default connect(
  state => ({
    user: getAuthUser(state),
  })
)(Menu)
