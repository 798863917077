import React, { Component } from 'react'
import classNames from 'classnames'
import './ActivityTypes.scss'
import _ from 'lodash'
import {ACTIVITY_IMAGES} from '../../constants';
import {t} from "../../i18n";

export default class ActivityTypes extends Component {
  render() {
    const { activityTypes, selected, onClick, tall, className, containerClassName, uniformFontSize, smallerContent, homeActivities, fiveActivities, noHover } = this.props
      console.log({activityTypes})
    let divClass = 'col-md-3'
    let containerClass = ''
    if (smallerContent) divClass = 'col-md-2';
    if (homeActivities) divClass = 'col-md-1';
    if (homeActivities) containerClass = 'seven-cols';
    if (fiveActivities) containerClass = 'offset-1';

    console.log({activityTypes})
    console.log({selected})

    return (
      <div className={`container ${containerClassName}`}>
        <div className={`activity-types ${className}`}>
          <div className={`row`}>
              {activityTypes.map((type, i) => (
                <div
                  onClick={(e) => onClick && onClick(type, e)}
                  key={type.id}
                  style={{padding: 0}}
                  className={classNames(`col-sm-6 col-md-3 col-lg text-center`, {
                    'pointer': typeof onClick === 'function'
                  })}
                >
                  <div className={noHover ? "type-icon-container-events" : classNames("type-icon-container", {
                    'selected': !!selected[type.id],
                  })} >
                    <img
                      className={classNames({
                        'invert': !type.selectedImg,
                      })}
                      style={{height: '3rem', width: '3rem', objectFit: 'contain'}}
                      // src={(selected[type.id] && type.selectedImg) ? type.selectedImg : _.get(type, 'image.full', type.image)}
                      src={ACTIVITY_IMAGES[type.label]}
                      alt="service's logo"
                    />
                  </div>
                  <div className={classNames(
                    'type-label',
                    // {'type-label-container': tall },
                    // {'type-label-container-small': !tall}
                  )}>
                      <h5 className={classNames({"text-activity": !uniformFontSize && type.label.length > 8} ,{"mt-6": tall}, {"mt-4": !tall})}>
                        {_.split(splitLabel(type.label), '<br>', 100).map(label => {
                          return (<p key={label} style={{marginBottom: 5}}>{label}</p>)
                        })}
                      </h5>

                  </div>
                  <h2 className="text-secondary-new">{type.count}</h2>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

ActivityTypes.defaultProps = {
  className: '',
  containerClassName: '',
  selected: {},
  tall: true,
}

const splitLabel = (label) => {
  if (label === 'Privileged Golf Tour') {
    return 'Privileged<br>Golf Tour'
  }
  if (label === 'Privileged Resort') {
    return 'Privileged<br>Resort'
  }
  return label
}

