import { rj } from 'redux-rocketjump'
import { combineReducers } from 'redux'
import { fork } from 'redux-saga/effects'
import positionalArgs from 'redux-rocketjump/plugins/positionalArgs'
import rjPromise from 'redux-rocketjump/plugins/promise'
import request from 'superagent'
import { authApiCall } from './auth'
import { MASTER_TOKEN, API_URL } from '../constants'
import moment from 'moment';

const GET_GOLF_CLUBS = 'GET_GOLF_CLUBS'
export const {
  actions: {
    load: loadClubs,
    unload: unloadClubs,
  },
  selectors: {
    getData: getClubs,
  },
  saga: clubsSaga,
  reducer: clubsReducer,
} = rj({
  type: GET_GOLF_CLUBS,
  state: 'clubs.list',
  api: (data) => {
    console.log({dateClub: data})
    let requestString = `${API_URL}/clubs/open?active=true`
    if (data.date) {
      const fixedDate = moment(data.date).add(4, 'hours').toISOString()
      requestString = `${API_URL}/clubs/open?active=true&date=${fixedDate}`
    }
    return request.get(requestString)
    .set('Authorization', `Bearer ${MASTER_TOKEN}`)
    .then(({ body }) => body)
  }
})()

const GET_GOLF_CLUB = 'GET_GOLF_CLUB'
export const {
  actions: {
    load: loadClub,
    unload: unloadClub,
  },
  selectors: {
    getData: getClub,
  },
  saga: clubSaga,
  reducer: clubReducer,
} = rj(positionalArgs(), {
  type: GET_GOLF_CLUB,
  state: 'clubs.detail',
  api: (id) => {
    console.log({golfID: id, lenght: id.length})

    if (id.length === 24 && id.split('_').length === 1) {
      return request.get(`${API_URL}/clubs/${id}`)
        .set('Authorization', `Bearer ${MASTER_TOKEN}`)
        .then(({ body }) => body)
    } else {
      return request.get(`${API_URL}/clubs?searchName=${encodeURIComponent(id)}`)
        .set('Authorization', `Bearer ${MASTER_TOKEN}`)
        .then(({ body }) => body[0])
    }
  }
})()

const GET_GOLF_CLUB_FACILITIES = 'GET_GOLF_CLUB_FACILITIES'
export const {
  actions: {
    load: loadClubFacilities,
    unload: unloadClubFacilities,
  },
  selectors: {
    getData: getClubFacilities,
  },
  saga: clubSagaFacilities,
  reducer: clubReducerFacilities,
} = rj(positionalArgs(), {
  type: GET_GOLF_CLUB_FACILITIES,
  state: 'clubs.facilities',
  api: (id, date) =>  {
    console.log({dateClub: date})
    let requestString = `${API_URL}/clubs/${id}/openfacilities`
    if (date) {
      const fixedDate = moment(date).add(8, 'hours').toISOString()
      requestString = `${API_URL}/clubs/${id}/openfacilities?date=${fixedDate}`
    }
    return request.get(requestString)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => body)
  }
})()

const GET_ITALIAN_CLUBS = 'GET_ITALIAN_CLUBS'
export const {
  actions: {
    load: loadItalianClubs,
    unload: unloadItalianClubs,
  },
  selectors: {
    getData: getItalianClubs,
  },
  saga: italianClubsSaga,
  reducer: italianClubsReducer,
} = rj({
  type: GET_ITALIAN_CLUBS,
  state: 'clubs.italianClubsList',
  api: () =>  request.get(`${API_URL}/italianclubs`)
    .set('Authorization', `Bearer ${MASTER_TOKEN}`)
    .then(({ body }) => body)
})()

const RESERVE_CLUB = 'RESERVE_CLUB'
export const {
  actions: {
    load: reserveClub,
  },
  saga: reserveClubSaga,
} = rj(rjPromise, {
  type: RESERVE_CLUB,
  state: false,
  callApi: authApiCall,
  api: t => ({ id, ...reservation }) => request.post(`${API_URL}/clubs/${id}/reservations`)
    .set('Authorization', `Bearer ${t}`)
    .send(reservation)
    .then(({ body }) => body)
})()


export const reducer = combineReducers({
  list: clubsReducer,
  italianClubsList: italianClubsReducer,
  detail: clubReducer,
  facilities: clubReducerFacilities
})

export function* saga() {
  yield fork(clubsSaga)
  yield fork(clubSaga)
  yield fork(italianClubsSaga)
  yield fork(reserveClubSaga)
  yield fork(clubSagaFacilities)
}
