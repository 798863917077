import moment from 'moment'
import { padTime } from '../../utils'
import {t} from "i18next";

export const required = value => (value ? undefined :t('required field'))

export const mustIntPositive = value => {
  if (value === null || typeof value === 'undefined' || value === '') {
    return undefined
  }
  const converted = Math.abs(parseInt(value))
  if (isNaN(converted) || String(converted) !== String(value) || converted <= 0) {
    return t('enter a number')
  }
}

export const lessOrEqualThan = max => value => {
  if (value === null || typeof value === 'undefined' || value === '') {
    return undefined
  }
  const converted = Math.abs(parseInt(value))
  if (isNaN(converted) || String(converted) !== String(value) || converted > max) {
    return t('insert a number equal or more than') `${max}`
  }

}

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? t('email address not valid')
    : undefined

export const mustBookingDate = value => {
  if (!value) {
    return undefined
  }

  const m = moment(value, 'DD/MM/YYYY', true)
  if (!m.isValid()) {
    return t('invalid date')
  }

  const now = moment()
  if (!m.isSameOrAfter(now, 'date')) {
    return t('cant book in the past')
  }
}

export const mustDate = value => {
  if (!value) {
    return undefined
  }

  const m = moment(value, 'DD/MM/YYYY', true)
  if (!m.isValid()) {
    return t('invalid date')
  }
}

export const mustHour = value => {
  if (!value) {
    return undefined
  }

  if (
    !moment(value, 'HH:mm', true).isValid() &&
    !moment(value, 'HH', true).isValid()
  ) {
    return t('invalid hour')
  }
}


export const mustFutureBookingHour = (value, allValues) => {
  if (!value) {
    return undefined
  }
  if (
    allValues.dateTimeStart &&
    typeof mustBookingDate(allValues.dateTimeStart) === 'undefined'
  ) {
    const book = moment(`${allValues.dateTimeStart} ${padTime(value)}`, 'DD/MM/YYYY HH:mm')
    const now = moment().add(30, 'minutes')
    if (now.isAfter(book)) {
      return t('cant book in the past')
    }
  }
  return undefined
}

export const minLength = min => value =>
  value && value.length < min ? (t('enter at least') ,`${min}`, t('characters')) : undefined

export const oneFacility = value => {
    const msg=t('Select at least one service')
    return !value || value.length < 1 ? msg : undefined
  }
