import { rj } from 'redux-rocketjump'
import get from 'lodash/get'
import request from 'superagent'
import jsonp from 'superagent-jsonp'

// INSTRAGRAM TOKEN
const IG_TOKEN = 'IGQVJVM2NrRkRuMzZAkZAm8wTTRvclBmQ1F4NEg4emVrWWhveE84RnR4WjJkZAVJ0a3VjZAnFHXzhvcE5TaVVFYjVlbnBVZATNJcFcwSHBzWm5IZA0Nnekg0Yzh2S3BCZAkRMLXNyQlZAmVHNZAeDJRMW16NkcwZAAZDZD'

export const {
  actions: {
    load: loadIsta,
  },
  selectors: {
    getData: getInsta,
  },
  saga,
  reducer,
} = rj({
  type: 'GET_IG',
  state: 'ig',
  api: () => request.get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,username,timestamp&access_token=${IG_TOKEN}`)
    .use(jsonp({
      timeout: 5000,
    }))
    .then((res) => {
      console.log({res})
      if (!get(res, 'body.data', null)) {
        console.error('Bad response from ig', res)
        return { data: [] }
      }
      console.log('Instagram success', res.body)
      return res.body
    })
})()
