import React from 'react'
import get from 'lodash/get'
import './PrivilegedGolfTourImage.scss'
import { ACTIVITY_BG_IMAGES } from '../../constants'

export const PrivilegedGolfTourImage = ({history}) => {

  const onClickTour = () => {
    history.push('/the-royal-diamond')
  }

  const onClickSponsor = () => {
    history.push('/sponsor')

  }

  return (
    <div id={'PrivilegedGolfTourImage'}>
      <img src={'/images/home-image.png'} style={{width:'100%'}}/>
      <div className={'hover-container'}>
        <div className={'golf-tour'} onClick={onClickTour}>
        </div>
        <div className={'sponsor'} onClick={onClickSponsor}>
        </div>
      </div>


    </div>
  )
}

