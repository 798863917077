import React, {useEffect, useState} from 'react'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import './AlbumsDetails.scss'
import withScrollTop from '../../hocs/ScrollTop'
import _ from 'lodash'
import { DateTime } from 'luxon'
import {connect} from "react-redux";
import {
    getConventions,
    loadConventions,
    getConventionsHeader,
    loadConventionsHeader
} from "../../state/conventions";
import { getAuthUser } from 'eazy-auth'
import {getAlbumDetail} from '../../api';
import {Card} from "reactstrap";
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'
import queryString from 'query-string'

const ImageOverlay = ({ imageSrc, closeOverlay }) => {
    const containerStyle = {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const overlayStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        position: 'fixed',
        width: '100%',
        height: '100%',
        zIndex: 999
    }

    const imageStyle = {
        height: '85vh',
        objectFit: 'contain',
        zIndex: 1000,
        cursor: 'default'
    }
    return (
      <div className="cursor-pointer" style={containerStyle}>
          <div style={overlayStyle} onClick={closeOverlay}/>
          <img style={imageStyle} src={imageSrc} alt="errore caricamento immagine" onClick={closeOverlay}/>
      </div>
    )
}

const AlbumsDetails = ({...props}) => {

    const [albumsDetail, setAlbumsDetail] = useState([])
    const [type, setType] = useState('photo')
    const [video, setVideo] = useState('')
    const [image, setImage] = useState(null)

    const id = props.match.params.id

    useEffect(() => {
        refreshPhotoList()
    }, [])

    const refreshPhotoList = async () => {
        const photoList = await getAlbumDetail(id)
        setAlbumsDetail(photoList)
    }

    const onClickVideo = async (videoId) => {
        setVideo(videoId)
    }

    console.log({props: props})
    console.log({albumsDetail})
    const {conventions, conventionsHeader} = props;

    return <div className="album-detail">
        <ModalVideo channel='youtube' autoplay isOpen={!!video} videoId={video} onClose={() => setVideo('')} />
        {image && <ImageOverlay imageSrc={image} closeOverlay={() => setImage(null)} />}

        <Menu bgColor={'rgb(1, 22, 50)'}/>

        <div className="container-cards-album justify-content-center">
            <div class="row">
                <h1 className="title-album">{albumsDetail.title}</h1>
            </div>
            <div class="row">
                <div className="date-album">{albumsDetail?.date && DateTime.fromISO(albumsDetail.date).toFormat('DDD')}</div>
            </div>
            <div className="row">
                <div className="description-album">{albumsDetail.description}</div>
            </div>
            <div className="row" style={{marginBottom: '1rem'}}>
                <button
                  onClick={e => setType('photo')}
                  style={{marginRight: '1rem'}}
                  className={`btn ${type === 'photo' ? 'btn-primary' : 'btn-secondary'}`}>FOTO</button>
                <button
                  onClick={e => setType('video')}
                  className={`btn ${type === 'video' ? 'btn-primary' : 'btn-secondary'}`}>VIDEO</button>
            </div>


            {type === 'photo' && <div class="row">
                {albumsDetail?.pictures?.length === 0 && <div className="date-album">Non ci sono immagini disponibili</div>}
                {albumsDetail?.pictures?.map(image => {
                    return (
                        <Card className="album-cards col-xs-12 col-sm-6 col-md-3 pointer" onClick={() => setImage(image.full)}>
                            <img className="images-album" src={image.full} alt="errore caricamento immagine"/>
                        </Card>
                    )
                })}
            </div>}


            {type === 'video' && <div class="row">
                {albumsDetail?.videos?.length === 0 && <div className="date-album">Non ci sono video disponibili</div>}
                {albumsDetail?.videos.map(video => {
                    const queryParams = getParameterFromUrl(video)

                    const videoId = queryParams.v
                    return (
                        <Card className="album-cards col-xs-12 col-sm-6 col-md-3 pointer" onClick={() => onClickVideo(videoId)}>
                            <img className="images-album" src={`https://img.youtube.com/vi/${videoId}/0.jpg`} alt="errore caricamento immagine"/>
                        </Card>
                    )
                })}
            </div>}
        </div>

        <Footer/>
    </div>
}


export default connect(state => ({
    conventions: getConventions(state),
    conventionsHeader: getConventionsHeader(state),
    user: getAuthUser(state)
}), {
    loadConventions,
    loadConventionsHeader,
})(withScrollTop(AlbumsDetails))


export const getParameterFromUrl = (url) => {
    let regex = /[?&]([^=#]+)=([^&#]*)/g;
    let params = {};
    let match;
    while ((match = regex.exec(url))) {
        params[match[1]] = match[2];
        console.log(match[1], match[2]);
    }
    console.log({params});
    return params;
};
