import request from 'superagent'
import { createSelector } from 'reselect'
import { rj } from 'redux-rocketjump'
import { takeEveryAndCancel } from 'redux-rocketjump/effects'
import combineRjs from 'redux-rocketjump/plugins/combine'
import rjPosArgs from 'redux-rocketjump/plugins/positionalArgs'
import { authApiCall, withToken } from './auth'
import { API_URL, MASTER_TOKEN } from '../constants'

const GET_RESORTS = 'GET_RESORTS'
const GET_RESORT_DETAIL = 'GET_RESORT_DETAIL'

export const {
  connect: {
    list: {
      actions: {
        load: loadResorts,
      },
      selectors: {
        getData: getResorts,
      }
    },
    detail: {
      actions: {
        load: loadResortDetail,
        unload: unloadResortDetail,
      },
      selectors: {
        getData: getResortDetail,
      }
    },
  },
  saga,
  reducer,
} = combineRjs({

  list: rj({
    type: GET_RESORTS,
    api: t => () => withToken(MASTER_TOKEN, request.get(`${API_URL}/resorts`))
      .then(({ body }) => body)
  }),
  detail: rj({
    type: GET_RESORT_DETAIL,
    api: t => (id) => withToken(MASTER_TOKEN, request.get(`${API_URL}/resorts/${id}`))
      .then(({ body }) => body)
  }),

}, {
  state: 'resorts',
  callApi: authApiCall,
})
