import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { FieldInput } from '../Form/Fields'
import { required, email } from '../Form/validation'

const RecoverForm = ({ handleSubmit, valid }) => (
  <form onSubmit={handleSubmit}>
    <div className='w-100'>
      <div className='row'>
        <div className='col-md-4 offset-md-4'>
          <Field
            labelProps={{ className: 'text-secondary' }}
            className='border-primary'
            validate={[required, email]}
            label='EMAIL'
            component={FieldInput}
            name='email'
            type='email'
          />

        </div>

      </div>

      <div className='row'>
        <div className='col-md-6 offset-md-3'>
          <div className='text-center'>
            <button disabled={!valid} className='btn bg-white text-secondary'>Recupera password</button>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-md-6 offset-md-3'>
          <div className='text-center'>
            <Link className="text-secondary" to="/login">Torna al login</Link>
          </div>
        </div>
      </div>
    </div>
  </form>
)

export default reduxForm({
  form: 'register',
})(RecoverForm)
