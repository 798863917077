import { rj } from 'redux-rocketjump'
import { createSelector } from 'reselect'
import { takeEveryAndCancel } from 'redux-rocketjump/effects'
import rjPromise from 'redux-rocketjump/plugins/promise'
import { select, fork, put } from 'redux-saga/effects'
import { makeAuthFlow, makeAuthReducer, getAuthUser, updateUser } from 'eazy-auth'
import request from 'superagent'
import { MASTER_TOKEN, API_URL, CARDS_ICONS } from '../constants'

// Inject token in Authorization header when provided
export const withToken = (token, baseRequest) =>
  (token ? baseRequest.set('Authorization', `Bearer ${token}`) : baseRequest)


const login = ({ username, password }) =>
  request.post(`${API_URL}/auth`)
  .set('Authorization', `Basic ${btoa(`${username}:${password}`)}`)
  .then(({ body }) => ({
    access_token: body.token,
    user: body.user,
  }))

const me = (token, loginData) => {
  // Get user data from login skip the me request
  // if (loginData && loginData.user) {
  //   return Promise.resolve(loginData.user)
  // }
  return withToken(token, request.get(`${API_URL}/users/me`))
    .then(({ body }) => body)
}

const reducer = makeAuthReducer()

const { authFlow: authSaga, authApiCall } = makeAuthFlow({
  meCall: me,
  // refreshTokenCall: refreshToken,
  loginCall: login,
})

export const getClubUserAccount = createSelector(
  getAuthUser,
  user => {
    if (!user) {
      return user
    }
    if (user.loyaltyCard && user.loyaltyCard.membershipPlan) {
      return {
        ...user,
        loyaltyCard: {
          ...user.loyaltyCard,
          membershipPlan: {
            ...user.loyaltyCard.membershipPlan,
            ...CARDS_ICONS[user.loyaltyCard.membershipPlan.iconDef]
          }
        }
      }
    }
    return user
  }
)

export const register = (payload) =>
  request.post(`${API_URL}/register`)
  .set('Authorization', `Bearer ${MASTER_TOKEN}`)
  .send(payload)
  .then(({ body }) => body)

export const recover = (payload) =>
  request.post(`${API_URL}/password-resets`)
  .set('Authorization', `Bearer ${MASTER_TOKEN}`)
  .send(payload)
  .then(({ body }) => body)

export const {
  actions: {
    load: updatePassword,
  },
  saga: updatePasswordSaga,
} = rj(rjPromise, {
  type: 'UPDATE_PASSWORD',
  state: false,
  takeEffect: takeEveryAndCancel,
  apiExtraParams: function *(params) {
    const user = yield select(getAuthUser)
    return { ...params, user }
  },
  api: ({ password, oldPassword, user }) =>
    request.put(`${API_URL}/users/${user.id}/password`)
      .set('Authorization', `Basic ${btoa(`${user.email}:${oldPassword}`)}`)
      .send({
        password,
      })
      .then(({ body }) => body)
})()

export const {
  actions: {
    load: updateUserData,
  },
  saga: upadateUserDataSaga,
} = rj(rjPromise, {
  type: 'UPDATE_USER_DATA',
  state: false,
  takeEffect: takeEveryAndCancel,
  callApi: authApiCall,
  successEffect: function *(user) {
    yield put(updateUser(user))
  },
  api: t => user => withToken(t, request.put(`${API_URL}/users/${user.id}`))
    .send(user)
    .then(({ body }) => body)
})()

function *saga() {
  yield fork(authSaga)
  yield fork(updatePasswordSaga)
  yield fork(upadateUserDataSaga)
}

export {
  saga,
  authApiCall,
  reducer,
}
