import React from 'react'
import get from 'lodash/get'
import { Button } from 'reactstrap'
import { ACTIVITY_BG_IMAGES } from '../../constants'
import './ActivityDetailDescription.scss'
import {t} from "i18next";

class ActivityDetailDescription extends React.PureComponent {
  Print(ok){
    console.log(ok)
  }
  render() {
    const { activity, reserve } = this.props
    const defaultImage = `/images/${ACTIVITY_BG_IMAGES[get(activity, 'MasterCategory', 'Default')]}`
    const backgroundImage = `url(${get(activity, 'CoverImage', defaultImage)})`
    const longDescription = get(activity, 'longDescription')
    const numPart = get(activity, 'NumPart')
    const minAge = get(activity, 'MinAge')
    const priceRange = get(activity, 'PriceRange')
    let attributeValues = get(activity, 'AttributeValues', [])

    priceRange && attributeValues.unshift({ Name:t('price'), Value: priceRange })
    minAge && attributeValues.unshift({ Name:t('min age'), Value: minAge })
    numPart && attributeValues.unshift({ Name:t('participants num'), Value: numPart })

    return (
      <div className="container-fluid activitydetail-description">
        <div className='row pr-5 pt-5 pb-5 pl-5'>
          <div className='col-md-7'>
            <div className="activitydetail-description-row">
              <div className="activitydetail-description-text">
                {activity.Description && <h2 style={{fontWeight: '700'}}>{get(activity, 'Title', t('privileged offer'))}</h2>}
                {activity.Description}
                <div className="my-4">
                  {attributeValues.map((service, i) => (
                    <div key={i} className="row mb-3">
                      <div className="detail-service-label" style={{paddingLeft: 15}}>
                        {t(`${service.Name}`)}
                      </div>
                      <div className=" text-left" style={{paddingLeft: 15}}>
                        {service.Value.trim()}
                      </div>
                    </div>

                  ))}
                </div>

              </div>
              <p>
                {activity.AdditionalNote}
              </p>
              {!!longDescription && <p>
                {longDescription}
              </p>}
              <div className="mt-2 mb-4" style={{ padding: '0 30px 0 0' }}>
                <Button className="btn btn-primary btn-prenota-modal" onClick={reserve}>{t('book')}</Button>
              </div>
            </div>
          </div>
          <div className='col-md-5 img-container' style={{
            backgroundImage,
          }}>
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityDetailDescription
