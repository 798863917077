import React from 'react'
import { reduxForm, Field } from 'redux-form'
import sortBy from 'lodash/sortBy'
import { required, email, mustDate } from '../Form/validation'
import { FieldInput } from '../Form/Fields'
import {t} from "i18next";

const UserInfoForm = ({ handleSubmit, valid, submitting, submitSucceeded, clubs, selectedCountry, handleChangeCountry, countries }) => (
  <form onSubmit={handleSubmit} className='p-3'>
    <div className='row'>
      <div className='col-md-6'>
        <Field
          validate={[required]}
          placeholder={t('name')}
          className='text-primary border-primary'
          name='name'
          component={FieldInput}
        />
        <Field
          validate={[required]}
          placeholder={t('surname')}
          className='text-primary border-primary'
          name='surname'
          component={FieldInput}
        />
        <Field
          validate={[required, mustDate]}
          placeholder={t('birthdate')}
          className='text-primary border-primary'
          name='birthDate'
          component={FieldInput}
        />
        <Field
          validate={required}
          placeholder={t('city')}
          className='text-primary border-primary'
          name='address.city'
          component={FieldInput}
        />
        <Field
          validate={required}
          placeholder={t('province')}
          className='text-primary border-primary'
          name='address.municipality'
          component={FieldInput}
        />
        <Field
          type='select'
          name='favoriteClubId'
          className='border-primary text-primary no-webkit'
          emptyClassName='text-placeholder-blue'
          component={FieldInput}
        >
          <option value={''}>{t('select membership club')}</option>
          <option value={'62cc55ecc40b1514b8be9ad8'}>{t('other').toUpperCase()}</option>
          { clubs && clubs.length > 0 && sortBy(clubs, 'name').map(club => (
            <option key={club._id} value={club._id}>{club.name}</option>
          ))}
        </Field>
      </div>
      <div className='col-md-6'>
        <Field
          validate={[required, email]}
          placeholder={t('email address')}
          className='text-primary border-primary'
          type='email'
          name='email'
          component={FieldInput}
        />
        <Field
          validate={required}
          placeholder={t('phone')}
          className='text-primary border-primary'
          name='phoneNumber'
          component={FieldInput}
        />
        <Field
          validate={required}
          placeholder={t('address')}
          className='text-primary border-primary'
          name='address.address'
          component={FieldInput}
        />
        <Field
          validate={required}
          placeholder='CAP'
          className='text-primary border-primary'
          name='address.zipCode'
          component={FieldInput}
        />
          <select className='form-control text-primary border-primary'
                  style={{backgroundColor: 'transparent'}}
                  value={selectedCountry}
                  onChange={handleChangeCountry}
          >
            <option style={{color: 'grey'}} value=''>{t('nation')}</option>
            {countries && countries.map(country => (
              <option style={{color: 'grey'}} key={country.key} value={country.key}>{country.value}</option>
            ))}
          </select>
        <Field
          validate={required}
          placeholder={t('number card fig')}
          className='text-primary border-primary mt-3'
          name='figNumber'
          component={FieldInput}
        />
      </div>

    </div>

    <br />
    <div className='text-center'>
      <button
        disabled={!valid || submitting}
        className='btn btn-secondary text-white pl-5 pr-5'>{t('save info')}</button>
    </div>
    {submitSucceeded  && <div className='text-secondary d-flex justify-content-center align-items-center mt-5'>
      <i className="fa fa-2x fa-check-circle-o"></i>
      <h5 className='m-0 ml-3'>{t('changed info')}</h5>
    </div>}
  </form>
)

export default reduxForm({
  form: 'userInfo',
})(UserInfoForm)
