import React, {Component, Fragment, useMemo} from 'react'
import memoize from 'memoize-one'
import countBy from 'lodash/countBy'
import groupBy from 'lodash/groupBy'
import kebabCase from 'lodash/kebabCase'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import EventsList from '../../components/EventsList'
import EventReservationSuccess from '../../components/EventReservationSuccess'
import MarkerMap, { TypedPopup } from '../../components/MarkerMap'
import royalPinIcon from '../../components/MarkerMap/pin-the-royal-diamond.png'
import ActivityTypes from '../../components/ActivityTypes'
import Menu from '../../components/Menu'
import { connect } from 'react-redux'
import {
  getEvents,
  loadEvents,
  getEventsGlobal,
  loadEventsGlobal,
  getEventsGlobalSouth,
  loadEventsGlobalSouth,
  getEventsRegulation,
  loadEventsRegulation,
  getFilteredEvents,
  loadFilteredEvents,
  getEventTypes,
  loadEventTypes
} from '../../state/events'
import {labelFromEventType, labelFromEventTypeTwoLines} from '../../utils'
import withScrollTop from '../../hocs/ScrollTop'
import './Events.scss'
import ActivityTypesEvents from "../../components/ActivityTypesEvents";
import { Button } from 'reactstrap'
import Footer from '../../components/Footer'
import regioni from '../../regioni.json';
import provinces from '../../province.json';
import find from 'lodash/find';
import _ from 'lodash'
import {MONTHS} from '../../constants';
import {DateTime} from 'luxon';
import {t} from "i18next";
import {PrivilegedGolfTourImage} from '../../components/PrivilegedGolfTourImage/PrivilegedGolfTourImage';

const EVENTS_TYPE = [
  "PrivilegedTour2020",
  "silverRace",
  "goldRace",
  "TrialWGleague",
  "WGLeagueF",
  "AmatOpen",
  "WAGCTour",
  "WAGCqs",
  "WACGMatchP",
  "WACGMatchPFin",
  "WAGCNatFin",
  "WAGCWorldF",
]

class Events extends Component {
  state = {
    selectedType: null,
    eventReservationSuccess: false,
    region: null,
    province: null,
    month: null,
    search: '',
    filteredEventsLocal: null
  }

  componentDidMount() {
    this.props.loadEventsGlobal()
    this.props.loadEventsGlobalSouth()
    this.props.loadEvents()
    this.props.loadEventsRegulation()
    this.props.loadFilteredEvents('')
    this.props.loadEventTypes()
  }

  toggleSelectedType = type => {
    this.setState({
      selectedType: type.key,
    })
  }

  getEventsTypes = memoize(events => {
    console.log({events})
    if (!events) {
      return []
    }
    const eventsCount = countBy(events, event => event.type)
    // The Royal Diamond means all events
    eventsCount['PrivilegedTour2020'] = events.length
    // Overriding 'raceTheChampion' count for now
    eventsCount['raceTheChampion'] = 1

    return EVENTS_TYPE.map(type => ({ // 'superFinal' rimosso
      id: type,
      label: labelFromEventTypeTwoLines(type),
      image: `/images/${kebabCase(type)}.png`,
      // selectedImg: '/images/icona-golf-club-blue.png',
      count: eventsCount[type] || '-',
    }))
  })

  getMarkers = memoize((events, selectedType) => {
    if (!events) {
      return []
    }
    let filteredEvents
    if (selectedType && selectedType !== 'PrivilegedTour2020') {
      filteredEvents = events.filter(event => event.type === selectedType)
    } else {
      filteredEvents = events
    }
    const clubsById = keyBy(filteredEvents.map(event => event.club), '_id')
    return map(groupBy(filteredEvents, 'club._id'), (events, clubId) => {
      const club = clubsById[clubId]
      return {
        ...club,
        events,
        icon: royalPinIcon,
        type: 'event',
        position: {lat: _.get(club, 'coordinates[1]', null), lng: _.get(club, 'coordinates[0]', null)}
      }

    })
  })

  _filterEvents = (e) => {
    console.log(e.target.value)
    // this.props.loadFilteredEvents(e.target.value)
    this.setState({search: e.target.value})
  }

  handleChangeRegion = e => {
    const idRegion = e.target.value
    let region = null
    if (idRegion) {
      region = find(regioni, { id: idRegion })
    } else {
      region= null
    }

    this.setState({
      region,
      province: null,
    })
  }

  handleChangeProvince = e => {
    const idProvince = e.target.value
    console.log({idProvince})
    const province = find(provinces, { id: idProvince })
    console.log({province})

    this.setState({
      province,
    })
  }

  handleChangeMonth = e => {
    const month = e.target.value
    this.setState({
      month,
    })
  }

  onPressSearch = () => {
    const { filteredEvents } = this.props
    const { region, province, search, month} = this.state

    const filteredEventsLocal = filteredEvents.filter(event => {
      if (province && _.get(event, 'club.address.municipality') !== province.sigla_automobilistica) {
        return
      }
      if (region && _.get(event, 'club.address.region') !== region.nome) {
        return
      }
      if (!!search) {
        const searchLower = search.toLowerCase()
        console.log({searchLower})
        if ((
          (event.type && !event.type.toLowerCase().includes(searchLower)) &&
          (event.club && event.club.name && !event.club.name.toLowerCase().includes(searchLower)))
        ) {
          return
        }

      }
      if (!!month) {
        const eventMonth = Number(DateTime.fromISO(event.date).toFormat('MM'))
        if (eventMonth !== (MONTHS.findIndex(m => m === month)) + 1) {
          return
        }

      }
      return true
    })
    console.log({filteredEventsLocal})
    this.setState({filteredEventsLocal})
  }

  render() {
    const { selectedType, region, province, search, month, filteredEventsLocal } = this.state
    console.log({region, province, search, month})
    const { events, eventsGlobal, eventsGlobalSouth, eventsRegulation, filteredEvents, eventTypes } = this.props
    console.log({eventsGlobalSouth})
    console.log({eventTypes})
    // const eventsTypes2 = this.getEventsTypes(events)

    const selected = selectedType ? { [selectedType]: true } : {}
    const markers = this.getMarkers(events, selectedType)

    console.log({filteredEvents})

    _.forEach(eventTypes, (type, index) => {
      if (type.key === 'PrivilegedTour2020') {
        eventTypes[index].count = (events || []).length
      } else {
        eventTypes[index].count = (events || []).filter(event => event.type === type.key).length
      }
    })

    const showedEvents = filteredEventsLocal ? filteredEventsLocal : filteredEvents

    return (
      <div className='events'>
        <Menu bgColor={'rgb(1, 22, 50)'} />
        {this.state.eventReservationSuccess && <EventReservationSuccess />}
        {!this.state.eventReservationSuccess && <Fragment>
          <div className='home-sponsorsbox'>
            <PrivilegedGolfTourImage history={this.props.history}/>
          {/*<img src={'/images/home-image.png'} style={{width:'100%'}}/>*/}
            {/*<div className="home-sponsorsbox-image"></div>
            <div className="wrapper-logos">
              <div className='home-new-container'>
                <div className='home-text-container'>
                  <h1 style={{fontWeight:'bold'}}>Privileged Golf Tour</h1>
                  <h2 className='home-sub-header-text'>Sponsored by</h2>
                </div>
                <div className='home-logos-container'>
                  {Array(13).fill(null).map((a,i) => {
                    return <div className='home-sLogo'>
                      <img src={`/images/home-logos/${i+1}.png`} className='home-logo-image'/>
                    </div>
                  })}
                </div>
              </div>
                </div> */}
          </div>
          <div className='events-types'>
            <ActivityTypesEvents
              className='py-3'
              selected={selected}
              onClick={this.toggleSelectedType}
              tall={false}
              activityTypes={eventTypes || []}
              smallerContent={true}
              noHover
            />
          </div>
          <MarkerMap
            className='events-map'
            popupComponent={TypedPopup}
            markers={markers}
            options={{
              zoomControlOptions: {
                position: 7,
              },
            }}
          />
          <div className="events-news-container">
            {eventsRegulation &&  <a href={eventsRegulation.full} target="_blank" className="btn btn-outline-primary btn-sm pdf-link ">
              {t('regulation')} <br/> {t('royal diamond')}
            </a> }
            {eventsGlobal && <a href={eventsGlobal.full} target="_blank" rel="noopener noreferrer" className=" btn btn-outline-primary btn-sm pdf-link ">
              {t("rank")} <br/> {t('royal diamond')}
            </a>}

            {eventsGlobalSouth && <a href={eventsGlobalSouth.full} target="_blank" rel="noopener noreferrer" className=" btn btn-outline-primary btn-sm pdf-link "
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              NEWS
            </a>}
          </div>

          <div className="py-4 text-center container">
            <h2 style={{marginTop:16,marginBottom:32}}>{t('calendar tour')}</h2>
            <div className='events-filters-box'>
              <input
                className="form-control select-20"
                placeholder="Golf Club"
                onChange={this._filterEvents}
              />
              <select className='form-control ml-2 select-20' onChange={this.handleChangeRegion}>
                <option label={t('region')} value={''} />
                {regioni.map(region => (
                  <option key={region.id} value={region.id}>{region.nome}</option>
                ))}
              </select>
              <select className='form-control ml-2 select-20' onChange={this.handleChangeProvince}>
                <option label={t('province')} value={''} />
                {region && (
                  provinces.filter(provincia => provincia.id_regione === region.id).map(provincia => (
                    <option key={provincia.id} value={provincia.id}>{provincia.nome}</option>
                  ))
                )}
              </select>
              <select className='form-control ml-2 select-20' onChange={this.handleChangeMonth}>
                <option label={t('month')} value={''} />
                {MONTHS.map(month => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
              <button className="btn btn-secondary text-white color-orange" onClick={this.onPressSearch}>
                {t('search')}
              </button>
            </div>

          </div>
          {showedEvents && <EventsList
            onEventBooked={() => {
              this.setState({
                eventReservationSuccess: true,
              })
            }}
            className='container'
            showClub
            groupByMonth
            events={showedEvents}
          />}
        </Fragment>}
          <Footer />
      </div>
    )
  }
}

export default connect(state => ({
  events: getEvents(state),
  eventsGlobal: getEventsGlobal(state),
  eventsGlobalSouth: getEventsGlobalSouth(state),
  eventsRegulation: getEventsRegulation(state),
  filteredEvents: getFilteredEvents(state),
  eventTypes: getEventTypes(state),
}), {
  loadEvents,
  loadEventsGlobal,
  loadEventsGlobalSouth,
  loadEventsRegulation,
  loadFilteredEvents,
  loadEventTypes
})(withScrollTop(Events))
