import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { required } from '../Form/validation'
import { FieldInput, FieldCheckbox } from '../Form/Fields'
import UserFormSection from '../CommonForm/UserFormSection'
import AccountFormSection from '../CommonForm/AccountFormSection'
import './RegisterCardForm.scss'
import {openPrivacyPolicy, openTermsCondition} from "../../utils";
import {t} from "i18next";

const validate = data => {
  let errors = {}
  if (data.password !== data.passwordConfirm) {
    errors.passwordConfirm = t('passwords must match')
  }
  if (data.email !== data.emailConfirm) {
    errors.emailConfirm = t('email must match')
  }
  return errors
}

const RegisterCardForm = ({ handleSubmit, invalid, submitting, error, clubs, countries, handleChangeCountry, selectedCountry }) => (
  <form onSubmit={handleSubmit} className='register-card-form'>
    <div className='bg-primary'>
      <div className='container py-5 px-md-5'>
        <UserFormSection clubs={clubs} countries={countries} handleChangeCountry={handleChangeCountry} selectedCountry={selectedCountry}/>
        <div className='row'>
          <div className='col-md-6 offset-md-3'>
            <Field
              name='cardCode'
              placeholder={t('insert card code')}
              className='border-white bg-primary text-white mt-3'
              component={FieldInput}
              validate={required}
            />
          </div>
        </div>
      </div>
    </div>
    <div className='bg-golf'>
      <div className='container px-md-5'>
        <div className='text-center mb-3'>
          <h2>{t('account info')}</h2>
          <div>
            {t('choose password')}
          </div>
        </div>
        <div className='row w-100'>
          <div className='col-md-6 offset-md-3'>
            <AccountFormSection />
            <div className='my-4'>
              <Field
                label={<span>{t('i declare')} <a className="cursor-pointer" onClick={openTermsCondition}>{t('general regulation')}</a> {t('of the platform')}</span>}
                name='aceptTheRules'Dichiaro di aver preso visione del
                validate={required}
                component={FieldCheckbox}
              />
              <Field
                label={<span>{t('i declare + l')} <a className="cursor-pointer" onClick={openPrivacyPolicy}>{t('privacy policy')}</a></span>}
                name='aceptPrivacy'
                validate={required}
                component={FieldCheckbox}
              />
            </div>
          </div>
        </div>
        <button
          className='btn btn-primary btn-create-account mt-3'
          disabled={invalid || submitting}
          type='submit'>{t('create account')}</button>

        {error && <div className='alert alert-danger mt-4'>
          <b>{t('error register phase')}</b>
          <div>{error}</div>
          <div>{t('retry later')}</div>
        </div>}

      </div>
    </div>
  </form>
)
export default reduxForm({
  form: 'registerCard',
  validate,
})(RegisterCardForm)
