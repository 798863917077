import React from 'react'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import {retina} from 'leaflet/src/core/Browser';
import moment from 'moment';

const GolfPopup = ({ marker, date }) => {

    let url = `/club/${marker.searchName || marker._id}`

    if (date) {
        url = url + '?date=' + moment(date).format('DD-MM-YYYY')
    }

    return (
      <div>
          {marker.logo && <img
            alt='club logo'
            height={50}
            className='mb-2'
            src={get(marker, 'logo.thumbnail.squared')}
          />}
          <h5 className="text-secondary">
              <Link to={url} className="text-secondary" params={{ date }}>
                  {marker.name}
              </Link>
          </h5>
          <p className="text-white">
              {get(marker, 'address.address')}{' '}<br />
              {get(marker, 'address.zipCode')}{' '}
              {get(marker, 'address.city')}{' '}
              {get(marker, 'address.municipality')}
          </p>
      </div>
    )
}
export default GolfPopup
