import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import './ActivityDetailModal.scss'
import ActivityReservationFormModal from '../ActivityReservationFormModal'
import { Link, withRouter } from 'react-router-dom'
import {t} from "i18next";

class ActivityDetailModal extends React.PureComponent {
  render(){
    const { isOpen, toggle, user, activity, onSubmit, onSubmitSuccess, history } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle} className='activitydetail-modal' backdropClassName='backdrop-golfclubdetail-modal'>
        <ModalBody>
          {user && <ActivityReservationFormModal
            onUndo={toggle}
            activity={activity}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
          />}
          {!user && <div>
            <h4>{t('do login')}</h4>
            <p>
                {t('to make a reservation you need to login')}
            </p>
            <Link style={{textTransform:"uppercase"}} to={{pathname:'/login', state:{referrer:history.location.pathname+'?reservationModal=1'}}} className="btn btn-secondary text-white">{t('login')}</Link>
          </div>}
        </ModalBody>
      </Modal>
    )
  }
}

export default withRouter(ActivityDetailModal)
