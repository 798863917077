import request from 'superagent'
import { createSelector } from 'reselect'
import { rj } from 'redux-rocketjump'
import rjCache from 'redux-rocketjump/plugins/cache'
import { MASTER_TOKEN, API_URL, CARDS_ICONS } from '../constants'

const GET_PLANS = 'GET_PLANS'
export const {
  actions: {
    load: loadPlans,
  },
  selectors: {
    getData: getPlans,
    getCards: getPlansCard,
  },
  reducer,
  saga,
} = rj(rjCache(), {
  type: GET_PLANS,
  state: 'plans',
  proxySelectors: {
    getCards: ({ getData }) => createSelector(getData, plans => {
      if (plans === null) {
        return []
      }
      return plans.map(plan => ({
        ...plan,
        slug: plan.name.replace(/[^\w]+/g, '-').toLowerCase(),
        ...CARDS_ICONS[plan.iconDef]
      }))
    })
  },
  api: () =>  request.get(`${API_URL}/membership-plans`)
    .set('Authorization', `Bearer ${MASTER_TOKEN}`)
    .then(({ body }) => body)
})()
