import React from 'react'
// import HomeNewsLetter from '../../components/HomeNewsLetter'
import './Albums.scss'
import moment from 'moment';
import _ from 'lodash';
import {Field, reduxForm} from 'redux-form';
import {FieldDatepicker} from '../../components/Form/Fields';
import find from 'lodash/find';
import '../../components/HomeGolfClubsList/HomeGolfClubsList.scss'
import province from "../../province.json";
import {normalizeDate} from '../../components/GolfClubDetailModal/GolfClubDetailModal';
import {t} from "i18next";

const AlbumFilters= ({search,setSearch, date, setDate}) => {

    return (
        <div className='row album-filters'>
            {/*<div className='d-flex flex-md-row flex-column filters-container '>*/}

            <div className="col-12 col-sm-4" style={{marginBottom: '1rem'}}>
              <input
                value={search}
                onChange={(e) => {setSearch(e.target.value)}}
                type='text'
                className='form-control'
                placeholder={t('search')}
              />

            </div>

            <div className="col-12 col-sm-4">

              <Field
                value={date}
                className={'form-control date-picker-gallery'}
                emptyClassName='text-placeholder'
                // labelProps={{
                //   className: 'golfclubdetail-form-label',
                // }}
                // row
                // fullWidth
                colLabel={3}
                colInput={9}
                // name='dateTimeStart'
                placeholder={t('date')}
                component={FieldDatepicker}
                onChange={(newDate) => setDate(normalizeDate(newDate))}
                // validate={[required, mustBookingDate]}
              />
            </div>


        {/*</div>*/}
        </div>
    )
}


export default reduxForm({
    form: 'HomeGolfClubsListFilter',
})(AlbumFilters)
