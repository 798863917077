import React from 'react'
import { Field } from 'redux-form'
import { required, mustDate, email } from '../Form/validation'
import { FieldInput, FieldDatepicker } from '../Form/Fields'
import sortBy from 'lodash/sortBy'
import regioni from "../../regioni.json";
import {t} from "i18next";

const UserFormSection = ({ readOnly, className, clubs, countries, handleChangeCountry, selectedCountry}) => (
  <div className={className}>
    <div className='row mb-3'>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          name='name'
          className='border-white bg-primary text-white'
          placeholder={t('name')}
          component={FieldInput}
          validate={required}
        />
      </div>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          name='surname'
          className='border-white bg-primary text-white'
          placeholder={t('surname')}
          component={FieldInput}
          validate={required}
        />
      </div>
    </div>
    <div className='row'>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          name='birthDate'
          className='border-white bg-primary text-white'
          emptyClassName='text-placeholder'
          placeholder={t('birthdate')}
          fullWidth
          component={FieldDatepicker}
          validate={[required, mustDate]}
        />
      </div>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          className='border-white bg-primary text-white'
          name='fiscalCode'
          placeholder={t('fiscal code')}
          component={FieldInput}
          validate={required}
        />
      </div>
    </div>
    <div className='row'>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          name='email'
          className='border-white bg-primary text-white'
          placeholder={t('email address')}
          component={FieldInput}
          type='email'
          validate={[required, email]}
        />
      </div>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          className='border-white bg-primary text-white'
          name='emailConfirm'
          placeholder={t('confirm email')}
          component={FieldInput}
          type='email'
          validate={[required, email]}
        />
      </div>
    </div>
    <div className='row'>

      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          className='border-white bg-primary text-white'
          name='phoneNumber'
          placeholder={t('phone')}
          component={FieldInput}
          validate={required}
        />
      </div>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          name='address.address'
          className='border-white bg-primary text-white'
          placeholder={t('address')}
          component={FieldInput}
          validate={required}
        />
      </div>
    </div>
    <div className='row mb-3'>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          name='address.city'
          className='border-white bg-primary text-white'
          placeholder={t('city')}
          component={FieldInput}
          validate={required}
        />
      </div>
      <div className='col-md-6'>
        <div className='row'>
          <div className='col-md-3'>
            <Field
              readOnly={readOnly}
              name='address.municipality'
              className='border-white bg-primary text-white'
              placeholder={t('province')}
              component={FieldInput}
              validate={required}
            />
          </div>
          <div className='col-md-3'>
            <Field
              readOnly={readOnly}
              name='address.zipCode'
              className='border-white bg-primary text-white'
              placeholder={t('cap')}
              component={FieldInput}
              validate={required}
            />
          </div>
          <div className='col-md-6'>
            <select className='form-control text-white select-no-outline'
                    style={{backgroundColor: 'transparent'}}
                    value={selectedCountry}
                    onChange={handleChangeCountry}
            >
              <option style={{color: 'grey'}} value=''>{t('nation')}</option>
              {countries && countries.map(country => (
                <option style={{color: 'grey'}} key={country.key} value={country.key}>{country.value}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
    <div className='row'>
      <div className='col-md-6'>
        <Field
            readOnly={readOnly}
            name='figNumber'
            className='border-white bg-primary text-white'
            placeholder={t('number card fig')}
            component={FieldInput}
        />
      </div>
      <div className='col-md-6'>
        <Field
          readOnly={readOnly}
          type='select'
          name='favoriteClubId'
          className='border-white bg-primary text-white no-webkit'
          emptyClassName='text-placeholder'
          component={FieldInput}
        >
          <option value={''}>{t('select membership club')}</option>
          <option value={''}>{t('other')}</option>
          { clubs && clubs.length > 0 && sortBy(clubs, 'name').map(club => (
            <option className="text-white" key={club._id} value={club._id}>{club.name}</option>
          ))}
        </Field>
      </div>
    </div>
  </div>
)

UserFormSection.defaultProps = {
  readOnly: false,
}

export default UserFormSection
