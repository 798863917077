import React, { PureComponent } from 'react'
import find from 'lodash/find'
import memoize from 'memoize-one'
import HomeItemCard from '../HomeItemCard'
import regioni from '../../regioni.json'
import province from '../../province.json'
import './HomeGolfClubsList.scss'
import {Field, reduxForm} from 'redux-form';
import {FieldDatepicker, FieldInput} from '../Form/Fields';
import {mustBookingDate, mustFutureBookingHour, mustHour, oneFacility, required} from '../Form/validation';

class HomeGolfClubsList extends PureComponent {
  state = {
    search: '',
    regione: null,
    provincia: null,
  }



  render() {
    const { search, regione, provincia } = this.state
    const { markers: matchingMarkers, date } = this.props

    return (
      <div className='px-lg-5 px-md-4 px-sm-2 home-golf-clubs-list mb-5'>
        {console.log({matchingMarkers})}
        <div className='row m-0 p-0'>
          {matchingMarkers && matchingMarkers.map(item => (
            <HomeItemCard
              type={item.type}
              item={item}
              date={date}
              key={item.id}
            />
          ))}
          {matchingMarkers && matchingMarkers.length === 0 && (
            <div className='text-center text-primary w-100 mb-4 mt-4'>
              <h5>Nessun risultato disponibile.</h5>
            </div>
          )}
        </div>


      </div>
    )
  }
}

export default HomeGolfClubsList
