import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { getAuthUser } from 'eazy-auth'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import ResortDetailCover from './ResortDetailCover'

import { loadClub, getClub, unloadClub, reserveClub } from '../../state/clubs'
import {
  loadActivites,
  unloadActivities,
  getActivityTypesCounts,
  getActivities,
  clearAllActivitiesById,
} from '../../state/activities'
import {
  loadResortDetail,
  getResortDetail,
  unloadResortDetail
} from '../../state/resorts'
import withScrollTop from '../../hocs/ScrollTop'
import './ResortDetail.scss'
import {Button} from "reactstrap";
import _ from "lodash";
import ResortDetailCollage from "./ResortDetailCollage/ResortDetailCollage";
import GolfClubDetailRelatedActivities from "../../components/GolfClubDetailRelatedActivities";
import {t} from "i18next";

class ResortDetail extends React.Component {
  state = {
    selectedType: null,
    reservationSuccess: false,
    showingModal: false,
    packId: null
  }

  componentDidMount(){
    const { match } = this.props
    const { params } = match
    this.props.loadResortDetail(params.id)
  }

  componentWillUnmount() {
    this.props.unloadResortDetail()
  }

  handleActivityTypeClickAndScroll = type => {
    this.toggleActivityType(type)
    if (this.aboveMap) {
      this.aboveMap.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  _openMail = (emailAddress) => {
    const subject = 'Richiesta Privileged Resort'
    const body = ''
    window.open(`mailto:${'reservation@privilegedgolf.com '}?subject=${subject}&body=${body}`, '_blanc')
  }

  toggleMoreInfo = (packId) => {
    this.setState((prevState) => {
      return { packId: prevState.packId !== packId  ? packId : null  }
    })
  }

  onClickWrite = () => {

  }

  render() {
    const { user, resort } = this.props

    console.log({resort})

    const packs = _.get(resort, 'packages', [])

    return <div className='golf-club-detail'>
      <Menu bgColor={'#1B3862'}/>

      <Fragment>
        <ResortDetailCover resort={resort}/>

        {/*<GolfClubDetailRelatedActivities />*/}

        {resort && <ResortDetailCollage resort={resort}/>}

        {/*<div style={{minHeight: 80, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
        {/*  <Button className="book-btn-custom" style={{cursor: 'auto', outline: 0}}>PACCHETTI IN ESCLUSIVA PER I SOCI PRIVILEGED</Button>*/}
        {/*</div>*/}

        <div className="card-wrapper">
          <h2 className="title">{t('packages for privileged members')} </h2>
          <div className="row container-cards">

            {_.map(packs, pack => {
              return (
                <div key={pack.title} className="col-12 col-md-6 col-lg-4 col-xl-3 card-wrapper-sponsor">
                  <div className="card-sponsor">
                    <img src={_.get(pack, 'image.thumbnail.medium', '')} className="sponsor-image"/>
                    <div className="card-text">
                      <div className="text-title">
                        {pack.title}
                        <img src={`/images/${this.state.packId === pack.id ? 'top' : 'down'}-arrow.png`} onClick={() => this.toggleMoreInfo(pack.id)} />
                      </div>
                      {this.state.packId === pack.id &&<div className="description">{pack.description}</div>}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>




        {/*Footer*/}
        {/*<div className='bottom-bg'>*/}
        {/*  <div style={{minHeight: 240, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
        {/*    <Button onClick={() => this._openMail(resort.emailAddress)} className="btn book-btn">PER INFORMAZIONI O PRENOTAZIONI CLICCA QUI</Button>*/}
        {/*  </div>*/}

        {/*  <div className='golfclub-play-overlay' style={{*/}
        {/*    bottom: user ? undefined : -5,*/}
        {/*  }}>*/}
        {/*    <img src="/images/player.png" alt="golf player" className='golf-player-overlay'/>*/}
        {/*    <img src="/images/golf-flag.png" alt="golf flag" className='golf-flag-overlay'/>*/}
        {/*  </div>*/}
        {/*</div>*/}


      </Fragment>

      <div className='d-flex flex-column justify-content-center align-items-center' style={{paddingTop: '3rem', paddingBottom: '3rem', backgroundColor: 'rgb(1, 22, 50)'}}>
        <h1 style={{color: 'white', marginBottom: '1rem', textAlign: 'center'}}>{t('for info or booking')}</h1>
        <a onClick={this.onClickWrite} className="btn btn-secondary background-secondary" style={{width: '10rem'}} href="mailto:reservation@privilegedgolf.com">{t('text us')}</a>
      </div>

      <Footer/>
    </div>;
  }
}

export default connect(
  state => ({
    user: getAuthUser(state),
    resort: getResortDetail(state),
  }),
  {

    loadResortDetail,
    unloadResortDetail
}
)(withScrollTop(ResortDetail))
