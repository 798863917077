import React, { PureComponent } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import './ReservedActivityModal.scss'
import {t} from "i18next";

export default class ReservedActivityModal extends PureComponent {
  render() {
    const { isOpen, toggle } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle} className='reserved-activity-modal' backdropClassName='backdrop-reserved-modal'>
        <ModalBody>
          <img src="/images/privileged-logo-gold.png" alt="privileged club logo" />
          <h4>{t('reservation quest sent')}</h4>
          <p className="text-white text-center">
            {t('for help contact our')}
          </p>
          <p className="text-white text-center">
            <img
              style={{ width: 40 }}
              className='mr-2'
              alt='mail icon' src='/images/contacts-mail.png' />
            <a className="text-white no-link-style" href="mailto:reservation@privilegedgolf.com">
              reservation@privilegedgolf.com
            </a>
          </p>
          <p className="text-white text-center">
            <img
              style={{ width: 40 }}
              className='mr-2'
              alt='phone icon' src='/images/contacts-phone.png' />
            <a className="text-white no-link-style" href="tel:01711712440">
              0171 - 1712440
            </a>
          </p>
          <button onClick={toggle} className='btn btn-block btn-secondary text-white mt-5'>OK</button>
        </ModalBody>
      </Modal>
    )
  }
}
