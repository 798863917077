import React from 'react'
import './HomeInstagram.scss'

// const fakeImages = [
//   {src: '/images/event-bg.png'},
//   {src: '/images/event-bg.png'},
//   {src: '/images/event-bg.png'},
//   {src: '/images/event-bg.png'},
//   {src: '/images/event-bg.png'},
// ]

class HomeInstagram extends React.PureComponent {
  render(){
    const { instagram } = this.props
    console.log({instagram})
    return (
      <div className="container-fluid py-5 homeinstagram">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2>Instagram</h2>
            <h4>#privilegedgolf</h4>
            <div className="homeinstagram-gallery">
              {instagram && instagram.data.slice(0, 5).map((post, i) => (
                <div key={post.id} className="homeinstagram-gallery-image-container">
                  <a href={post.link} className='no-link-style '>
                    <div
                      className="homeinstagram-gallery-image"
                      alt="golf Instagram"
                      style={{backgroundImage: `url(${post.media_url})`}}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// HomeInstagram.defaultProps = {
//   images: fakeImages,
// }

export default HomeInstagram
