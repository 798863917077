import React from 'react'
import get from 'lodash/get'
import './GolfClubDetailCover.scss'

class GolfClubDetailCover extends React.PureComponent {
  render(){
    const { club, id } = this.props
    const logo = get(club, 'logo.thumbnail.medium', '/images/privileged-logo-gold.png')
    const bg = get(club, 'mainPicture.full')
    // const background = bg
    //   ? `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${bg}')`
    //   : undefined
    return (
      <>
        <div
          className="golfclubdetail-cover"
          style={{backgroundColor: '#222', backgroundImage: bg ? `url(${bg})` : undefined}}
        >
          {club && <img src={logo} alt={`Golf club ${club.name} logo`}/>}
        </div>
        {club && <h1 className="text-center clubname font-700" id={id}>{club.name}</h1>}
      </>
    )
  }
}

export default GolfClubDetailCover
