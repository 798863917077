import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { GuestRoute, MaybeAuthRoute, AuthRoute } from 'eazy-auth'
import { Provider } from 'react-redux'
import store from './state'
import Home from './pages/Home'
import Contacts from './pages/Contacts'
import Login from './pages/Login'
import Recover from './pages/Recover'
import BecomeMember from './pages/BecomeMember'
import GolfClubDetail from './pages/GolfClubDetail'
import ActivityDetail from './pages/ActivityDetail'
import Sponsor from './pages/Sponsor'
import Conventions from './pages/Conventions'
import Albums from './pages/Albums'
import NotFound from './pages/NotFound'
import UserAccount from './pages/UserAccount'
import RegisterCard from './pages/RegisterCard'
import Events from './pages/Events'
import Cookies from './components/Cookies'
import ReactGA from 'react-ga'
import createHistory from 'history/createBrowserHistory'
import RenewsMember from "./pages/RenewsMember/RenewsMember";
import ResortDetail from "./pages/ResortDetail/ResortDetail";
import AlbumDetails from "./pages/AlbumDetails/AlbumDetails";
import { withTranslation } from 'react-i18next';

const history = createHistory()

if (process.env.NODE_ENV === 'production') {
  // integrate history \w Google Analytics
  ReactGA.initialize('UA-48978419-27');
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname + location.search })
    ReactGA.pageview(location.pathname + location.search)
  })
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <React.Fragment>
          <Switch>
            <MaybeAuthRoute path={`/`} exact component={Home} />
            <MaybeAuthRoute path={`/the-royal-diamond`} exact component={Events} />
            <Route path={`/contatti`} component={Contacts} />
            <GuestRoute path={`/login`} component={Login} />
            <GuestRoute path={`/recupera-password`} component={Recover} />
            <GuestRoute path={`/diventa-socio`} component={BecomeMember} />
            <GuestRoute path={`/registra-tessera`} component={RegisterCard} />
            <Route path={`/rinnova-tessera`} component={RenewsMember} />
            <Route path={`/club/:id`} component={GolfClubDetail} />
            <Route path={`/resort/:id`} component={ResortDetail} />
            <Route path={`/attivita/:id`} component={ActivityDetail} />
            <Route path={`/sponsor`} component={Sponsor} />
            <Route path={`/convenzioni`} component={Conventions} />
            <Route path={`/albums/:id`} component={AlbumDetails} />
            <Route path={`/albums`} component={Albums} />

            <AuthRoute path={`/user`} component={UserAccount} />
            <Redirect from="/index.html" to="/" />
            <Redirect from="/index.htm" to="/" />
            <Route component={NotFound} />
          </Switch>
          <Cookies/>
          </React.Fragment>
        </Router>

      </Provider>
    )
  }
}

export default withTranslation()(App);
