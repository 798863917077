import {API_URL, MASTER_TOKEN} from '../constants';

export const getAlbumList = async () => {
  try {
    const response = await fetch(`${API_URL}/albums?orderBy=order`, {
      headers: {
        'Authorization': `bearer ${MASTER_TOKEN}`
      }
    })
    console.log({response})
    const list = await response.json()
    console.log({list})
    return list
  } catch (e) {
    console.log({errorGetAlbumList: e})
  }
}

export const getAlbumDetail = async (albumId) => {
  try {
    const response = await fetch(`${API_URL}/albums/${albumId}`, {
      headers: {
        'Authorization': `bearer ${MASTER_TOKEN}`
      }
    })
    console.log({response})
    const albumDetail = await response.json()
    console.log({albumDetail})
    return albumDetail
  } catch (e) {
    console.log({errorGetAlbumList: e})
  }
}
