import React from 'react'
import get from 'lodash/get'
import './ResortDetailCover.scss'

class ResortDetailCover extends React.PureComponent {
  render(){
    const { resort } = this.props
    const logo = get(resort, 'logo.thumbnail.medium', '')
    const bg = get(resort, 'header.full')
    // const background = bg
    //   ? `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${bg}')`
    //   : undefined
    return (
      <>
        <div
          className="resortdetail-cover"
          style={{backgroundColor: '#222', backgroundImage: bg ? `url(${bg})` : undefined}}
        >
          {/*{logo ? <img src={logo} alt={`Golf club ${resort.name} logo`} /> : <div></div>}*/}
        </div>
        {resort && <h1 className="resort-name">{resort.name}</h1>}
      </>
    )
  }
}

export default ResortDetailCover
