import React from 'react'
import { connect } from 'react-redux'
import { getClubUserAccount } from '../../state/auth'
import { getItalianClubs, loadItalianClubs } from '../../state/clubs'
import Menu from '../../components/Menu'
import UserAccountMenu from '../../components/UserAccountMenu'
import UserAccountCard from '../../components/UserAccountCard'
import withScrollTop from '../../hocs/ScrollTop'
import UserAccountSettings from '../../components/UserAccountSettings'
import Footer from '../../components/Footer'
import LogoutModal from '../../components/LogoutModal'
import './UserAccount.scss'
import _ from 'lodash'
import moment from 'moment'

class UserAccount extends React.PureComponent {
  state = {
    showLogoutModal: false,
    expiryDate: false
  }

  componentDidMount() {
    const { user } = this.props;
    this.props.loadItalianClubs()

    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    }
    console.log({user: this.props.user})
    const expiryDate = moment(_.get(user, 'loyaltyCard.expiryDate', null)).diff(new Date(), 'days') < 0
    console.log({expiryDate})
    const renewAsked = localStorage.getItem('renewAsked');
    // alert(expiryDate.diff(new Date(), 'days')< 0)
    if (expiryDate && renewAsked !== 'true') {
      this.props.history.push({
        pathname: '/rinnova-tessera',
        state: { user }
      })
    }

    this.setState({expiryDate})
  }

  toggleShowLogoutModal = () => this.setState(prevState => ({
    showLogoutModal: !prevState.showLogoutModal,
  }))

  render() {
    const { user, italianClubs } = this.props
    console.log({user})
    return (
      <div className="useraccount">
        <div className="bg-primary text-white d-flex flex-column useraccount_heading">
          <Menu bgColor={'rgb(1, 22, 50)'}/>
          <div className="d-flex flex-row justify-content-lg-center justify-content-xl-evenly align-items-center useraccount_hbox">
            <UserAccountMenu
              onLogoutClick={this.toggleShowLogoutModal}
              user={user}
            />
            {user.loyaltyCard && <UserAccountCard
              card={user.loyaltyCard}
              membership={user.membership}
            />}
          </div>

          <button
            type='button'
            onClick={this.toggleShowLogoutModal}
            className='btn btn-secondary btn-sm align-self-end mr-5 ml-5 text-capitalize mb-5 pl-4 pr-4'>
            Logout
          </button>
          
        </div>
        <div ref={r => this.settingsSection = r}/>
        <UserAccountSettings user={user} clubs={italianClubs}/>
        <Footer />
        <LogoutModal
          isOpen={this.state.showLogoutModal}
          toggle={this.toggleShowLogoutModal}
        />
      </div>
    )
  }
}

export default connect(state => ({
  user: getClubUserAccount(state),
  italianClubs: getItalianClubs(state),
}), { loadItalianClubs })(withScrollTop(UserAccount))
