import React from 'react'
import get from 'lodash/get'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { labelFromEventType } from '../../utils'

const EventPoupup = ({ marker }) => (
  <div>
    {marker.logo && <img
      alt='club logo'
      height={50}
      className='mb-2'
      src={get(marker, 'logo.thumbnail.squared')}
    />}
    <h5 className="text-secondary">
    <Link to={`/club/${marker._id}?royalDiamond=1`} className="text-secondary">
      {marker.name}
    </Link>
    </h5>
    {marker.events.map(event => (
      <div key={event._id} className='d-flex text-white py-2'>
        <div className='mr-2'>{moment(event.date).format('DD/MM/YYYY')}</div>
        <div>{labelFromEventType(event.type)}</div>
      </div>
    ))}
  </div>
)
export default EventPoupup
