import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { FieldInput, FieldDatepicker } from '../Form/Fields'
import { required, mustBookingDate, mustHour, mustFutureBookingHour, oneFacility } from '../Form/validation'
import Facilities from './Facilities'
import './GolfClubDetailFormModal.scss'
import get from 'lodash/get'
import moment from 'moment';
import {normalizeDate} from '../GolfClubDetailModal/GolfClubDetailModal';
import {t} from "i18next";

const FacilitiesField = ({ input, facilities }) => {
  const value = Array.isArray(input.value) ? input.value : []
  return <Facilities
    onClick={facility => {
      if (value.indexOf(facility.name) === -1) {
        input.onChange(value.concat(facility.name))
      } else {
        input.onChange(value.filter(name => name !== facility.name))
      }
    }}
    selected={value}
    facilities={facilities}
  />
}

class GolfClubDetailFormModal extends React.PureComponent {

  state = {
    isChangedDate: false,
  }

  componentDidMount() {
    const selectedDate = localStorage.getItem('selectedDate')
    console.log({selectedDateFromStorage: selectedDate})
    if (selectedDate) {
      this.props.onChangeDate(selectedDate)
      localStorage.setItem('selectedDate', '')
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    console.log({nextProps})
    if (nextProps.date) {
      this.props.change('dateTimeStart', moment(nextProps.date).format('DD/MM/YYYY'))
    }
  }

  render(){
    const { handleSubmit, facilities, club, error, invalid, submitting, onUndo, date, onChangeDate, change } = this.props
    const fareConditions = get(club, 'fareConditions')
    console.log({change})

    console.log({date})

    return (
      <div className="golfclubdetail-form-modal container">
        <div className="row">
          <div className="col-lg-1 col-md-1" />
          <div className="col-md-10 col-sm-12 p-0">
            <div className="w-100 text-center text-white">
              <h3>{t('book services')}</h3>
            </div>
            <div className="golfclubdetail-form-container">
              <small>{t('data field obligatory')}</small>
              <form onSubmit={handleSubmit}>
                <div className='form-body'>
                  <Field
                    emptyClassName='text-placeholder'
                    labelProps={{
                      className: 'golfclubdetail-form-label',
                    }}
                    row
                    fullWidth
                    colLabel={3}
                    colInput={9}
                    name='dateTimeStart'
                    placeholder="gg/mm/aaaa"
                    label={<span>{t('date')}<sup>*</sup></span>}
                    component={FieldDatepicker}
                    validate={[required, mustBookingDate]}
                    onChange={(newDate) => onChangeDate(normalizeDate(newDate))}
                    value={date}
                  />
                  <div className='row'>
                    <div className='col-sm-3 golfclubdetail-form-label'>{t('preferred time')}</div>
                    <div className='col-sm-9'>
                      <Field
                        placeholder='hh:mm'
                        type='time'
                        name='bookingTime'
                        component={FieldInput}
                        validate={[mustHour, mustFutureBookingHour]}
                      />
                    </div>
                  </div>
                  {/* <Field
                    labelProps={{
                      className: 'golfclubdetail-form-label',
                    }}
                    colLabel={3}
                    colInput={9}
                    label='N° Persone'
                    name='numParticipants'
                    component={FieldGridInput}
                    validate={[required, mustIntPositive]}
                  /> */}
                  <div className='row'>
                    <div className='col-sm-12'>
                      <Field
                        placeholder='Note'
                        name='bookingNote'
                        type='textarea'
                        rows={3}
                        component={FieldInput}
                      />
                    </div>
                  </div>
                  <div className='text-white text-center py-3'>{t('select services you want')}</div>
                  <Field
                    name='facilities'
                    component={FacilitiesField}
                    facilities={facilities}
                    validate={[oneFacility]}
                  />
                </div>
                {!!fareConditions && <div className="p-2">
                  <small>
                    {fareConditions}
                  </small>
                </div>}

                <div className='d-flex flex-column align-items-center justify-content-center mt-4'>
                  <button
                    disabled={invalid || submitting}
                    type="submit" className="btn btn-block btn-prnota">{t('book')}</button>
                  <button
                    onClick={onUndo}
                    type="button" className="btn btn-block btn-prnota">{t('cancel')}</button>
                </div>
                {error && <div className='alert alert-danger mt-2'>
                  {error}
                </div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'reserveClub',
})(GolfClubDetailFormModal)
