import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import find from 'lodash/find'
import get from 'lodash/get'
import queryString from 'query-string'
import {
  getPlansCard,
  loadPlans,
} from '../../state/plans'
import { getItalianClubs, loadItalianClubs } from '../../state/clubs'
import {
  obtainPaymentTokenRenew,
  clearPaymentTokenRenew,
  performPaymentRenew,
  isLoadingPaymentTokenRenew,
  getPaymentTokenErrorRenew,
  getPaymentDataRenew,
} from '../../state/payment'
import Menu from '../../components/Menu'
import BecomeMemberCardsList from '../../components/BecomeMemberCardsList'
import RegistrationDone from '../../components/RegistrationDone'
import RenewsMemberForms from '../../components/RenewsMemberForm'
import Footer from '../../components/Footer'
import withScrollTop from '../../hocs/ScrollTop'
import './RenewsMember.scss'
import _ from 'lodash'
import moment from 'moment'
import RenewsMemberForm from "../../components/RenewsMemberForm/RenewsMemberForm";

class RenewsMember extends React.Component {
  state = {
    user: null,
  }

  componentDidMount() {
    const user = _.get(this.props, 'location.state.user', null) || JSON.parse(localStorage.getItem('user'))
    // if (!user) {
    //   this.props.history.push('/login')
    //   return;
    // }
    localStorage.setItem('renewAsked', 'true')
    this.setState({user})
    this.props.loadPlans()
    this.props.loadItalianClubs()
  }

  componentWillUnmount() {
    this.props.clearPaymentTokenRenew()
  }

  handleChangeMembership = membership => {
    this.props.history.push(`/diventa-socio?membership=${membership}`)
    this.formSection.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  render() {
    const {
      location,
      plans,
      italianClubs,
      paymentData,
      obtainPaymentTokenRenew,
      loadingPaymentToken,
      paymentDataError,
      performPaymentRenew,
    } = this.props
    const { user, newUser } = this.state

    // Retrieve selected membership plan
    let membership = null
    let plan = null
    if (plans.length > 0 && user) {
      plans.forEach(singlePlan => {
        if (singlePlan.id === _.get(user, 'loyaltyCard.membershipPlan.id', '')) {
          plan = singlePlan;
        }
      })
    }

    return <div className='become-member'>
      <Menu  bgColor={'rgb(1, 22, 50)'}/>
      {newUser && <div>
        <RegistrationDone message={'Acquisto andato a buon fine'} />
      </div>}
      {!newUser && <Fragment>

        <div ref={r => this.formSection = r} />
        {plan && user && <RenewsMemberForms
          // FOR A DEBUG EASY LIFE
          initialValues={{
            favoriteClubId: user.favoriteClubId,
            name: user.name,
            surname: user.surname,
            email: user.email,
            emailConfirm: user.email,
            password: '',
            passwordConfirm: '',
            birthDate: moment(user.birthDate).format('DD/MM/YYYY'),
            fiscalCode: user.fiscalCode,
            phoneNumber: user.phoneNumber,
            address: {
              city: _.get(user, 'address.city', ''),
              municipality: _.get(user, 'address.municipality', ''),
              zipCode: _.get(user, 'address.zipCode', ''),
              address: _.get(user, 'address.address', ''),
            },
          }}
          plan={plan}
          clubs={italianClubs}
          obtainPaymentTokenRenew={obtainPaymentTokenRenew}
          loadingPaymentToken={loadingPaymentToken}
          paymentData={paymentData}
          paymentDataError={paymentDataError}
          performPaymentRenew={performPaymentRenew}
          onSubmitSuccess={checkoutResult => {
            this.setState({
              newUser: checkoutResult.data,
            }, () => {
              window.scrollTo(0, 0)
            })
          }}
        />}
      </Fragment>}
      <Footer/>
    </div>
  }
}

export default connect(state => ({
  plans: getPlansCard(state),
  italianClubs: getItalianClubs(state),
  paymentData: getPaymentDataRenew(state),
  loadingPaymentToken: isLoadingPaymentTokenRenew(state),
  paymentDataError: getPaymentTokenErrorRenew(state),
}), {
  loadPlans,
  loadItalianClubs,
  obtainPaymentTokenRenew,
  clearPaymentTokenRenew,
  performPaymentRenew,
})(withScrollTop(RenewsMember))
