import React, { PureComponent } from 'react'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import {
  loadListActivities,
  unloadListActivities,
  loadMoreListActivities,
  getListActivities,
  getListActivitiesCount,
  getNextParamasListActivitiesCount,
  isLoadingListActivities,
} from '../../state/activities'
import HomeItemCard from '../HomeItemCard'
import regioni from '../../regioni.json'
import province from '../../province.json'
import './HomeActivitiesList.scss'

class HomeActivitiesList extends PureComponent {

  componentDidMount() {
    this.loadActivites()
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.props.unloadListActivities()
      this.loadActivites()
    }
  }

  componentWillUnmount() {
    this.props.unloadListActivities()
  }

  loadActivites = () => {
    const { provincia, regione, search } = this.props
    this.props.loadListActivities({
      type: this.props.type,
      region: regione === null ? '' : regione.nome,
      province: provincia === null ? '' : provincia.sigla_automobilistica.toLowerCase(),
      text: search,
      pageOffset: 1,
    })
  }

  loadMoreActivites = () => {
    const { nextParamas } = this.props
    this.props.loadMoreListActivities(nextParamas)
  }

  debouncedLoadActivities = debounce(this.loadActivites, 200)

  render() {
    const { regione, provincia, search } = this.props
    const { nextParamas, activities, loading } = this.props
    console.log('list activities', activities)
    return (
      <div className='px-lg-5 px-md-4 px-sm-2 home-activities-list mb-5'>

        <div className='row m-0 p-0'>
          {activities && activities.map(activity => (
            <HomeItemCard
              item={activity}
              type='activity'
              key={activity.Id}
            />
          ))}
          {activities && activities.length === 0 && !loading && (
            <div className='text-center text-primary w-100 mb-4 mt-4'>
              <h5>Nessun risultato disponibile.</h5>
            </div>
          )}
        </div>
        <div className='text-center'>
          {nextParamas && <button
            className='btn btn-light'
            disabled={loading}
            onClick={this.loadMoreActivites}>Carica altri</button>}
        </div>

      </div>
    )
  }
}

export default connect(state => ({
  activities: getListActivities(state),
  nextParamas: getNextParamasListActivitiesCount(state),
  count: getListActivitiesCount(state),
  loading: isLoadingListActivities(state),
}), {
  loadListActivities,
  loadMoreListActivities,
  unloadListActivities,
})(HomeActivitiesList)
