import React from 'react'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import './Conventions.scss'
import withScrollTop from '../../hocs/ScrollTop'
import _ from 'lodash'
import {connect} from "react-redux";
import {
  getConventions,
  loadConventions,
  getConventionsHeader,
  loadConventionsHeader
} from "../../state/conventions";
import { getAuthUser } from 'eazy-auth'
import {isUserExpired} from "../../constants";
import {t} from "i18next";


class Conventions extends React.Component {

  state = {
    conventionId: null
  }


  componentDidMount() {
    this.props.loadConventions()
    this.props.loadConventionsHeader()
  }

  _openSite = link => {

  }

  toggleMoreInfo = (conventionId) => {
    this.setState((prevState) => {
      return { conventionId: prevState.conventionId !== conventionId  ? conventionId : null  }
    })
  }

  render(){
    const { conventions: allConventions, conventionsHeader, user } = this.props;
    console.log({state: this.state})
    console.log({props: this.props})
    const conventions = (allConventions || []).filter(conv => !conv.isHomeVisible)


    console.log({conventions})
    console.log({conventionsHeader})


    return <div className="convention">
      <Menu bgColor={'rgb(1, 22, 50)'}/>
      <div className="sponsor-header">
        <img src={conventionsHeader ? conventionsHeader.full : ''} className={'header-image'} />
      </div>

      <div className="card-wrapper">
        <h1 className="title font-700" style={{fontSize: 60}}>{t('conventions')} Privileged</h1>
        <div className={`row container-cards ${this.state.conventionId ? 'align-start' : ''}`}>
          {_.map(conventions, convention => {
            return (
              <div key={convention.id} className="col-12 col-sm-6 col-md-4 col-lg-3 card-wrapper-sponsor">
                <a href={convention.permalink} target="_blank" className="card-link">
                  <div className="card-sponsor">
                    <img src={_.get(convention, 'logo.thumbnail.medium', '')} className="sponsor-image"/>
                    <div className="card-text">
                      <div className="text-title">{convention.name}</div>
                      {!isUserExpired(user) && (
                        <>
                          {this.state.conventionId === convention.id && <div className="description">{convention.description}</div>}
                          <div className="more-info-wrapper">
                        <span onClick={() => this.toggleMoreInfo(convention.id)}>
                          Info
                          <img src={`/images/${this.state.conventionId === convention.id ? 'top' : 'down'}-arrow.png`} />
                        </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  }
}

export default connect(state => ({
  conventions: getConventions(state),
  conventionsHeader: getConventionsHeader(state),
  user: getAuthUser(state)
}), {
  loadConventions,
  loadConventionsHeader,
})(withScrollTop(Conventions))
