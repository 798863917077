import request from 'superagent'
import { rj } from 'redux-rocketjump'
import { takeEveryAndCancel } from 'redux-rocketjump/effects'
import rjPosArgs from 'redux-rocketjump/plugins/positionalArgs'
import { select, put } from 'redux-saga/effects'
import { getAuthUser, updateUser } from 'eazy-auth'
import { authApiCall, withToken } from './auth'
import { API_URL } from '../constants'

export const UPDATE_AVATAR = 'UPDATE_AVATAR'

export const {
  actions: {
    load: updateAvatar,
  },
  selectors: {
    isLoading: isUpdatingAvatar,
  },
  reducer,
  saga,
} = rj(rjPosArgs(), {
  type: UPDATE_AVATAR,
  state: 'avatar',
  callApi: authApiCall,
  takeEffect: takeEveryAndCancel,
  dataReducer: () => null,
  apiExtraParams: function *(params) {
    const user = yield select(getAuthUser)
    return [...params, user]
  },
  successEffect: function *(user) {
    yield put(updateUser(user))
  },
  api: t => (file, user) => {
    return withToken(t, request.post(`${API_URL}/uploads`))
      .attach('file', file)
      .then(({ body }) => {
        const { id } = body
        return withToken(t, request.put(`${API_URL}/users/${user.id}`))
          .send({
            ...user,
            profilePictureId: id,
          })
          .then(({ body }) => body)
      })
  }
})()
