import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import find from 'lodash/find'
import get from 'lodash/get'
import queryString from 'query-string'
import {
  getPlansCard,
  loadPlans,
} from '../../state/plans'
import { getItalianClubs, loadItalianClubs } from '../../state/clubs'
import {
  obtainPaymentToken,
  clearPaymentToken,
  performPayment,
  isLoadingPaymentToken,
  getPaymentTokenError,
  getPaymentData,
} from '../../state/payment'
import Menu from '../../components/Menu'
import BecomeMemberCardsList from '../../components/BecomeMemberCardsList'
import RegistrationDone from '../../components/RegistrationDone'
import BecomeMemberForm from '../../components/BecomeMemberForm'
import Footer from '../../components/Footer'
import withScrollTop from '../../hocs/ScrollTop'
import './BecomeMember.scss'

class BecomeMember extends React.Component {
  state = {
    newUser: null,
  }

  componentDidMount() {
    this.props.loadPlans()
    this.props.loadItalianClubs()
  }

  componentWillUnmount() {
    this.props.clearPaymentToken()
  }

  handleChangeMembership = membership => {
    this.props.history.push(`/diventa-socio?membership=${membership}`)
    this.formSection.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  render() {
    const {
      location,
      plans,
      italianClubs,
      paymentData,
      obtainPaymentToken,
      loadingPaymentToken,
      paymentDataError,
      performPayment,
    } = this.props
    const { newUser } = this.state

    // Retrieve selected membership plan
    const queryParams = queryString.parse(location.search)
    let membership = null
    let plan = null
    if (plans.length > 0) {
      membership = get(queryParams, 'membership', plans[0].slug)
      plan = find(plans, { slug: membership })
      if (!plan) {
        membership = plans[0].slug
      }
    }

    return <div className='become-member'>
      <Menu  bgColor={'rgb(1, 22, 50)'}/>
      {newUser && <div>
        <RegistrationDone message={'Acquisto andato a buon fine'} />
      </div>}
      {!newUser && <Fragment>
        <BecomeMemberCardsList
          cards={plans}
          membership={membership}
          onChangeMembership={this.handleChangeMembership}
        />
        <div ref={r => this.formSection = r} />
        {plan && <BecomeMemberForm
          // FOR A DEBUG EASY LIFE
          // initialValues={{
          //   name: 'Giovanni',
          //   surname: 'Fumello',
          //   email: 'fumagalli.gf3@gmail.com',
          //   emailConfirm: 'fumagalli.gf3@gmail.com',
          //   password: 'Fumello',
          //   passwordConfirm: 'Fumello',
          //   birthDate: '26/09/1993',
          //   fiscalCode: '23',
          //   phoneNumber: '3384719566',
          //   address: {
          //     city: 'Olginate',
          //     municipality: 'Lecco',
          //     zipCode: '23854',
          //     address: 'Via Santa Maria 23',
          //   },
          // }}
          plan={plan}
          clubs={italianClubs}
          obtainPaymentToken={obtainPaymentToken}
          loadingPaymentToken={loadingPaymentToken}
          paymentData={paymentData}
          paymentDataError={paymentDataError}
          performPayment={performPayment}
          onSubmitSuccess={checkoutResult => {
            this.setState({
              newUser: checkoutResult.data,
            }, () => {
              window.scrollTo(0, 0)
            })
          }}
        />}
      </Fragment>}
      <Footer/>
    </div>
  }
}

export default connect(state => ({
  plans: getPlansCard(state),
  italianClubs: getItalianClubs(state),
  paymentData: getPaymentData(state),
  loadingPaymentToken: isLoadingPaymentToken(state),
  paymentDataError: getPaymentTokenError(state),
}), {
  loadPlans,
  loadItalianClubs,
  obtainPaymentToken,
  clearPaymentToken,
  performPayment,
})(withScrollTop(BecomeMember))
