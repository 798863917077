import React from 'react'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import withScrollTop from '../../hocs/ScrollTop'
import './NotFound.scss'

class NotFound extends React.Component {
  render(){
    return <div className='not-found'>
      <Menu bgColor={'rgb(1, 22, 50)'} />
      <div className='not-found-body'>
        <h1>Page Not Found</h1>
      </div>
      <Footer/>
    </div>
  }
}

export default withScrollTop(NotFound)
