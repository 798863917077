import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import words from 'lodash/words'
import capitalize from 'lodash/capitalize'
import {getUploadedFile} from "./state/general";
import React from 'react'

export const padTime = (time) => {
  return time.indexOf(':') === -1
    ? `${time}:00`
    : time
}

export const isDesktopScreen = () => {
  return window.innerWidth > 768
}

export const groupByLatLng = markers => {
  const byCoords = groupBy(markers, marker => `${marker.position.lat}|${marker.position.lng}`)
  const markersGroupped = map(byCoords, groupMarkers => ({
    ...groupMarkers[0],
    ids: groupMarkers.map(m => m.id),
  }))
  // console.log(byCoords, markersGroupped)
  return markersGroupped
}

const MAGIC_TYPES = {
  PrivilegedTour2020: 'Privileged Golf Tour',
  silverRace: 'The Royal Diamond Silver Race',
  goldRace: 'The Royal Diamond Gold Race',
  TrialWGleague: 'Road to Augusta Masters Trial',
  WGLeagueF: 'Road to Augusta Masters Final',
  AmatOpen: 'Amateurs Open',
  WAGCTour: 'WAGC Tour',
  WAGCqs: 'WAGC Qualifying Series',
  WACGMatchP: 'WAGC Match Play',
  WACGMatchPFin: 'WAGC Match Play Final',
  WAGCNatFin: 'WAGC NATIONAL FINAL',
  WAGCWorldF: 'WACG WORLD FINAL',
}

const MAGIC_TYPES_TWO_LINES = {
  PrivilegedTour2020: 'Privileged<br/>Golf Tour',
  silverRace: 'The Royal Diamond<br/>Silver Race',
  goldRace: 'The Royal Diamond<br/>Gold Race',
  TrialWGleague: 'Road to Augusta<br/>Masters Trial',
  WGLeagueF: 'Road to Augusta<br/>Masters Final',
  AmatOpen: 'Amateurs<br/>Open',
  WAGCTour: 'WAGC<br/>Tour',
  WAGCqs: 'WAGC<br/>Qualifying Series',
  WACGMatchP: 'WAGC<br/>Match Play',
  WACGMatchPFin: 'WAGC<br/>Match Play Final',
  WAGCNatFin: 'WAGC<br/>NATIONAL FINAL',
  WAGCWorldF: 'WACG<br/>WORLD FINAL',
}
export const labelFromEventType = type => {
  if (MAGIC_TYPES[type]) {
    return MAGIC_TYPES[type]
  }
  return words(type).map(a => capitalize(a)).join(' ')
}

export const labelFromEventTypeTwoLines = type => {
  if (MAGIC_TYPES_TWO_LINES[type]) {
    return MAGIC_TYPES_TWO_LINES[type]
  }
  return words(type).map(a => capitalize(a)).join(' ')
}

export const openTermsCondition = async () => {
  const file = await getUploadedFile('termsAndConditions')
  if (file) {
    window.open(file.full, '_blanc')
  }
}

export const openCookiePolicy = async () => {
  const file = await getUploadedFile('cookiePolicy')
  if (file) {
    window.open(file.full, '_blanc')
  }
}

export const openPrivacyPolicy = async () => {
  const file = await getUploadedFile('privacyPolicy')
  if (file) {
    window.open(file.full, '_blanc')
  }
}

export const printTextBreakLine = (text) => {
  return text.split('<br/>').map(function(item) {
    return (<>{item}<br/></>)
  })
}
