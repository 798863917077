import React from 'react'
import GolfPopup from './GolfPopup'
import ActivityPopup from './ActivityPopup'
import EventPopup from './EventPopup'
import ResortPopup from './ResortPopup'

const TypedPopup = ({ marker, ...passProps }) => {
  console.log({marker, passProps})
  switch (marker.type) {
    case 'event':
      return <EventPopup marker={marker} {...passProps} />
    case 'club':
      return <GolfPopup marker={marker} {...passProps} />
    case 'activity':
      return <ActivityPopup marker={marker} {...passProps} />
    case 'resort':
      return <ResortPopup marker={marker} {...passProps} />
    default:
      throw new Error(`Popup bad marker type given: ${marker.type}`)
  }
}
export default TypedPopup
