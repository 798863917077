import React, { Fragment } from 'react'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import qs from 'query-string'
import { SubmissionError } from 'redux-form'
import { getAuthUser } from 'eazy-auth'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import GolfClubDetailCover from '../../components/GolfClubDetailCover'
import GolfClubDetailIconServices from '../../components/GolfClubDetailIconServices'
import GolfClubDetailRelatedActivities from '../../components/GolfClubDetailRelatedActivities'
// import GolfClubDetailContacts from '../../components/GolfClubDetailContacts'
// import GolfMap from '../../components/GolfMap'
import GolfClubDetailServices from '../../components/GolfClubDetailServices'
// import GolfClubDetailTimeTable from '../../components/GolfClubDetailTimeTable'
import GolfClubDetailLowerInfo from '../../components/GolfClubDetailLowerInfo'
// import GolfClubDetailForm from '../../components/GolfClubDetailForm'
import GolfClubDetailModal from '../../components/GolfClubDetailModal'
import EventReservationSuccess from '../../components/EventReservationSuccess'
// import GolfClubDetailRelatedPrenotation from '../../components/GolfClubDetailRelatedPrenotation'
import ActivityTypes from '../../components/ActivityTypes'
import EventsList from '../../components/EventsList'
import MarkerMap, { TypedPopup } from '../../components/MarkerMap'
import {loadClub, getClub, unloadClub, reserveClub, loadClubFacilities, getClubFacilities} from '../../state/clubs'
import { padTime, groupByLatLng } from '../../utils'
import {
  loadActivites,
  unloadActivities,
  getActivityTypesCounts,
  getActivities,
  clearAllActivitiesById,
} from '../../state/activities'
import golfClubPinIcon from '../../components/MarkerMap/golf-club-pin.png'
import withScrollTop from '../../hocs/ScrollTop'
import './GolfClubDetail.scss'
import moment from 'moment';
import _ from 'lodash';
import {t} from "i18next";

const ACTIVITY_ORDER = {
  Hotel: 0,
  'Privileged Golf Tour': 1,
  Fitness: 2,
  Ristoranti: 3,
  Wellness: 4,
}


const ReservationSuccess = ({ markers, club, selected, toggleActivityType, activityTypes }) => (
  <div className='golf-club-reservation-success'>
    <div className='top-cover'>
      <img
        alt='club logo'
        src={'/images/privileged-logo-gold-big.png'}
      />
      <div className='container'>
        <p>
          {t('your booking was successful')}
        </p>
        <div className='coursive text-white'>{t('Good Game!')}</div>
      </div>
    </div>
    <div className='near-activities'>
      <div className='text-center text-primary mb-5'>
        <h4>{t('want to book more?')}</h4>
        <div className='golfclub-reserved-overlay'>
          <img src="/images/player.png" alt="golf player" className='golf-player-overlay' />
          <img src="/images/golf-flag.png" alt="golf flag" className='golf-flag-overlay' />
        </div>
      </div>
      <ActivityTypes
        selected={selected}
        onClick={toggleActivityType}
        activityTypes={activityTypes}
      />
    </div>
    <MarkerMap
      defaultZoom={9}
      // style={{ height: 300 }}
      markers={markers}
      popupComponent={TypedPopup}
      center={club ? {
        lat: club.coordinates[1],
        lng: club.coordinates[0],
      } : undefined}
      options={{
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        draggable: false,
        scrollwheel: false,
        panControl: false,
      }}
    />
  </div>
)

class GolfClubDetail extends React.Component {
  state = {
    selectedType: null,
    reservationSuccess: false,
    showingModal: false,
    selectedDate: ''
  }

  componentDidMount(){
    const { match, location } = this.props
    const { params } = match
    const searchParams = qs.parse(location.search)

    this.props.loadClub(params.id)
    console.log('load FAC')
    console.log({searchParams})
    if (searchParams.date) {
      this.onChangeDateFacilities(moment(searchParams.date, 'DD-MM-YYYY').toISOString(), params.id)
    }
    const query = qs.parse(this.props.history.location.search)
    if(query.reservationModal){
      this.setState({showModal:true})
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.club !== prevProps.club && this.props.club) {
      const { club } = this.props
      this.props.loadActivites({
        lat: club.coordinates[1],
        lng: club.coordinates[0],
        distance: 100000,
      })
    }
    if (this.props.activities !== prevProps.activities && this.props.activities) {
      this.setState({selectedType: 51})
    }

    const query = qs.parse(this.props.history.location.search)
    if(this.royalDiamondContainer && query.royalDiamond && this.props.club && !prevProps.club){
      this.royalDiamondContainer.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  componentWillUnmount() {
    this.props.unloadClub()
    this.props.unloadActivities()
    this.props.clearAllActivitiesById()
  }

  toggleShowModal = () => {
    localStorage.setItem('selectedDate', this.state.selectedDate)
    this.setState({showModal: !this.state.showModal})
  }

  toggleActivityType = type => {
    this.setState({
      selectedType: type.id,
    })
  }

  handleActivityTypeClickAndScroll = type => {
    this.toggleActivityType(type)
    if (this.aboveMap) {
      this.aboveMap.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  getMarkers = memoize((club, activities, activityTypes, selectedType) => {
    const typeById = keyBy(activityTypes, 'id')
    let markers = (activities === null || selectedType === null)
      ? []
      : groupByLatLng(get(activities, selectedType, []).map(activity => ({
        ...activity,
        id: activity.Id,
        type: 'activity',
        icon: typeById[selectedType].markerIcon,
        position: {
          lat: +activity.Geo.Lat.replace(',', '.'),
          lng: +activity.Geo.Long.replace(',', '.')
        }
      })))

    if (club) {
      markers.push({
        ...club,
        icon: golfClubPinIcon,
        type: 'club',
        description: 'ciao',
        position: {lat: club.coordinates[1], lng: club.coordinates[0]}
      })
    }

    return markers
  })

  getActivityTypesWithEvents = memoize((activityTypes, club) => {
    let eventsCount = null
    if (club) {
      eventsCount = club.events.length
    }

    return activityTypes.concat({
      id: 23,
      label: 'Privileged Golf Tour',
      image: '/images/icona-diamond.png',
      count: eventsCount
    }).sort((a, b) => ACTIVITY_ORDER[a.label] - ACTIVITY_ORDER[b.label])

  })

  onChangeDateFacilities = (newDate, id) => {
    const { match, club } = this.props
    const { params } = match
    this.props.loadClubFacilities(id || club._id, newDate)
    this.setState({selectedDate: newDate})
  }

  render() {
    const {club, activityTypes, activities, user, reserveClub, clubFacilities } = this.props
    const { selectedType, showModal, selectedDate } = this.state
    // const timeTable = get(club, 'timetable[0].times')
    const selected = selectedType ? { [selectedType]: true } : {}
    const markers = this.getMarkers(club, activities, activityTypes, selectedType)
    const activityTypesWithEvents = this.getActivityTypesWithEvents(activityTypes, club)

    console.log({club})
    console.log({selectedDate})

    const facilitiesSecond = _.get(clubFacilities, 'facilities', [])
    const facilitiesBase = _.get(club, 'facilities', [])
    const facilities = facilitiesSecond.length ? facilitiesSecond : facilitiesBase
    // console.log(activities, activityTypes, markers)


    return <div className='golf-club-detail'>
      <Menu bgColor={'rgb(1, 22, 50)'} />
      {this.state.reservationSuccess && <ReservationSuccess
        markers={markers}
        club={club}
        selected={selected}
        toggleActivityType={this.toggleActivityType}
        activityTypes={activityTypes}
      />}
      {this.state.eventReservationSuccess && <EventReservationSuccess />}
      {!(this.state.eventReservationSuccess || this.state.reservationSuccess) && <Fragment>
        <GolfClubDetailCover club={club} id="reservationScroll"/>
        <h2 className="mt-3 font-700" style={{ textAlign: 'center' }}>{t('book our exclusives')}</h2>
        <GolfClubDetailIconServices
          reserve={this.toggleShowModal}
          services={facilities}
          onChangeDate={this.onChangeDateFacilities}
          date={selectedDate}
        />

        {/*<div className='golfclub-jacket-border'/>*/}

        <div className="neighboring-activities">
          <h2 style={{fontWeight: '700', fontSize: 40}}>{t('activity close')}{club?.name}</h2>
          <div className="neighboring-activities-activities">
            <ActivityTypes
              className='mb-2 pt-3'
              selected={selected}
              onClick={this.toggleActivityType}
              activityTypes={activityTypesWithEvents}
              smallerContent
              fiveActivities
            />
          </div>

          <div className='text-center text-white px-2 p-md-x-0'>
            <span className='attivita-limitrofe-text-map' style={{fontWeight: '400'}}>
              {t('want to book more?')} {t('select the category and pin on map')}
            </span>
          </div>
        </div>

        <div ref={r => this.aboveMap = r} />
        <MarkerMap
          defaultZoom={9}
          // style={{ height: 300 }}
          markers={markers}
          popupComponent={TypedPopup}
          center={club ? {
            lat: club.coordinates[1],
            lng: club.coordinates[0],
          } : undefined}
          options={{
            streetViewControl: false,
            // zoomControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            // draggable: false,
            // scrollwheel: false,
            // panControl: false,
          }}
        />

        {/*
          In passato carousel loghi ... ora è solo un pezzo di erba
        */}
        {/*<GolfClubDetailRelatedActivities />*/}

        {/* {club && <GolfClubDetailContacts club={club} />} */}

        {club && <GolfClubDetailServices club={club} reserve={() => {
          const serviceScroll = document.getElementById('reservationScroll')
          serviceScroll.scrollIntoView({ behavior: 'smooth' })
        }}/>}

        <div ref={r => this.royalDiamondContainer = r}>
        {club && club.events && !!club.events.length && <div className="scheduled-race">
          <h3 className='text-secondary text-center font-700'>Privileged Golf Tour {club.name}</h3>
          <p className='text-white text-center font-300'>{t('calendar next races')}</p>
          <EventsList
            // Handler called when event success booked
            onEventBooked={() => {
              this.setState({
                eventReservationSuccess: true,
              })
            }}
            className='container'
            events={club.events}
            club={club}
            layout="grid"
          />
        </div>}
        </div>

        <div>
          {/* {timeTable && <GolfClubDetailTimeTable timeTable={timeTable} />} */}
          {club && <GolfClubDetailLowerInfo club={club}/>}

          <GolfClubDetailModal
            user={user}
            club={club}
            facilities={facilities}
            date={selectedDate}
            onChangeDate={this.onChangeDateFacilities}
            onSubmit={reservation => {
              return reserveClub({
                ...reservation,
                id: club.id,
                // Append :00 to hour such 12 -> 12:00
                bookingTime: padTime(reservation.bookingTime),
                numParticipants: parseInt(reservation.numParticipants),
              }).catch(e => {
                throw new SubmissionError({
                  _error: get(e, 'response.body.error', t('error while booking'))
                })
              })
            }}
            onSubmitSuccess={() => {
              this.setState({
                reservationSuccess: true,
                selectedType: null,
              }, () => {
                window.scrollTo(0, 0)
              })
            }}
            toggle={this.toggleShowModal}
            isOpen={showModal}/>

          {/* {user && <GolfClubDetailForm
            facilities={get(club, 'facilities', [])}
            onSubmit={reservation => {
              return reserveClub({
                ...reservation,
                id: club.id,
                // Append :00 to hour such 12 -> 12:00
                bookingTime: padTime(reservation.bookingTime),
                numParticipants: parseInt(reservation.numParticipants),
              }).catch(e => {
                throw new SubmissionError({
                  _error: get(e, 'response.body.error', 'Errore durante la prenotazione')
                })
              })
            }}
            onSubmitSuccess={() => {
              this.setState({
                reservationSuccess: true,
                selectedType: null,
              }, () => {
                window.scrollTo(0, 0)
              })
            }}
          />} */}
        </div>

        {/* <GolfClubDetailRelatedPrenotation
          onClick={this.handleActivityTypeClickAndScroll}
          activityTypes={activityTypes}
        /> */}
      </Fragment>}

      <Footer/>
    </div>
  }
}

export default connect(
  state => ({
    user: getAuthUser(state),
    club: getClub(state),
    clubFacilities: getClubFacilities(state),
    activities: getActivities(state),
    activityTypes: getActivityTypesCounts(state),
  }),
  {
    unloadClub,
    loadClubFacilities,
    loadClub,
    loadActivites,
    unloadActivities,
    clearAllActivitiesById,
    reserveClub,
  }
)(withScrollTop(GolfClubDetail))
