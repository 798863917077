import React from 'react'
import get from 'lodash/get'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { labelFromEventType } from '../../utils'
import _ from 'lodash'

const ResortPoupup = ({ marker }) => (
  <div>
    {marker.header && <img
      alt='club logo'
      height={50}
      className='mb-2'
      src={get(marker, 'header.thumbnail.squared')}
    />}
    <h5 className="text-secondary">
      <Link to={`/resort/${marker._id}`} className="text-secondary">
        {marker.name}
      </Link>
    </h5>
  </div>
)
export default ResortPoupup
