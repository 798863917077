import React, { PureComponent, Fragment } from 'react'
import { SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import RegisterCardForm from '../../components/RegisterCardForm'
import RegistrationDone from '../../components/RegistrationDone'
import withScrollTop from '../../hocs/ScrollTop'
import { getItalianClubs, loadItalianClubs } from '../../state/clubs'
import { getCountries, loadCountries } from '../../state/events'
import {
  register,
} from '../../state/auth'
import './RegisterCard.scss'
import {t} from "i18next";

class RegisterCard extends PureComponent {
  state = {
    registerUser: null,
    selectedCountry: ''
  }

  componentDidMount() {
    this.props.loadItalianClubs()
    this.props.loadCountries()
  }

  handleChangeCountry = (event) => {
    this.setState({selectedCountry: event.target.value})
  }

  render() {
    const { registerUser, selectedCountry } = this.state
    const { italianclubs, countries } = this.props

    return (
      <div className='with-topbar register-card'>
        <Menu bgColor={'rgb(1, 22, 50)'} />
        {registerUser && <RegistrationDone
          message='Registrazione andata a buon fine'
        />}
        {!registerUser && <Fragment>
          <div className='bg-primary'>
            <div className='container text-center'>
              <img style={{ height: 150 }} alt='privileged the club logo' src='/images/privileged-logo-gold.png' />
              <h1 className='text-secondary page-title'>{t('register your card')}</h1>
              <div className='text-white mt-2'>
                {t('if already have card')}
              </div>
            </div>
          </div>
          <RegisterCardForm
            selectedCountry={selectedCountry}
            handleChangeCountry={this.handleChangeCountry}
            countries={countries}
            clubs={italianclubs}
            onSubmit={user => register({
              ...user,
              address: {
                ...user.address,
                country: selectedCountry,
              },
              birthDate: moment(user.birthDate, 'DD/MM/YYYY', true)
                ? moment(user.birthDate, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
                : user.birthDate
            }).catch(e => {
              throw new SubmissionError({
                _error: get(e, 'response.body.error', t('register error'))
              })
            })}
            onSubmitSuccess={registerUser => {
              this.setState({
                registerUser,
              }, () => {
                window.scrollTo(0, 0)
              })
            }}
          />
        </Fragment>}
        <Footer/>
      </div>
    )
  }
}

export default withScrollTop(connect(state => ({
  italianclubs: getItalianClubs(state),
  countries: getCountries(state),
}), {
  loadItalianClubs, loadCountries
})(RegisterCard))
